import { ItemService } from './../../../services/item.service';
import { ItemTypeService } from './../../../services/itemType.service';
import { ItemType } from './../../../models/itemType';
import { Highway } from './../../../models/highway';
import { Exit } from './../../../models/exit';
import { ItemLocationService } from './../../../services/itemLocation.service';
import { ItemLocation } from './../../../models/itemLocation';
import { Item } from './../../../models/Item';
import { HighwayService } from './../../../services/highway.service';
import { DialogService } from './../../../services/dialog.service';
import { Company } from './../../../models/company';
import { CompanyService } from './../../../services/company.service';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, ElementRef, NgZone, AfterViewInit, TemplateRef } from '@angular/core';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { DataLogVersionService } from '../../../services/data-log-version.service';
import { MouseEvent } from '@agm/core/services/google-maps-types';
import { MapsAPILoader } from '@agm/core';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { InvestigationService } from '../../../services/investigation.service';
import { InvestigationType } from '../../../models/investigationType';
import { Investigation } from '../../../models/investigation';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoadwayService } from '../../../services/roadway.service';
import { Roadway } from '../../../models/roadway';
import { SiasFileService } from '../../../services/siasFile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PreviousRouteService } from '../../../helpers/previousRoute.service';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform, base64ToFile } from 'ngx-image-cropper';
import imageCompression from 'browser-image-compression'
import { SiasFile, TypeFile } from '../../../models/siasFile';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from '../../../services/spinner.service';
import { CropperComponent } from 'angular-cropperjs';


@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss']
})
export class AddItemComponent implements OnInit {

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  item: Item = new Item()
  allCompany: Array<Company>;
  allHighway: Array<Highway>;
  allItemLocation: Array<ItemLocation>;
  allHighwayExit: Array<Exit>;
  allItemType: Array<ItemType>;
  allRoadway: Array<Roadway>;

  @ViewChild('targa') targa: any;
  @ViewChild('allegato') allegato: any;
  @ViewChild("dialogCrop", { static: false }) dialogCrop: TemplateRef<any>;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  showCropper: boolean;
  rotation = 0;
  scale = 1;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  aspectRatioVariable: number = 4 / 3;

  listOfFiles: any[] = [];

  currentSiasFile: SiasFile = new SiasFile;
  currentFileType: TypeFile;
  currentFileName: string;

  previewImage: any;
  previewItemImage: any;
  previewItemTarga: any;

  investigation: Investigation = new Investigation();

  addItemForm: FormGroup;

  hasId: boolean = false;

  zoom: number;
  address: string;
  private geoCoder;
  @ViewChild('search')
  public searchElementRef: ElementRef;

  restoreMode: boolean = false;

  constructor(private authenticationService: AuthService, private companyService: CompanyService, private highwayService: HighwayService, private itemLocationService: ItemLocationService,
    private itemTypeService: ItemTypeService, private itemService: ItemService, private router: Router, private cd: ChangeDetectorRef, private roadwayService: RoadwayService,
    private activatedRoute: ActivatedRoute, private dataLogVersionService: DataLogVersionService, private mapsAPILoader: MapsAPILoader, private investigationService: InvestigationService,
    private ngZone: NgZone, private formBuilder: FormBuilder, private siasFileService: SiasFileService, private sanitizer: DomSanitizer, private previousRoute: PreviousRouteService, public dialog: MatDialog, public spinnerService: SpinnerService) {

    let currentUser = this.authenticationService.getCurrentUser();

    if (Object.keys(this.item).length === 0) {
      this.item = new Item();
      this.item.files = new Array<SiasFile>();
    }


    this.item.fk_user = currentUser;
    this.investigation.fk_investigationType = new InvestigationType();

    //load Places Autocomplete (attualmente commentato perchè non richiesto, ma sicuramente tornerà utile)
    // this.mapsAPILoader.load().then(() => {
    //   this.setCurrentLocation();
    //   this.geoCoder = new google.maps.Geocoder;

    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
    //     types: ["address"]
    //   });
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       //set latitude, longitude and zoom
    //       this.lat = place.geometry.location.lat();
    //       this.lng = place.geometry.location.lng();
    //       this.zoom = 15;
    //     });
    //   });
    // });


  }

  ngOnInit(): void {
    this.companyService.getAllCompany().then((resCompany) => {
      this.allCompany = resCompany;
    })

    this.highwayService.getAllHighwaysWithExits().then((resHighway) => {
      this.allHighway = resHighway;
    })

    this.itemLocationService.getAllItemLocation().then((resItemLocation) => {
      this.allItemLocation = resItemLocation;
    })

    this.itemTypeService.getAllItemType().then((resItemType) => {
      this.allItemType = resItemType;
    })

    this.roadwayService.getAllRoadway().then((resRoadway) => {
      this.allRoadway = resRoadway;
    })

    if (this.activatedRoute.snapshot.params.fk_dataLogVersion) {
      this.dataLogVersionService.getDataLogVersion(this.activatedRoute.snapshot.params.fk_dataLogVersion).then((res) => {
        this.item = res.data;
        this.restoreMode = true;
        this.item.fk_dataLogVersion = this.activatedRoute.snapshot.params.fk_dataLogVersion;
      })
    }
    else if (this.activatedRoute.snapshot.params.id) {
      this.hasId = true;
      this.itemService.getItem(this.activatedRoute.snapshot.params.id).then((res) => {
        this.item = res;
        // Workaround: Se la proprietà è null devo metterla undefined altrimenti nell'autocomplete viene scritto null
        if (this.item.fk_company == null)
          this.item.fk_company = undefined

        if (this.item.fk_highway == null)
          this.item.fk_highway = undefined

        if (this.item.fk_itemLocation == null)
          this.item.fk_itemLocation = undefined

        if (this.item.fk_itemType == null)
          this.item.fk_itemType = undefined

        if (this.item.fk_exit == null)
          this.item.fk_exit = undefined

        this.zoom = 15;
        this.getAllFiles();
      })
    } else {
      // Recupero la posizione corrente soltanto nel caso in cui sto facendo un inserimento senza id
      this.getCurrentLocation();
    }

    this.createForm();

  }

  getAllFiles() {
    this.siasFileService.getAllSiasFileByItem(this.activatedRoute.snapshot.params.id).then((res) => {
      this.item.files = res;
      this.listOfFiles = res.filter(x => x.type == 2);
      this.previewImage = res.filter(x => x.type == 3);
      if (this.previewImage.length == 0) {
        this.previewImage = null;
      } else {
        this.getFilePreview(this.previewImage[0].data);
      }


    }).catch(() => {
      DialogService.Error("Non è stato possibile recuperare i files")
    })
  }


  onFileChange(event, type) {
    if (event.target.files && event.target.files.length)
      this.currentFileName = event.target.files[0].name;

    this.currentFileType = type;
    this.imageChangedEvent = event;
    this.dialog.open(this.dialogCrop, {
      width: '550px',
      autoFocus: false
    })

  }

  aspectRadioVertical() {
    this.aspectRatioVariable = 3 / 4;
  }

  aspectRadioHorizontal() {
    this.aspectRatioVariable = 4 / 3;
  }


  getCroppedAndUpload(file) {

    var self = this;

    var file: any = base64ToFile(file);

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    /**
     * Comprime l'immagine passando come parametri le options create nell'oggetto sopra, e il file dal reader principale
     */
    imageCompression(file, options).then((fileCompressed => {

      let fileReader = new FileReader();

      //Faccio la push di ogni file all'interno dell'array di file dell'item da mandare al server
      fileReader.onload = function () {

        let resultReader = <string>fileReader.result;


        self.currentSiasFile.name = self.currentFileName;
        self.currentSiasFile.base64 = resultReader.substr(resultReader.indexOf(',') + 1);
        self.currentSiasFile.creationDate = new Date();
        self.currentSiasFile.type = JSON.parse(JSON.stringify(self.currentFileType));
        self.currentSiasFile.content_type = fileCompressed.type;

        if (JSON.parse(JSON.stringify(self.currentFileType == 3)))
          self.previewItemImage = resultReader;

        if (JSON.parse(JSON.stringify(self.currentFileType == 2))) {
          self.listOfFiles.push(self.currentSiasFile);
        }
        self.item.files.push(self.currentSiasFile);
        self.currentSiasFile = new SiasFile();
        self.allegato.nativeElement.value = '';
        self.targa.nativeElement.value = '';
        self.cd.markForCheck();

        self.dialog.closeAll();
        self.spinnerService.hide();
      };

      fileReader.readAsDataURL(fileCompressed);

    }));

  }

  imageLoaded() {
    this.showCropper = true;

    setTimeout(() => {
      document.getElementsByClassName("cropper")[0]["style"]["width"] = "100px";
    }, 10);
  }

  cropImage() {
    this.imageCropper.crop();
    this.spinnerService.show();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.getCroppedAndUpload(this.croppedImage);
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  clearImageEvent() {
    this.imageChangedEvent = '';
    this.allegato.nativeElement.value = '';
    this.targa.nativeElement.value = '';
  }

  //Se imageId è popolato allora si sta eliminando un file già salvato e presente nel sistema, altrimenti lo rimuovo dalla lista che sto creando

  deleteItemImage(image = null) {
    this.previewImage = [];
    this.previewItemImage = null;
    if (image) {
      for (var i = this.item.files.length - 1; i >= 0; --i) {
        if (this.item.files[i]["name"] == image.name) {
          this.item.files.splice(i, 1);
          if (image.id != null) {
            this.siasFileService.deleteSiasFile(image.id);
          }
        }
      }
    } else {
      this.item.files = this.item.files.filter(x => x.type != 3);
    }
  }

  // Dato il nome del file, recupero l'indice dai 2 array e faccio lo splice
  deleteSelectedFile(index) {
    let clonedElement = this.listOfFiles[index];
    this.listOfFiles.splice(index, 1);
    for (var i = this.item.files.length - 1; i >= 0; --i) {
      if (this.item.files[i]["name"] == clonedElement.name) {
        this.item.files.splice(i, 1);
        if (clonedElement.id != null) {
          this.siasFileService.deleteSiasFile(clonedElement.id);
        }
      }
    }
  }

  saveItem() {

    if (this.item.id) {
      this.itemService.updateItem(this.item).then((resItem) => {
        DialogService.Success("Item salvato con successo");
        this.router.navigate([this.previousRoute.getPreviousUrl()]);
      })
    }
    else {
      this.itemService.saveItem(this.item).then((resItem) => {
        Swal.fire({
          title: 'Salvataggio effettuato con successo',
          text: "Vuoi inserire un altro item?",
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.value) {
            window.location.reload();
          } else {
            this.router.navigate(['/dashboard']);
          }
        })
      }).catch((err) => {
        DialogService.Error(err.Message);
      })
    }

  }


  markerDragEnd($event: MouseEvent) {
    this.item.lat = $event["coords"].lat;
    this.item.lng = $event["coords"].lng;
  }

  setPosition(pos: Position) {
    this.item.lng = +pos.coords.longitude;
    this.item.lat = +pos.coords.latitude;
    this.zoom = 15;
  }

  getCurrentLocation() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.setPosition(pos);
      }, function () {
        DialogService.Error("Non è stato possibile recuperare la posizione");
      });
    }
  }

  // Get Current Location Coordinates - setCurrentLocation e getAddress sono per i places di google maps(attualmente rimossi)
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.item.lat = position.coords.latitude;
        this.item.lng = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.item.lat, this.item.lng);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('Nessun risultato trovato');
        }
      } else {
        window.alert('Geocoder fallito per: ' + status);
      }

    });
  }

  getFilePreview(base64) {
    this.previewItemImage = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64, ${base64}`);
  }

  getPreviewTarga(image) {
    if (image.data) {
      this.previewItemTarga = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64, ${image.data}`);
    } else {
      this.previewItemTarga = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64, ${image.base64}`);
    }
  }

  createForm() {
    if (this.item == null)
      this.item = new Item();

    this.addItemForm = this.formBuilder.group({
      lat: [{ value: this.item.lat, disabled: true }, Validators.required],
      lng: [{ value: this.item.lng, disabled: true }, Validators.required],
      company: [this.item.fk_company, Validators.required],
      highway: [this.item.fk_highway, Validators.required],
      location: [this.item.fk_itemLocation, Validators.required],
      exit: [this.item.fk_exit],
      position: [this.item.locationExit],
      type: [this.item.fk_itemType, Validators.required],
      roadway: [this.item.fk_roadway],
      preparation: [this.item.preparation],
      indication: [this.item.indication, Validators.required],
      note: [this.item.note]
    })
  }

  goBack() {
    window.history.back()
  }


  /**
  * Convert a base64 string in a Blob according to the data and contentType.
  * 
  * @param b64Data {String} Pure base64 string without contentType
  * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
  * @param sliceSize {Int} SliceSize to process the byteCharacters
  * @return Blob
  */
  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
