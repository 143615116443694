import { SiasFile } from './../models/siasFile';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SiasFileService {

    constructor(private http: HttpClient) { }

    getAllSiasFileByItem(id: number, type: number = null, includeBase64: boolean = null): Promise<Array<SiasFile>> {
        const formData = new FormData();
        formData.append('id', id.toString());
        if (type)
            formData.append('type', type.toString());

        return this.http.post<Array<SiasFile>>(environment.apiFullUrl + '/siasfile/GetAllSiasFileByItem/' + includeBase64, formData).toPromise();
    }

    deleteSiasFile(id): Promise<SiasFile> {
        return this.http.post<SiasFile>(environment.apiFullUrl + '/siasfile/DeleteSiasFile/' + id, null).toPromise();
    }

    //Download di un file salvato come array di byte
    downloadSiasFile(id: number) {
        window.location.href = environment.apiFullUrl + '/siasfile/DownloadFile/' + id;
    }

    previewSiasFile(id: number): Promise<string> {
        return this.http.get<string>(environment.apiFullUrl + '/siasfile/previewFile/' + id).toPromise();
    }

    saveSiasFile(files: Array<Blob>, customProperties: Array<any>, idItem?: number): Promise<any> {

        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('blob', files[i], files[i]["name"])
        }

        if (files)
            formData.getAll('blob');

        if (customProperties)
            formData.append('customProperties', JSON.stringify(customProperties))

        return this.http.post<any>(environment.apiFullUrl + '/siasfile/savesiasfile/' + idItem, formData).toPromise();
    }

}
