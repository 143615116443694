import { AgmCoreModule } from '@agm/core';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { MaterialModule } from './../material.module';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';


const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ItemComponent } from './components/item/item.component';
import { AddItemComponent } from './components/item/add-item/add-item.component';
import { IndagineComponent } from './components/indagine/indagine.component';
import { WizardIndagineComponent } from './components/indagine/wizard-indagine/wizard-indagine.component';
import { CompanyComponent } from './components/company/company.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DataLogVersionComponent } from './components/data-log-version/data-log-version.component';
import { ListIndagineComponent } from './components/indagine/list-indagine/list-indagine.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { NguiAutoCompleteModule, NguiAutoCompleteComponent } from '@ngui/auto-complete';
import { ImportComponent } from './components/import/import.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PreviousRouteService } from './helpers/previousRoute.service';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularCropperjsModule } from 'angular-cropperjs';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AutocompleteLibModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCjXdaN3LOqqs5_FIn_WayGPhLpuDWPiGo',
      libraries: ['places']
    }),
    AgmJsMarkerClustererModule,
    MatTableExporterModule,
    NguiAutoCompleteModule,
    NgbModule,
    NgSelectModule,
    ImageCropperModule,
    AngularCropperjsModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    SpinnerComponent,
    UserProfileComponent,
    ItemComponent,
    AddItemComponent,
    IndagineComponent,
    WizardIndagineComponent,
    CompanyComponent,
    DashboardComponent,
    DataLogVersionComponent,
    ListIndagineComponent,
    ImportComponent
  ],
  entryComponents: [
    NguiAutoCompleteComponent
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy, },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    PreviousRouteService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
