import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Highway } from '../models/highway';


@Injectable({
    providedIn: 'root'
})
export class HighwayService {

    constructor(private http: HttpClient) { }

    getExitsHighway(id): Promise<Highway>{
        let url = environment.apiFullUrl + '/highway/GetExitsHighway/' + id;
        return this.http.get<Highway>(url).toPromise()
    }

    getAllHighwaysWithExits(): Promise<Array<Highway>>{
        return this.http.get<Array<Highway>>(environment.apiFullUrl + '/highway/GetAllHighwaysWithExits').toPromise()
    }
}

