import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Roadway } from '../models/roadway';

@Injectable({
    providedIn: 'root'
})
export class RoadwayService {

    constructor(private http: HttpClient) { }

    getAllRoadway(): Promise<Array<Roadway>> {
        return this.http.get<Array<Roadway>>(environment.apiFullUrl + '/Roadway/GetAllRoadway').toPromise();
    }

}
