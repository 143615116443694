import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DataLogVersion } from '../../models/dataLogVersion';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataLogVersionService } from '../../services/data-log-version.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-data-log-version',
  templateUrl: './data-log-version.component.html',
  styleUrls: ['./data-log-version.component.css']
})
export class DataLogVersionComponent implements OnInit {

  @Input() idDataLog: number;
  @Input() table: string;
  @Input() label: string;
  @Input() isDialog: boolean;
  @Output() onCallback: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSourceLogs: MatTableDataSource<DataLogVersion>;
  displayedColumnsLogs = ['VersionDate', 'User', 'Actions'];
  logs: Array<DataLogVersion>;

  constructor(private dataLogVersionService: DataLogVersionService) { }

  ngOnInit(): void {
    this.getLogsItem()
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Rimuovo lo spazio bianco
    filterValue = filterValue.toLowerCase(); // Ricerca di default con caratteri minuscoli
    this.dataSourceLogs.filter = filterValue;
  }

  /** Richiama la funzione in output del componente
   * datalog: oggetto da aprire in update mode
   */
  openData(datalog: DataLogVersion) {
    this.onCallback.emit(datalog);
  }

  getLogsItem() {
    this.dataLogVersionService.getAllDataLogVersions(this.idDataLog, this.table).then((res) => {
      this.logs = res;
      this.dataSourceLogs = new MatTableDataSource(this.logs);
      this.dataSourceLogs.paginator = this.paginator;
      this.dataSourceLogs.sort = this.sort;
    }).catch((err) => {
      DialogService.Error("Non è stato possibile recuperare lo storico")
    })
  }

}
