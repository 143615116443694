import { SpinnerService } from './../services/spinner.service';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService, private spinnerService: SpinnerService) { }

    private requestsCount: number = 0;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Se presente il token jwt concedo l'autorizzazione
        let currentUser = this.authenticationService.getCurrentToken();
        if (currentUser) {
            request = request.clone({
                setHeaders: {
                    Authorization: `${currentUser}`
                }
            });
        }

        //Aggiunge la richiesta all'elenco delle richieste disponibili e mostra lo spinner
        this.requestsCount++;
        this.spinnerService.show();

        //Invoca l'observable e resta in ascolto della conclusione della chiamata HTTP
        //per stoppare se necessario lo spinner
        return next.handle(request).pipe(finalize(() => this.removeRequest()));
    }

    removeRequest() {
        this.requestsCount--;
        if (this.requestsCount == 0)
            this.spinnerService.hide();
    }
}
