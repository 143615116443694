<div class="brand-card">
  <!--NUOVO ITEM o MODIFICA-->
  <div class="card-header">{{hasId ? 'Modifica' : 'Nuovo'}} Item {{hasId ? item.cod : ''}}</div>

  <div class="brand-card-body">
    <div class="container-fluid">
      <form [formGroup]="addItemForm">
        <div class="row mt-3">


          <!--MAPPA-->
          <div class="col-md-6 col-sm-12">
            <!-- Label per ricerca indirizzo(commentato per eventuale esigenza futura) -->
            <!-- <div class="form-group">
              <label>Enter address</label>
              <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
                placeholder="Inserire indirizzo" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
                #search>
            </div> -->

            <agm-map [zoom]='zoom' [latitude]="item.lat" [longitude]="item.lng" [fullscreenControl]='false'
              [mapTypeControl]='true'>
              <agm-marker [latitude]="item.lat" [longitude]="item.lng" [markerDraggable]="true"
                (dragEnd)="markerDragEnd($event)">
              </agm-marker>
            </agm-map>

            <div matTooltip="Ritorna alla posizione corrente" (click)="getCurrentLocation()" dir="ltr"
              title="Riposiziona" controlwidth="40" controlheight="40" class="currentPosition">
              <i style=" font-size: 2.5em;
              line-height: 1.10em;
              vertical-align: -15%;" class="fa fa-crosshairs"></i>
            </div>

          </div>

          <!--ADD ITEM-->
          <div class=" col-md-6 col-sm-12">
            <!--DATI ITEM-->
            <div class="row">

              <!--LATITUDINE-->
              <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <mat-form-field class="full-width">
                  <mat-label>Latitudine</mat-label>
                  <input formControlName="lat" type="text" matInput [(ngModel)]="item.lat" name="lat" id="lat"
                    [disabled]>
                </mat-form-field>
              </div>

              <!--LONGITUDINE-->
              <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <mat-form-field class="full-width">
                  <mat-label>Longitudine</mat-label>
                  <input formControlName="lng" type="text" matInput [(ngModel)]="item.lng" name="lng" id="lng" disabled>
                </mat-form-field>
              </div>

              <!--CLIENTE-->
              <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <ng-select [items]="allCompany" placeholder="Cliente" bindLabel="businessName"
                  [(ngModel)]="item.fk_company" formControlName="company">
                </ng-select>
              </div>

              <!--STRADA-->
              <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <ng-select [items]="allHighway" placeholder="Strada" bindLabel="name" [(ngModel)]="item.fk_highway"
                  formControlName="highway">
                </ng-select>
              </div>

              <!--UBICAZIONE-->
              <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <ng-select [items]="allItemLocation" placeholder="Ubicazione" bindLabel="name"
                  [(ngModel)]="item.fk_itemLocation" formControlName="location">
                </ng-select>
              </div>

              <!-- ASSE -->
              <div *ngIf="item.fk_itemLocation?.id == 1" class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <mat-form-field class="full-width">
                  <mat-label>Posizione</mat-label>
                  <input type="text" matInput [(ngModel)]="item.locationExit" name="locationExit" id="locationExit"
                    placeholder="XXX+XXX" formControlName="position" maxlength="7">
                </mat-form-field>
              </div>

              <!-- COMP ESTERNA-->
              <div *ngIf="item.fk_itemLocation?.id == 2" class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <ng-select [disabled]="item.fk_highway == null || !item.fk_highway.id " [items]="item.fk_highway.exits"
                  placeholder="Ubicazione" bindLabel="name" [(ngModel)]="item.fk_exit" formControlName="exit">
                </ng-select>
              </div>

              <div *ngIf="item.fk_itemLocation?.id == 1" class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <ng-select [items]="allRoadway" placeholder="Carreggiata" bindLabel="name" [(ngModel)]="item.fk_roadway"
                  formControlName="roadway">
                </ng-select>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <ng-select [items]="allItemType" placeholder="Tipologia" bindLabel="name" [(ngModel)]="item.fk_itemType"
                  formControlName="type">
                </ng-select>
              </div>

              <!--INDICAZIONE-->
              <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <mat-form-field class="full-width">
                  <mat-label>Indicazione</mat-label>
                  <input type="text" matInput [(ngModel)]="item.indication" name="indication" id="indication"
                    formControlName="indication">
                </mat-form-field>
              </div>

              <!--NOTE-->
              <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <mat-form-field class="full-width">
                  <mat-label>Note</mat-label>
                  <input type="text" matInput [(ngModel)]="item.note" name="note" id="note" formControlName="note">
                </mat-form-field>
              </div>


              <!--PMV-->
              <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                <mat-form-field class="full-width mat-form-field--no-underline" floatPlaceholder="always">
                  <input matInput placeholder="" style="display: none">
                  <mat-checkbox [(ngModel)]="item.preparation" name="preparation" id="preparation"
                    formControlName="preparation">
                    PMV
                  </mat-checkbox>
                </mat-form-field>
              </div>
            </div>

            <!--UPLOAD-->
            <div class="row">
              <!--IMMAGINE-->
              <div class="col-md-8 col-sm-12" style="vertical-align: bottom;">
                <figure>
                  <figcaption>

                    <!--ELIMINA IMMAGINE-->
                    <div class="row mt-2">
                      <div class="col-md-12 text-left">
                        <mat-icon class="pointer" (click)="deleteItemImage(previewImage ? previewImage[0] : null)"
                          *ngIf="previewItemImage" style="color: red;">
                          delete
                        </mat-icon>
                      </div>
                    </div>

                    <!--AGGIUNGI IMMAGINE-->
                    <a class="pointer" (click)="fileInputItem.click()">
                      <img *ngIf="!previewItemImage" src="../../../../assets/img/img.png" style="width:100%" />
                      <input name="item_image" #fileInputItem #allegato type="file" (change)="onFileChange($event, 3)"
                        style="display:none;" />
                    </a>

                    <!--PREVIEW IMMAGINE-->
                    <img style="width:100%" [src]="previewItemImage" *ngIf="previewItemImage">
                  </figcaption>
                </figure>
              </div>

              <!--TARGA-->
              <div class="col-md-4 col-sm-12 text-right">

                <!--AGGIUNGI TARGA-->
                <button class="mt-2 btn btn-orange" style="margin-bottom: 10px; color:white" color="accent"
                  (click)="fileInput.click()">
                  <i class="cui-cloud-upload"></i>
                  <span> Targa</span>
                </button>

                <input style="display: none" #targa type="file" (change)="onFileChange($event, 2)" #fileInput>

                <!--TARGHE AGGIUNTE-->
                <ng-container *ngFor="let selected of listOfFiles;let index = index">
                  <mat-form-field appearance="standard">
                    <!-- <mat-label>{{selected.name}}</mat-label> -->
                    <input disabled matInput>

                    <div class="manage-targa">
                      <!--BTN SEARCH TARGA-->
                      <button data-container="mat-dialog-container" placement="bottom"
                        (click)="getPreviewTarga(selected)" [ngbPopover]="popContent" class="btn-search-targa"
                        type="button">
                        <i class="fa fa-search"></i>
                      </button>

                      <!--BTN DELETE TARGA-->
                      <mat-icon class="btn-delete-targa pointer" (click)="deleteSelectedFile(index)">
                        delete
                      </mat-icon>
                    </div>

                    <ng-template #popContent>
                      <img style="max-width: 100%; max-height: 100%;" [src]="previewItemTarga">
                    </ng-template>
                  </mat-form-field>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <!--AZIONI-->
        <div class="row mt-3">
          <div class="col-sm-12 text-right">

            <!--INDIETRO-->
            <button matTooltip="INDIETRO" (click)="goBack()" class="btn btn-primary btn-orange mr-1" type="button">
              <i class="icon-arrow-left"></i>
            </button>

            <!--SALVA-->
            <button [disabled]="addItemForm.invalid" matTooltip="SALVA" (click)="saveItem()"
              class="btn btn-primary btn-orange" type="button">
              <i class="cui-pencil"></i>
            </button>

          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!--dialog Modifica immagine-->
<ng-template #dialogCrop>
  <div class="card">
    <div class="card-header"> MODIFICA IMMAGINE</div>
    <div class="card-body" id="crop">

      <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [autoCrop]="false"
        [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="aspectRatioVariable"
        [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
        [transform]="transform" [alignImage]="'left'" format="png" (imageCropped)="imageCropped($event)">
      </image-cropper>

      <div class="row mt-3  justify-content-center">

        <!-- <div class="col-md-auto edit-img p-1">
          <button (click)="rotateLeft()" matTooltip="Ruota a sinistra" class="btn btn-primary btn-dark">
            <i class="fa fa-rotate-left"></i>
          </button>
        </div>

        <div class="col-md-auto edit-img p-1">
          <button (click)="rotateRight()" matTooltip="Ruota a destra" class="btn btn-primary btn-dark">
            <i class="fa fa-rotate-right"></i>
          </button>
        </div> -->

        <div class="col-md-auto edit-img p-1">
          <button (click)="aspectRadioHorizontal()" matTooltip="Ritaglia orizzontalmente"
            class="btn btn-primary btn-dark">
            <i class="fa fa-arrows-h"></i>
          </button>
        </div>

        <div class="col-md-auto edit-img p-1">
          <button style="padding-right: 17px; padding-left: 17px;" (click)="aspectRadioVertical()"
            matTooltip="Ritaglia verticalmente" class="btn btn-primary btn-dark">
            <i class="fa fa-arrows-v"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>
    <!--CONFERMA-->
    <button mat-dialog-close (click)="cropImage()" matTooltip="CONFERMA"
      class="btn btn-primary btn-orange float-right mr-2">
      <i class="fa fa-check"></i>
    </button>

    <!--CHIUDI-->
    <button (click)="clearImageEvent()" mat-dialog-close matTooltip="CHIUDI"
      class="btn btn-primary btn-orange mr-1 float-right">
      <i class="fa fa-window-close"></i>
    </button>
  </div>
</ng-template>