import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class KmzService {

    constructor(private http: HttpClient) { }

    importKmzFile(file: File, modelName?: string, fk_company: number = null): Promise<any> {
        let formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<any>(environment.apiFullUrl + '/kmz/ImportKmzFile/' + modelName, formData).toPromise()
    }


}