import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']

})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthService
  ) {

    // Se l'utente è già connesso faccio il redirect alla home page
    if (this.authenticationService.currentUser) {
      this.router.navigate(['/'])
    }

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    // Prendo il return url dai route parameteres o il default '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // Per accedere ai campi del form facilmente
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // Se il form non è valido mi fermo
    if (this.loginForm.invalid) {
      return;
    }

    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .then((resUser) => {
        localStorage.setItem('currentUserSias', resUser);
        this.authenticationService.loggedIn.next(true);
        // Login effettuato con successo
        this.router.navigate(['/']);
      })
      .catch((err) => {
        this.authenticationService.loggedIn.next(false);
        Swal.fire({
          title: err,
          icon: 'error',
        })
      });

  }

}
