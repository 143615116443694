import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public router: Router) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    //Al momento verifica soltanto se è presente il token di autenticazione
    var currentUser = localStorage.getItem('currentUserSias');
    if (currentUser == null) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

}
