import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { KmzService } from '../../services/kmz.service';
import { CsvService } from '../../services/csv.service';
import { DialogService } from '../../services/dialog.service';

@Component({
    selector: 'app-import',
    templateUrl: './import.component.html',
    styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit, AfterViewInit {

    fk_company: number = null;
    showCsvButton: boolean = true;
    showKmzButton: boolean = true;

    @Input() modelName: string;
    @Input() csv: boolean;
    @Input() kmz: boolean;
    @Output("importCsvCompleted") importCsvCompleted: EventEmitter<any> = new EventEmitter<any>();
    @Output("importKmzCompleted") importKmzCompleted: EventEmitter<any> = new EventEmitter<any>();


    constructor(private cd: ChangeDetectorRef, private kmzService: KmzService, private csvService: CsvService) {

    }

    ngAfterViewInit() {
        if (this.modelName == undefined) {
            DialogService.Error("Non è stato indicato il modello da usare per le importazioni");
        }
    }

    ngOnInit() {
        if (this.csv != undefined)
            this.showCsvButton = this.csv;

        if (this.kmz != undefined)
            this.showKmzButton = this.kmz;
    }

    onFileCsvChange(event) {

        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {

                this.checkImportCsv(file);

                // need to run CD since file load runs outside of zone
                this.cd.markForCheck();

                // Svuoto l'input file dopo aver caricato e importato l'import
                document.getElementById('file-import-csv')["value"] = "";

            };
        }
    }

    onFileKmzChange(event) {

        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {

                this.importKmz(file);

                // need to run CD since file load runs outside of zone
                this.cd.markForCheck();

                // Svuoto l'input file dopo aver caricato e importato l'import
                document.getElementById('file-import-kmz')["value"] = "";

            };
        }
    }

    private checkImportCsv(blob) {
        let csvBlob = blob;
        this.csvService.checkImportCsv(csvBlob, this.modelName)
            .then((res) => {
                if (res.IsValid != true) {
                    DialogService.Error(res.VerifyMessages);
                }
                else {
                    if(res.VerifyMessages == undefined || res.VerifyMessages.length == 0)
                        this.importCsv(csvBlob);
                    else {
                        DialogService.Confirm(res.VerifyMessages, () => {
                            this.importCsv(csvBlob);
                        }, true, null, '70rem');
                    }
                }
            })
            .catch((err) => {
                DialogService.Error(err.Message);
            })

    }

    private importCsv(blob) {
        this.csvService.importCsvFile(blob, this.modelName)
            .then((res) => {
                if (this.importCsvCompleted) {
                    this.importCsvCompleted.emit(res);
                    DialogService.Success("Importazione completata");
                }
            })
            .catch((err) => {
                DialogService.Error(err.Message);
            })
    }

    private importKmz(blob) {
        this.kmzService.importKmzFile(blob, this.modelName)
            .then((res) => {
                if (this.importKmzCompleted) {
                    this.importKmzCompleted.emit(res);
                    DialogService.Success("Importazione completata");
                }
            })
            .catch((err) => {
                DialogService.Error(err.Message);
            })
    }
}
