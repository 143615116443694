<!-- tabs profilo utente ed amministrazione -->
<div *ngIf="isDataAvailable" class="brand-card">
  <div class="card-header"> INDAGINE ITEM {{currentItem.cod}}</div>
  <div class="brand-card-body tabDimensioni">
    <mat-tab-group dynamicHeight>

      <mat-tab label="RIEPILOGO">
        <div class="animated fadeIn container-fluid">
          <div class="table-responsive">
            <table class="table table-bordered table-sm" table-bordered>
              <thead>
                <tr>
                  <th style="width: 40%" rowspan="2">DESCRIZIONE</th>
                  <th style="width: 10%" rowspan="2">PESO GLOBALE</th>
                  <th style="width: 40%" rowspan="1" colspan="5">PESO LOCALE</th>
                  <th style="width: 10%" rowspan="2">FDL RILEVATO</th>
                </tr>
                <tr>
                  <th style="background-color: rgb(146, 208, 80);">1</th>
                  <th style="background-color: rgb(157, 202, 128);">2</th>
                  <th style="background-color: rgb(255, 255, 0);">3</th>
                  <th style="background-color: rgb(255, 192, 0);">4</th>
                  <th style="background-color: red;">5</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>PIASTRA DI BASE E TIRAFONDI</td>
                  <td style="background-color: rgb(186, 186, 186);">4</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_piastra_1}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_piastra_2}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_piastra_3}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_piastra_4}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_piastra_5}}</td>
                  <td>{{investigationSummary[0]?.local_degradation_piastra}}</td>

                </tr>
                <tr>
                  <td>RITTO-PROLUNGHE RITTO-GIUNTI RITTO/RITTO</td>
                  <td style="background-color: rgb(211, 211, 211);">3</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_ritto_1}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_ritto_2}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_ritto_3}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_ritto_4}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_ritto_5}}</td>
                  <td>{{investigationSummary[0]?.local_degradation_ritto}}</td>
                </tr>
                <tr>
                  <td>
                    TRAVERSO-PROLUNGHE TRAVERSO-GIUNTI
                    TRAVERSO/TRAVERSO
                  </td>
                  <td style="background-color: rgb(237, 237, 237);">2</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_traverso_1}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_traverso_2}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_traverso_3}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_traverso_4}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_traverso_5}}</td>
                  <td>{{investigationSummary[0]?.local_degradation_traverso}}</td>
                </tr>
                <tr>
                  <td>PASTORALI</td>
                  <td>1</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_pastorale_1}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_pastorale_2}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_pastorale_3}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_pastorale_4}}</td>
                  <td>{{investigationSummary[0]?.numPoints_weight_pastorale_5}}</td>
                  <td>{{investigationSummary[0]?.local_degradation_pastorale}}</td>
                </tr>
                <tr>
                  <td style="font-weight:bold" colspan="2">TOTALE NON CONFORMITA'</td>
                  <td style="background-color: rgb(146, 208, 80);">{{investigationSummary[0]?.total_weight_1}}</td>
                  <td style="background-color: rgb(157, 202, 128);">{{investigationSummary[0]?.total_weight_2}}</td>
                  <td style="background-color: rgb(255, 255, 0);">{{investigationSummary[0]?.total_weight_3}}</td>
                  <td style="background-color: rgb(255, 192, 0);">{{investigationSummary[0]?.total_weight_4}}</td>
                  <td style="background-color: red">{{investigationSummary[0]?.total_weight_5}}</td>
                  <td></td>

                </tr>


              </tbody>
            </table>
          </div>



          <div style="margin-top: 2%;" class="row">

            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
              <div class="card">
                <div class="card-body text-center">
                  <div class="text-muted small text-uppercase font-weight-bold">F.D.G.</div>
                  <div class="h2 py-3">{{investigationSummary[0]?.global_degradation}}</div>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
              <div class="card">
                <div class="card-body text-center">
                  <div class="text-muted small text-uppercase font-weight-bold">Priorità</div>
                  <div [ngStyle]="{'background-color': investigationSummary[0]?.intervention_priority == 1 ? 'red' : investigationSummary[0]?.intervention_priority == 2 ? 'yellow' : investigationSummary[0]?.intervention_priority == 3 ? 'green' : 'white'}"
                       class="h2 py-3">
                    {{investigationSummary[0]?.intervention_priority == 0 ? '' : investigationSummary[0]?.intervention_priority}}
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div style="margin-top: 2%;" class="row">
            <div class="col-md-4 col-sm-12 text-left">
              <div class='my-legend'>
                <div class='legend-title'>PESO LOCALE</div>
                <div class='legend-scale'>
                  <ul class='legend-labels'>
                    <li>
                      <span style='background:rgb(146, 208, 80);'></span>Non influisce potenzialmente sullo stato
                      dell’opera
                    </li>
                    <li>
                      <span style='background:rgb(157, 202, 128);'></span>Parzialmente influisce sullo stato
                      dell’opera
                    </li>
                    <li>
                      <span style='background:rgb(255, 255, 0);'></span>Potenziale pericolo se associato ad eventi
                      sopra
                      l’ordinarietà
                    </li>
                    <li>
                      <span style='background:rgb(255, 192, 0);;'></span>Alto pericolo associato ad azioni oltre
                      l’ordinarietà
                    </li>
                    <li><span style='background:red;'></span>Gravissimo pericolo</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-12 text-left">
              <div class='my-legend'>
                <div class='legend-title'>PESO GLOBALE</div>
                <div class='legend-scale'>
                  <ul class='legend-labels'>
                    <li><span style='background:white'></span>Sezione d’importanza minima</li>
                    <li><span style='background:rgb(237, 237, 237)'></span>Sezione d’importanza media</li>
                    <li><span style='background:rgb(211, 211, 211)'></span>Sezione d’importanza primaria</li>
                    <li><span style='background:rgb(186, 186, 186);'></span>Sezione d’importanza assoluta</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-12 text-left">
              <div class='my-legend'>
                <div class='legend-title'>PRIORITA'</div>
                <div class='legend-scale'>
                  <ul class='legend-labels'>
                    <li><span style='background:red;'></span>Intervento tempestivo</li>
                    <li><span style='background:yellow;'></span>Intervento urgente</li>
                    <li><span style='background:green'></span>Intervento ordinario</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>

      </mat-tab>

      <mat-tab label="PIASTRA">

        <div class="brand-card-body">
          <div class="container-fluid">

            <mat-table [dataSource]="dataSourcePiastra" matSort>

              <!-- Nome Column -->
              <!-- <ng-container matColumnDef="Nome">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Nome
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.investigationTypeName}} </mat-cell>
              </ng-container> -->
              <!-- Peso Column -->
              <!-- <ng-container matColumnDef="Peso">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Incidenza
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.points_weight}} </mat-cell>
              </ng-container> -->
              <!-- Degrado Column -->
              <ng-container matColumnDef="Degrado">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Fattore di degrado locale
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row?.local_degradation}} </mat-cell>
              </ng-container>

              <!-- Avanzamento Column -->
              <ng-container matColumnDef="Avanzamento">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Avanzamento
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row?.progressInvestigation}}% </mat-cell>
              </ng-container>

              <!-- Stato Column -->
              <ng-container matColumnDef="Stato">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Stato
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.isValidated == true ? 'Verificato' : row.statusInvestigation == 1 ? 'Da fare' : row.statusInvestigation == 2 ? 'In corso' : row.statusInvestigation == 3 ? 'Completato' : ''}}
                </mat-cell>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="Actions">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="allineamentoDestra">
                  Azioni
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index" class="allineamentoDestra">
                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button (click)="openInvestigationWizard(row.id)" mat-menu-item>Compila</button>
                  </mat-menu>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsPiastra"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsPiastra;">
              </mat-row>
            </mat-table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 prevNextButtons">
            <!-- <button (click)="openDialogPiastra()" class="btn btn-outline-primary">AGGIUNGI</button> -->
          </div>
        </div>
      </mat-tab>

      <mat-tab label="RITTO">

        <div class="brand-card-body">
          <div class="container-fluid">

            <mat-table [dataSource]="dataSourceRitto" matSort>

              <!-- Nome Column -->
              <!-- <ng-container matColumnDef="Nome">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Nome
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.investigationTypeName}} </mat-cell>
              </ng-container> -->
              <!-- Peso Column -->
              <!-- <ng-container matColumnDef="Peso">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Incidenza
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.points_weight}} </mat-cell>
              </ng-container> -->
              <!-- Degrado Column -->
              <ng-container matColumnDef="Degrado">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Fattore di degrado locale
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row?.local_degradation}} </mat-cell>
              </ng-container>

              <!-- Avanzamento Column -->
              <ng-container matColumnDef="Avanzamento">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Avanzamento
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row?.progressInvestigation}}% </mat-cell>
              </ng-container>

              <!-- Stato Column -->
              <ng-container matColumnDef="Stato">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Stato
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.isValidated == true ? 'Verificato' : row.statusInvestigation == 1 ? 'Da fare' : row.statusInvestigation == 2 ? 'In corso' : row.statusInvestigation == 3 ? 'Completato' : ''}}
                </mat-cell>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="Actions">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="allineamentoDestra">
                  Azioni
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index" class="allineamentoDestra">
                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button (click)="openInvestigationWizard(row.id)" mat-menu-item>Compila</button>
                  </mat-menu>
                </mat-cell>
              </ng-container>


              <mat-header-row *matHeaderRowDef="displayedColumnsRitto"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsRitto;">
              </mat-row>
            </mat-table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 prevNextButtons">
            <!-- <button (click)="openDialogRitto()" class="btn btn-outline-primary">AGGIUNGI</button> -->
          </div>
        </div>
      </mat-tab>

      <mat-tab label="TRAVERSO">

        <div class="brand-card-body">
          <div class="container-fluid">

            <mat-table [dataSource]="dataSourceTraverso" matSort>

              <!-- Nome Column -->
              <!-- <ng-container matColumnDef="Nome">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Nome
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.investigationTypeName}} </mat-cell>
              </ng-container> -->
              <!-- Peso Column -->
              <!-- <ng-container matColumnDef="Peso">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Incidenza
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.points_weight}} </mat-cell>
              </ng-container> -->
              <!-- Degrado Column -->
              <ng-container matColumnDef="Degrado">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Fattore di degrado locale
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row?.local_degradation}} </mat-cell>
              </ng-container>

              <!-- Avanzamento Column -->
              <ng-container matColumnDef="Avanzamento">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Avanzamento
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row?.progressInvestigation}}% </mat-cell>
              </ng-container>


              <!-- Stato Column -->
              <ng-container matColumnDef="Stato">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Stato
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.isValidated == true ? 'Verificato' : row.statusInvestigation == 1 ? 'Da fare' : row.statusInvestigation == 2 ? 'In corso' : row.statusInvestigation == 3 ? 'Completato' : ''}}
                </mat-cell>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="Actions">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="allineamentoDestra">
                  Azioni
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index" class="allineamentoDestra">
                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button (click)="openInvestigationWizard(row.id)" mat-menu-item>Compila</button>
                  </mat-menu>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsTraverso"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsTraverso;">
              </mat-row>
            </mat-table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 prevNextButtons">
            <!-- <button (click)="openDialogTraverso()" class="btn btn-outline-primary">AGGIUNGI</button> -->
          </div>
        </div>
      </mat-tab>

      <mat-tab label="PASTORALI">

        <div class="brand-card-body">
          <div class="container-fluid">

            <mat-table [dataSource]="dataSourcePastorali" matSort>
              <!-- Nome Column -->
              <!-- <ng-container matColumnDef="Nome">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Nome
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.investigationTypeName}} </mat-cell>
              </ng-container> -->
              <!-- Peso Column -->
              <!-- <ng-container matColumnDef="Peso">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Incidenza
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.points_weight}} </mat-cell>
              </ng-container> -->
              <!-- Degrado Column -->
              <ng-container matColumnDef="Degrado">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Fattore di degrado locale
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row?.local_degradation}} </mat-cell>
              </ng-container>

              <!-- Avanzamento Column -->
              <ng-container matColumnDef="Avanzamento">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Avanzamento
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row?.progressInvestigation}}% </mat-cell>
              </ng-container>

              <!-- Stato Column -->
              <ng-container matColumnDef="Stato">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Stato
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.isValidated == true ? 'Verificato' : row.statusInvestigation == 1 ? 'Da fare' : row.statusInvestigation == 2 ? 'In corso' : row.statusInvestigation == 3 ? 'Completato' : ''}}
                </mat-cell>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="Actions">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="allineamentoDestra">
                  Azioni
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index" class="allineamentoDestra">
                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button (click)="openInvestigationWizard(row.id)" mat-menu-item>Compila</button>
                  </mat-menu>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsPastorali"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsPastorali;">
              </mat-row>
            </mat-table>

          </div>
        </div>
        <div class="row">
          <div class="col-md-12 prevNextButtons">
            <!-- <button (click)="openDialogPastorali()" class="btn btn-outline-primary">AGGIUNGI</button> -->
          </div>
        </div>

      </mat-tab>

    </mat-tab-group>

    <div class="row">
      <div class="col-md-12 text-right">

        <div class="container-fluid">
          <button matTooltip="VALIDA INDAGINE" *ngIf="!(allInvestigationPiastra[0].isValidated == true)"
                  (click)="forceValidate(currentItem.id, true)" class="btn btn-primary btn-orange mr-2">
            <i class="icon-lock-open"></i>
          </button>

          <button matTooltip="RIMUOVI VALIDAZIONE INDAGINE" *ngIf="allInvestigationPiastra[0].isValidated == true"
                  (click)="forceValidate(currentItem.id, false)" class="btn btn-primary btn-orange mr-2">
            <i class="icon-lock"></i>
          </button>

          <button matTooltip="RIEPILOGO INDAGINE" (click)="openInvestigationWizardSummary()"
                  class="btn btn-primary btn-orange mr-2">
            <i class="icon-list"></i>
          </button>

          <button matTooltip="GENERA DOCUMENTO" (click)="generateDocument()" class="btn btn-primary btn-orange mr-2">
            <i class="icon-printer"></i>
          </button>

          <button matTooltip="CHIUDI INDAGINE" (click)="goBack()" class="btn btn-primary btn-orange">
            <i class="icon-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- dialog piastra -->
<ng-template #dialogAddPiastra>
  <div class="card-header"> AGGIUNGI PIASTRA </div>


  <form [formGroup]="piastraRegisterForm" class="paddingInput">


    <mat-form-field class="example-full-width">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="investigationName" [(ngModel)]="investigation.name" placeholder="Nome">
      <!-- devo aggiungere ngModule -->
    </mat-form-field>


    <mat-dialog-actions class="posizioneBottoniModale">
      <button class="btn btn-outline-primary marginBottoniCompany" [disabled]="piastraRegisterForm.invalid"
              (click)="addInvestigation(1)">
        SALVA
      </button>
      <button class="btn btn-outline-primary marginBottoniCompany" matDialogClose>CHIUDI</button>
    </mat-dialog-actions>

  </form>


</ng-template>

<!-- dialog ritto -->
<ng-template #dialogAddRitto>
  <div class="card-header"> AGGIUNGI RITTO </div>


  <form [formGroup]="rittoRegisterForm" class="paddingInput">


    <mat-form-field class="example-full-width">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="investigationName" [(ngModel)]="investigation.name" placeholder="Nome">
      <!-- devo aggiungere ngModule -->
    </mat-form-field>


    <mat-dialog-actions class="posizioneBottoniModale">
      <button class="btn btn-outline-primary marginBottoniCompany" (click)="addInvestigation(2)"
              [disabled]="rittoRegisterForm.invalid">
        SALVA
      </button>
      <button class="btn btn-outline-primary marginBottoniCompany" matDialogClose>CHIUDI</button>
    </mat-dialog-actions>

  </form>


</ng-template>

<!-- dialog traverso -->
<ng-template #dialogAddTraverso>
  <div class="card-header"> AGGIUNGI TRAVERSO </div>
  <form [formGroup]="traversoRegisterForm" class="paddingInput">
    <mat-form-field class="example-full-width">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="investigationName" [(ngModel)]="investigation.name" placeholder="Nome">
      <!-- devo aggiungere ngModule -->
    </mat-form-field>
    <mat-dialog-actions class="posizioneBottoniModale">
      <button (click)="addInvestigation(3)" class="btn btn-outline-primary marginBottoniCompany"
              [disabled]="traversoRegisterForm.invalid">
        SALVA
      </button>
      <button class="btn btn-outline-primary marginBottoniCompany" matDialogClose>CHIUDI</button>
    </mat-dialog-actions>
  </form>
</ng-template>

<!-- dialog pastorali -->
<ng-template #dialogAddPastorali>
  <div class="card-header"> AGGIUNGI PASTORALI </div>
  <form [formGroup]="pastoraliRegisterForm" class="paddingInput">
    <mat-form-field class="example-full-width">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="investigationName" [(ngModel)]="investigation.name" placeholder="Nome">
    </mat-form-field>

    <mat-dialog-actions class="posizioneBottoniModale">
      <button (click)="addInvestigation(4)" class="btn btn-outline-primary marginBottoniCompany"
              [disabled]="pastoraliRegisterForm.invalid">
        SALVA
      </button>
      <button class="btn btn-outline-primary marginBottoniCompany" mat-dialog-close>CHIUDI</button>
    </mat-dialog-actions>
  </form>
</ng-template>

<!--dialog SummaryWizard-->
<ng-template #dialogInvestigationWizardSummary>
  <div class="card">
    <div class="card-header"> RIEPILOGO INDAGINE</div>
    <div class="card-body" id="riepilogo">
      <mat-tab-group dynamicHeight>

        <mat-tab label="PIASTRA">
          <div class="animated fadeIn container-fluid">
            <div class="row" *ngFor="let pagePiastra of summaryInvestigationWizardPiastra.sections">

              <!-- Tasto rilevabile -->
              <div class="col-md-12 text-center" *ngIf="pagePiastra.section == null">
                <div class="container-fluid mt-4">
                  <mat-checkbox disabled [(ngModel)]="summaryInvestigationWizardPiastra.investigation.notDetectable">
                    Non rilevabile
                  </mat-checkbox>
                </div>
              </div>

              <!-- [ngClass]="{'col-lg-2 col-md-2 col-sm-2 summary-input': pagePiastra.section == null, 'col-lg-3 col-md-3 col-sm-3 summary-input': pagePiastra.section != null}" -->
              <!-- Dati generali quando pagePiastra.section == null, altrimenti le altre righe e cambio classe di bootstrap a seconda della riga che sto andando a renderizzare -->
              <div [hidden]="question.fk_answerFile== null && question.questionType== 1 && pagePiastra.section == null"
                   class="col-lg-3 col-md-6 col-sm-12 summary-input"
                   *ngFor="let question of pagePiastra.questions;let q=index">

                <div class="container-fluid mt-4">

                  <!-- QuestionType = 1: FILE (lo visualizza esternamente al mat-form-field altrimenti va in errore) -->
                  <div class="d-flex row container-img" *ngIf="question.questionType == 1">

                    <!-- Se il file è già presente allora non mostra l'uploader ma solo il nome del file con possibilità di cancellazione -->
                    <!--NOME FILE-->
                    <!-- <input disabled class="ml-3 file-name" *ngIf="question.fk_answerFile" type="text" readonly
                      [(ngModel)]="question.fk_answerFile.name"> -->

                    <img *ngIf="question.fk_answerFile" style="width: 100px; height: 80px;"
                         [src]="'data:image/jpeg;base64,' + question.fk_answerFile.base64">

                    <img *ngIf="!question.fk_answerFile" style="width: 100px; height: 80px;" src="assets/img/img.png">

                    <!-- PREVIEW -->
                    <button data-container="mat-dialog-container" placement="bottom"
                            (click)="getFilePreview(question.fk_answerFile.data)" [ngbPopover]="popContent"
                            class="btn btn-primary delete-file ml-3" type="button" *ngIf="question.fk_answerFile">
                      <i class="fa fa-search"></i>
                    </button>

                    <ng-template #popContent>
                      <img style="max-width: 100%; max-height: 100%;" [src]="previewImage">
                    </ng-template>

                    <!--ELIMINA-->
                    <!-- <button disabled class="btn btn-danger delete-file ml-3" type="button" *ngIf="question.fk_answerFile">
                      <i class="fa fa-trash"></i>
                    </button> -->
                  </div>

                  <!-- MAT-FORM-FIELD: usato per formattare bene i controlli ad eccezione del file uploader -->
                  <mat-form-field disabled style="padding: 10px;" class="d-flex align-items-start marginBottom"
                                  *ngIf="question.questionType != 1 && question.questionType != 5" style="width: 50%;">
                    <mat-label>{{question.question}}</mat-label>

                    <!-- QuestionType = 2: TESTO -->
                    <input disabled type="text" matInput [(ngModel)]="question.answerVarchar"
                           name="text{{question.idQuestion}}" *ngIf="question.questionType == 2">

                    <!-- QuestionType = 3: BOOLEANO -->
                    <!-- QuestionType = 4: NUMERO -->

                  </mat-form-field>

                  <!-- QuestionType = 5: SCELTA CON RADIO BUTTON ( ho dovuto mettere anche questo fuori dal form-field perchè non supportato) -->
                  <div class="marginBottom" style="padding-top: 2%;" *ngIf="question.questionType == 5">
                    <h3 style="text-align: left;">{{question.question}}</h3>
                    <div *ngFor="let choice of question.multipleChoiceAnswerLst; let choiceIndex = index"
                         style="float: left;">
                      <!-- <input type="radio" class="example-radio-button" id="radio{{question.idQuestion}}_{{choiceIndex}}"
                      name="radio{{question.idQuestion}}_{{choiceIndex}}" [value]="choice"
                      (change)="question.fk_answerMultipleChoiseDetail = choice"
                      [checked]="question.fk_answerMultipleChoiseDetail != null && question.fk_answerMultipleChoiseDetail.id == choice.id"> -->
                      <mat-radio-button disabled [class.colorWhite]="choice.value === 'N.P.'"
                                        [class.colorGreen]="choice.value === '0.0'" [class.colorYellow]="choice.value === '0.2'"
                                        [class.colorRedLight]="choice.value === '0.5'" [class.colorRed]="choice.value === '1'"
                                        id="radio{{question.idQuestion}}_{{choiceIndex}}"
                                        name="radio{{question.idQuestion}}_{{choiceIndex}}" [value]="choice"
                                        (change)="question.fk_answerMultipleChoiseDetail = choice"
                                        [checked]="question.fk_answerMultipleChoiseDetail != null && question.fk_answerMultipleChoiseDetail.id == choice.id">
                      </mat-radio-button>
                      <!-- <label for="radio{{question.idQuestion}}_{{choiceIndex}}">{{choice.value}}</label> -->
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="RITTO">
          <div class="animated fadeIn container-fluid">
            <div class="row" *ngFor="let pageRitto of summaryInvestigationWizardRitto.sections">

              <!-- Dati generali quando pagePiastra.section == null, altrimenti le altre righe e cambio classe di bootstrap a seconda della riga che sto andando a renderizzare -->
              <div [hidden]="question.fk_answerFile== null && question.questionType== 1 && pageRitto.section == null"
                   class="col-lg-3 col-md-6 col-sm-12 summary-input"
                   *ngFor="let question of pageRitto.questions;let q=index">

                <div class="container-fluid mt-4">

                  <!-- QuestionType = 1: FILE (lo visualizza esternamente al mat-form-field altrimenti va in errore) -->
                  <div class="d-flex row container-img" *ngIf="question.questionType == 1">

                    <!-- Se il file è già presente allora non mostra l'uploader ma solo il nome del file con possibilità di cancellazione -->
                    <!--NOME FILE-->

                    <img *ngIf="question.fk_answerFile" style="width: 100px; height: 80px;"
                         [src]="'data:image/jpeg;base64,' + question.fk_answerFile.base64">

                    <img *ngIf="!question.fk_answerFile" style="width: 100px; height: 80px;" src="assets/img/img.png">

                    <!-- PREVIEW -->
                    <button data-container="mat-dialog-container" placement="bottom"
                            (click)="getFilePreview(question.fk_answerFile.data)" [ngbPopover]="popContent"
                            class="btn btn-primary delete-file ml-3" type="button" *ngIf="question.fk_answerFile">
                      <i class="fa fa-search"></i>
                    </button>

                    <ng-template #popContent>
                      <img style="max-width: 100%; max-height: 100%;" [src]="previewImage">
                    </ng-template>

                    <!--ELIMINA-->
                    <!-- <button disabled class="btn btn-danger delete-file ml-3" type="button" *ngIf="question.fk_answerFile">
                      <i class="fa fa-trash"></i>
                    </button> -->
                  </div>

                  <!-- MAT-FORM-FIELD: usato per formattare bene i controlli ad eccezione del file uploader -->
                  <mat-form-field disabled style="padding: 10px;" class="d-flex align-items-start marginBottom"
                                  *ngIf="question.questionType != 1 && question.questionType != 5" style="width: 50%;">
                    <mat-label>{{question.question}}</mat-label>

                    <!-- QuestionType = 2: TESTO -->
                    <input disabled type="text" matInput [(ngModel)]="question.answerVarchar"
                           name="text{{question.idQuestion}}" *ngIf="question.questionType == 2">

                    <!-- QuestionType = 3: BOOLEANO -->
                    <!-- QuestionType = 4: NUMERO -->

                  </mat-form-field>

                  <!-- QuestionType = 5: SCELTA CON RADIO BUTTON ( ho dovuto mettere anche questo fuori dal form-field perchè non supportato) -->
                  <div class="marginBottom" style="padding-top: 2%;" *ngIf="question.questionType == 5">
                    <h3 style="text-align: left;">{{question.question}}</h3>
                    <div *ngFor="let choice of question.multipleChoiceAnswerLst; let choiceIndex = index"
                         style="float: left;">
                      <!-- <input type="radio" class="example-radio-button" id="radio{{question.idQuestion}}_{{choiceIndex}}"
                      name="radio{{question.idQuestion}}_{{choiceIndex}}" [value]="choice"
                      (change)="question.fk_answerMultipleChoiseDetail = choice"
                      [checked]="question.fk_answerMultipleChoiseDetail != null && question.fk_answerMultipleChoiseDetail.id == choice.id"> -->
                      <mat-radio-button disabled [class.colorWhite]="choice.value === 'N.P.'"
                                        [class.colorGreen]="choice.value === '0.0'" [class.colorYellow]="choice.value === '0.2'"
                                        [class.colorRedLight]="choice.value === '0.5'" [class.colorRed]="choice.value === '1'"
                                        id="radio{{question.idQuestion}}_{{choiceIndex}}"
                                        name="radio{{question.idQuestion}}_{{choiceIndex}}" [value]="choice"
                                        (change)="question.fk_answerMultipleChoiseDetail = choice"
                                        [checked]="question.fk_answerMultipleChoiseDetail != null && question.fk_answerMultipleChoiseDetail.id == choice.id">
                      </mat-radio-button>
                      <!-- <label for="radio{{question.idQuestion}}_{{choiceIndex}}">{{choice.value}}</label> -->
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="TRAVERSO">
          <div class="animated fadeIn container-fluid">
            <div class="row" *ngFor="let pageTraverso of summaryInvestigationWizardTraverso.sections">

              <div [hidden]="question.fk_answerFile== null && question.questionType== 1 && pageTraverso.section == null"
                   class="col-lg-3 col-md-6 col-sm-12 summary-input"
                   *ngFor="let question of pageTraverso.questions;let q=index">

                <div class="container-fluid mt-4">

                  <!-- QuestionType = 1: FILE (lo visualizza esternamente al mat-form-field altrimenti va in errore) -->
                  <div class="d-flex row container-img" *ngIf="question.questionType == 1">

                    <!-- Se il file è già presente allora non mostra l'uploader ma solo il nome del file con possibilità di cancellazione -->
                    <!--NOME FILE-->

                    <img *ngIf="question.fk_answerFile" style="width: 100px; height: 80px;"
                         [src]="'data:image/jpeg;base64,' + question.fk_answerFile.base64">

                    <img *ngIf="!question.fk_answerFile" style="width: 100px; height: 80px;" src="assets/img/img.png">

                    <!-- PREVIEW -->
                    <button data-container="mat-dialog-container" placement="bottom"
                            (click)="getFilePreview(question.fk_answerFile.data)" [ngbPopover]="popContent"
                            class="btn btn-primary delete-file ml-3" type="button" *ngIf="question.fk_answerFile">
                      <i class="fa fa-search"></i>
                    </button>

                    <ng-template #popContent>
                      <img style="max-width: 100%; max-height: 100%;" [src]="previewImage">
                    </ng-template>

                    <!--ELIMINA-->
                    <!-- <button disabled class="btn btn-danger delete-file ml-3" type="button" *ngIf="question.fk_answerFile">
                      <i class="fa fa-trash"></i>
                    </button> -->
                  </div>

                  <!-- MAT-FORM-FIELD: usato per formattare bene i controlli ad eccezione del file uploader -->
                  <mat-form-field disabled style="padding: 10px;" class="d-flex align-items-start marginBottom"
                                  *ngIf="question.questionType != 1 && question.questionType != 5" style="width: 50%;">
                    <mat-label>{{question.question}}</mat-label>

                    <!-- QuestionType = 2: TESTO -->
                    <input disabled type="text" matInput [(ngModel)]="question.answerVarchar"
                           name="text{{question.idQuestion}}" *ngIf="question.questionType == 2">

                    <!-- QuestionType = 3: BOOLEANO -->
                    <!-- QuestionType = 4: NUMERO -->

                  </mat-form-field>

                  <!-- QuestionType = 5: SCELTA CON RADIO BUTTON ( ho dovuto mettere anche questo fuori dal form-field perchè non supportato) -->
                  <div class="marginBottom" style="padding-top: 2%;" *ngIf="question.questionType == 5">
                    <h3 style="text-align: left;">{{question.question}}</h3>
                    <div *ngFor="let choice of question.multipleChoiceAnswerLst; let choiceIndex = index"
                         style="float: left;">
                      <!-- <input type="radio" class="example-radio-button" id="radio{{question.idQuestion}}_{{choiceIndex}}"
                      name="radio{{question.idQuestion}}_{{choiceIndex}}" [value]="choice"
                      (change)="question.fk_answerMultipleChoiseDetail = choice"
                      [checked]="question.fk_answerMultipleChoiseDetail != null && question.fk_answerMultipleChoiseDetail.id == choice.id"> -->
                      <mat-radio-button disabled [class.colorWhite]="choice.value === 'N.P.'"
                                        [class.colorGreen]="choice.value === '0.0'" [class.colorYellow]="choice.value === '0.2'"
                                        [class.colorRedLight]="choice.value === '0.5'" [class.colorRed]="choice.value === '1'"
                                        id="radio{{question.idQuestion}}_{{choiceIndex}}"
                                        name="radio{{question.idQuestion}}_{{choiceIndex}}" [value]="choice"
                                        (change)="question.fk_answerMultipleChoiseDetail = choice"
                                        [checked]="question.fk_answerMultipleChoiseDetail != null && question.fk_answerMultipleChoiseDetail.id == choice.id">
                      </mat-radio-button>
                      <!-- <label for="radio{{question.idQuestion}}_{{choiceIndex}}">{{choice.value}}</label> -->
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="PASTORALE">
          <div class="animated fadeIn container-fluid">
            <div class="row" *ngFor="let pagePastorale of summaryInvestigationWizardPastorale.sections">

              <div [hidden]="question.fk_answerFile== null && question.questionType== 1 && pagePastorale.section == null"
                   class="col-lg-3 col-md-6 col-sm-12 summary-input"
                   *ngFor="let question of pagePastorale.questions;let q=index">

                <div class="container-fluid mt-4">

                  <!-- QuestionType = 1: FILE (lo visualizza esternamente al mat-form-field altrimenti va in errore) -->
                  <div class="d-flex row container-img" *ngIf="question.questionType == 1">

                    <!-- Se il file è già presente allora non mostra l'uploader ma solo il nome del file con possibilità di cancellazione -->
                    <!--NOME FILE-->

                    <img *ngIf="question.fk_answerFile" style="width: 100px; height: 80px;"
                         [src]="'data:image/jpeg;base64,' + question.fk_answerFile.base64">

                    <img *ngIf="!question.fk_answerFile" style="width: 100px; height: 80px;" src="assets/img/img.png">

                    <!-- PREVIEW -->
                    <button data-container="mat-dialog-container" placement="bottom"
                            (click)="getFilePreview(question.fk_answerFile.data)" [ngbPopover]="popContent"
                            class="btn btn-primary delete-file ml-3" type="button" *ngIf="question.fk_answerFile">
                      <i class="fa fa-search"></i>
                    </button>

                    <ng-template #popContent>
                      <img style="max-width: 100%; max-height: 100%;" [src]="previewImage">
                    </ng-template>

                    <!--ELIMINA-->
                    <!-- <button disabled class="btn btn-danger delete-file ml-3" type="button" *ngIf="question.fk_answerFile">
                      <i class="fa fa-trash"></i>
                    </button> -->
                  </div>

                  <!-- MAT-FORM-FIELD: usato per formattare bene i controlli ad eccezione del file uploader -->
                  <mat-form-field disabled style="padding: 10px;" class="d-flex align-items-start marginBottom"
                                  *ngIf="question.questionType != 1 && question.questionType != 5" style="width: 50%;">
                    <mat-label>{{question.question}}</mat-label>

                    <!-- QuestionType = 2: TESTO -->
                    <input disabled type="text" matInput [(ngModel)]="question.answerVarchar"
                           name="text{{question.idQuestion}}" *ngIf="question.questionType == 2">

                    <!-- QuestionType = 3: BOOLEANO -->
                    <!-- QuestionType = 4: NUMERO -->

                  </mat-form-field>

                  <!-- QuestionType = 5: SCELTA CON RADIO BUTTON ( ho dovuto mettere anche questo fuori dal form-field perchè non supportato) -->
                  <div class="marginBottom" style="padding-top: 2%;" *ngIf="question.questionType == 5">
                    <h3 style="text-align: left;">{{question.question}}</h3>
                    <div *ngFor="let choice of question.multipleChoiceAnswerLst; let choiceIndex = index"
                         style="float: left;">
                      <!-- <input type="radio" class="example-radio-button" id="radio{{question.idQuestion}}_{{choiceIndex}}"
                      name="radio{{question.idQuestion}}_{{choiceIndex}}" [value]="choice"
                      (change)="question.fk_answerMultipleChoiseDetail = choice"
                      [checked]="question.fk_answerMultipleChoiseDetail != null && question.fk_answerMultipleChoiseDetail.id == choice.id"> -->
                      <mat-radio-button disabled [class.colorWhite]="choice.value === 'N.P.'"
                                        [class.colorGreen]="choice.value === '0.0'" [class.colorYellow]="choice.value === '0.2'"
                                        [class.colorRedLight]="choice.value === '0.5'" [class.colorRed]="choice.value === '1'"
                                        id="radio{{question.idQuestion}}_{{choiceIndex}}"
                                        name="radio{{question.idQuestion}}_{{choiceIndex}}" [value]="choice"
                                        (change)="question.fk_answerMultipleChoiseDetail = choice"
                                        [checked]="question.fk_answerMultipleChoiseDetail != null && question.fk_answerMultipleChoiseDetail.id == choice.id">
                      </mat-radio-button>
                      <!-- <label for="radio{{question.idQuestion}}_{{choiceIndex}}">{{choice.value}}</label> -->
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>

      <mat-dialog-actions>
        <!--CHIUDI-->
        <button mat-dialog-close matTooltip="CHIUDI" class="btn btn-primary btn-orange">
          <i class="fa fa-window-close fa-lg"></i>
        </button>
      </mat-dialog-actions>

    </div>
  </div>

</ng-template>
