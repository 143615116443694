<app-header [navbarBrandRouterLink]="['/dashboard']" [fixed]="true" [asideMenuToggler]="false"
  [mobileAsideMenuToggler]="false"
  [navbarBrandFull]="{src: 'assets/img/brand/platone.png', style: 'width: 100%; height: 98% ', alt: 'CoreUI Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/sygnet.svg', width: 30, height: 30, alt: 'CoreUI Logo'}"
  [sidebarToggler]="'lg'">
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
        dropdownToggle (click)="false">
        <img src="assets/img/avatars/6.png" class="img-avatar" alt="Profilo" />
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center">Benvenuto<strong> {{currentUser?.name}}
            {{currentUser?.surname}}</strong>
        </div>
        <a [routerLink]="['/user-profile']" class="dropdown-item" href="#"><i class="fa fa-user"></i> Profilo</a>
        <a (click)="logout()" class="dropdown-item" href="#"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <cui-breadcrumb>
      <!-- Breadcrumb Menu-->
      <!-- <li class="breadcrumb-menu d-md-down-none">
        <div class="btn-group" role="group" aria-label="Impostazioni">
          <a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>
        </div>
      </li> -->
    </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>

</div>
<app-footer>
  <span><img src="assets/img/brand/LogoSiasNero.svg" style="width: 6rem" />&copy; 2020</span>
</app-footer>

<!-- Modale Allegato -->
<ng-template #dialogImport>
  <app-import modelName="Item" (importKmzCompleted)="refreshPage()" (importCsvCompleted)="refreshPage()"></app-import>
</ng-template>