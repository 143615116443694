import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Investigation, InvestigationStatusEnum } from '../models/investigation';
import { InvestigationRow } from '../models/investigationRow';
import { SummaryInvestigation } from '../models/summaryInvestigation.model';
import { ItemState } from '../models/Item';
import { SummaryFilter } from '../models/summary-filter';

@Injectable({
    providedIn: 'root'
})

export class InvestigationService {

    constructor(private http: HttpClient) { }

    getListInvestigation(idItem): Promise<Array<Investigation>> {
        return this.http.get<Array<Investigation>>(environment.apiFullUrl + '/Investigation/GetListInvestigation/' + idItem).toPromise();
    }

    getInvestigation(idInvestigation): Promise<Investigation> {
        let url = environment.apiFullUrl + '/Investigation/GetInvestigation/' + idInvestigation;
        return this.http.get<Investigation>(url).toPromise();
    }

    saveInvestigation(investigation: Investigation): Promise<any> {
        return this.http.post<any>(environment.apiFullUrl + '/Investigation/SaveInvestigation', investigation).toPromise();
    }

    setInvestigationStatus(id: number, status: InvestigationStatusEnum): Promise<Investigation> {
        let url = environment.apiFullUrl + '/Investigation/SetInvestigationStatus/' + id + '/' + status;
        return this.http.get<Investigation>(url).toPromise();
    }

    deleteInvestigation(id: number): Promise<any> {
        return this.http.delete<any>(environment.apiFullUrl + '/Investigation/DeleteInvestigation/' + id).toPromise();
    }

    getSpecificQuestion(idInvestigationType): Promise<Array<InvestigationRow>> {
        let url = environment.apiFullUrl + '/Investigation/getSpecificQuestion' + idInvestigationType;
        return this.http.get<Array<InvestigationRow>>(url).toPromise();
    }

    getSummaryInvestigation(idItem?: number, filter?: SummaryFilter): Promise<Array<SummaryInvestigation>> {

        let currentFilter;

        if (filter) {
            currentFilter = JSON.parse(JSON.stringify(filter));
            if (currentFilter.IsValidated == -1)
                currentFilter.IsValidated = null;

            if (currentFilter.Stato == -1)
                currentFilter.Stato = null;

            if (currentFilter.Priorita == -1)
                currentFilter.Priorita = null;

            if (currentFilter.Pmv == -1)
                currentFilter.Pmv = null;

            if (currentFilter.IsCompleted == -1)
                currentFilter.IsCompleted = null;

            if (currentFilter.isDeleted == -1)
                currentFilter.isDeleted1 = null;

        } else {
            currentFilter = null;
        }

        return this.http.post<Array<SummaryInvestigation>>(environment.apiFullUrl + '/Investigation/GetSummaryInvestigation/' + idItem, currentFilter).toPromise();
    }

    setItemValidate(idItem: number, itemState: ItemState): Promise<any> {
        return this.http.get<any>(environment.apiFullUrl + '/Investigation/SetItemValidate/' + idItem + '/' + itemState).toPromise();
    }

    setInvestigationValidate(fk_item: number, isValidated: boolean): Promise<any> {
        return this.http.get<any>(environment.apiFullUrl + '/Investigation/SetInvestigationValidate/' + fk_item + '/' + isValidated).toPromise();
    }

}