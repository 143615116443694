import { User } from './../models/user.model';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) { }

    saveUser(user: User): Promise<any> {
        return this.http.post<any>(environment.apiFullUrl + '/user/SaveUser', user).toPromise()
    }

    deleteUser(id): Promise<any> {
        return this.http.delete<any>(environment.apiFullUrl + '/user/DeleteUser/' + id).toPromise()
    }

    getUser(id): Promise<User> {
        let url = environment.apiFullUrl + '/user/GetUser/' + id;
        return this.http.get<User>(url).toPromise()
    }

    updateUser(user: User): Promise<any> {
        return this.http.post<any>(environment.apiFullUrl + '/user/UpdateUser', user).toPromise()
    }

    getUserCompany(idCompany): Promise<any> {
        return this.http.get<any>(environment.apiFullUrl + '/user/GetUserCompany' + idCompany).toPromise();
    }

}