import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Investigation } from '../../../models/investigation';
import { DialogService } from './../../../services/dialog.service';
import { InvestigationService } from '../../../services/investigation.service';
import { InvestigationRow } from '../../../models/investigationRow';
import { InvestigationSection } from '../../../models/investigationSection';
import { SiasFileService } from '../../../services/siasFile.service';
import { ItemState } from '../../../models/Item';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ImageTransform, ImageCropperComponent, base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import imageCompression from 'browser-image-compression'
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-wizard-indagine',
  templateUrl: './wizard-indagine.component.html',
  styleUrls: ['./wizard-indagine.component.scss']
})
export class WizardIndagineComponent implements OnInit {

  currentInvestigationWizard: InvestigationWizard = null;
  selectedTab: number = 0;
  allFilesUploaded: Array<Blob> = new Array<Blob>();
  isLastTab: boolean = false;

  stringToClick;

  previewImage: SafeResourceUrl;

  aspectRatioVariable: number = 4 / 3;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  fileList = [];

  globalCurrentQuestion: InvestigationRow;
  currentFileName: string;

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  @ViewChild("dialogCrop", { static: false }) dialogCrop: TemplateRef<any>;
  @ViewChild('upload') upload: any;

  // Item Validato
  itemState: ItemState = 2;


  constructor(private activatedRoute: ActivatedRoute, private investigationService: InvestigationService, private router: Router, private siasFileService: SiasFileService, private sanitizer: DomSanitizer, public dialog: MatDialog) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.params["id"] != null) {
      this.investigationService.getInvestigation(this.activatedRoute.snapshot.params["id"])
        .then((res) => {
          this.initializeInvestigationWizardModel(res);
        })
        .catch((err) => {
          DialogService.Error(err.Message);
        })
    }
    else
      DialogService.Error("Non è stato possibile recuperare i dati per l'indagine");
  }

  initializeInvestigationWizardModel(investigationData: Investigation) {
    if (investigationData == undefined || investigationData == null)
      return;

    this.selectedTab = 0;
    this.currentInvestigationWizard = new InvestigationWizard();
    this.currentInvestigationWizard.investigation = investigationData;
    this.currentInvestigationWizard.sections = new Array<InvestigationWizardSections>();

    //Filtra tutte le domande che non risultano associate a nessuna sezione e, se presenti, le ordina per in base ai dati presenti sul DB
    //Le domande senza sezione di riferimento saranno riportate nel primo mat-tab statico del wizard (con etichetta Dati Generali)
    let noSectionQuestions = this.currentInvestigationWizard.investigation.lstInvestigationRow.filter(q => q.section == null);
    noSectionQuestions.sort((a, b) => { return a.orderNumber <= b.orderNumber ? -1 : 1 });
    let noSectionName: InvestigationWizardSections = new InvestigationWizardSections();
    noSectionName.questions = noSectionQuestions;
    noSectionName.section = null;
    this.currentInvestigationWizard.sections.push(noSectionName);
    // if (this.currentInvestigationWizard.noSectionQuestions != null && this.currentInvestigationWizard.noSectionQuestions.length > 0)
    //   this.currentInvestigationWizard.noSectionQuestions.sort((a, b) => {
    //     return a.orderNumber <= b.orderNumber ? -1 : 1;
    //   });

    //Recupera tutte le sezioni distinte, associate alle question, che andranno a comporre le pagine dell'indagine e le ordina in base al campo sectionOrder
    let allDistinctSections = this.currentInvestigationWizard.investigation.lstInvestigationRow.filter(q => q.section != null).filter((value, index, array) => array.map(a => a.section.id).indexOf(value.section.id) === index).map(q => q.section);
    allDistinctSections.sort((a, b) => {
      return a.sectionOrder <= b.sectionOrder ? -1 : 1;
    });

    //Popola le pagine del wizard (cioè le sezioni) inserendo in ognuna di esse le question in base alla configurazione che arriva dal DB
    allDistinctSections.forEach(section => {
      let wizardSection: InvestigationWizardSections = new InvestigationWizardSections();
      wizardSection.section = section;
      wizardSection.questions = this.currentInvestigationWizard.investigation.lstInvestigationRow.filter(q => q.section != null && q.section.id == section.id).sort((a, b) => {
        return a.orderNumber <= b.orderNumber ? -1 : 1;
      });
      this.currentInvestigationWizard.sections.push(wizardSection);
    });
    console.log(this.currentInvestigationWizard)
  }

  next() {
    this.selectedTab = this.selectedTab + 1;
  }

  previous() {
    this.selectedTab = this.selectedTab - 1;
  }

  save() {
    // console.log(this.currentInvestigationWizard.investigation);
    this.investigationService.saveInvestigation(this.currentInvestigationWizard.investigation).then((res) => {
      DialogService.Success("Indagine completata con successo");
      window.history.back();
    }).catch((err) => {
      DialogService.Error("Errore: " + err + " , Contattare l'amministratore del sistema");
    })
  }

  exit() {
    DialogService.Confirm("Sei sicuro di voler uscire dall'indagine?", () => {
      this.router.navigate(["../../indagine"], { relativeTo: this.activatedRoute });
    });
  }

  getCroppedAndUpload(file) {

    var self = this;

    var fileConverted: any = base64ToFile(file);
    fileConverted.name = self.currentFileName;

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    imageCompression(fileConverted, options).then((async fileCompressed => {

      self.fileList.push(fileCompressed);

      let savedFiles = await self.siasFileService.saveSiasFile(self.fileList, null, null);
      if (savedFiles) {
        self.globalCurrentQuestion.fk_answerFile = savedFiles[0];
        self.fileList = [];
      }
    }));

  }

  onFileChange(event, currentQuestion: InvestigationRow) {
    this.imageChangedEvent = event;
    this.globalCurrentQuestion = currentQuestion;
    this.currentFileName = event.target.files[0].name;

    this.dialog.open(this.dialogCrop, {
      width: '550px',
      autoFocus: false
    })

  }

  aspectRadioVertical() {
    this.aspectRatioVariable = 3 / 4;
  }

  aspectRadioHorizontal() {
    this.aspectRatioVariable = 4 / 3;
  }

  cropImage() {
    this.imageCropper.crop();
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.getCroppedAndUpload(this.croppedImage);
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  clearImageEvent() {
    this.imageChangedEvent = '';
    this.upload.nativeElement.value = '';
  }

  deleteFile(question: InvestigationRow) {
    this.siasFileService.deleteSiasFile(question.fk_answerFile.id)
      .then((res) => {
        question.fk_answerFile = null;
      })
      .catch((err) => {
        DialogService.Error(err.Message);
      })
  }

  // Forzo il salvataggio in qualsiasi punto dell'indagine e imposto lo stato a completato
  forceSave() {
    this.currentInvestigationWizard.investigation.statusInvestigation = 3;
    this.investigationService.saveInvestigation(this.currentInvestigationWizard.investigation).then((res) => {
      this.validateItem();
      DialogService.Success("Indagine completata con successo");
      window.history.back();
    }).catch((err) => {
      DialogService.Error("Errore: " + err + " , Contattare l'amministratore del sistema");
    })

  }

  validateItem() {
    this.investigationService.setItemValidate(this.currentInvestigationWizard.investigation.fk_item, this.itemState).then(() => {
    }).catch((err) => {
      DialogService.Error("Non è stato possibile validare l'item corrente: " + err)
    })
  }

  getId(question) {
    this.stringToClick = 'file_' + question.idQuestion;
    document.getElementById(this.stringToClick).click();
  }

  getFilePreview(base64) {
    this.previewImage = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64, ${base64}`);
  }




  /**
  * Convert a base64 string in a Blob according to the data and contentType.
  * 
  * @param b64Data {String} Pure base64 string without contentType
  * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
  * @param sliceSize {Int} SliceSize to process the byteCharacters
  * @return Blob
  */
  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }



}


/**
 * Classe d'appoggio che contiene tutta l'investigation e l'array di tutte le sezioni (ogni sezione sarà una pagina)
 */
export class InvestigationWizard {
  public investigation: Investigation;
  public sections: Array<InvestigationWizardSections>;
}
export class InvestigationWizardSections {
  public section: InvestigationSection;
  public questions: Array<InvestigationRow>;
}

