<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                Storico modifiche {{label}}
            </div>
            <div class="card-body">
                <div class="example-header">
                    <mat-form-field>
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtro">
                    </mat-form-field>
                </div>

                <div class="mat-table__wrapper" *ngIf="logs != null && logs.length >= 0">
                    <div class="example-container mat-row">

                        <mat-table [dataSource]="dataSourceLogs" matSort>

                            <ng-container matColumnDef="VersionDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Data versione
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.versionDate| date: 'dd/MM/yyyy HH:mm'}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="User">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Creato da
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.user.name + ' ' + row.user.surname}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Actions">
                                <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'actionWidth'">Azioni
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row; let i = index" [ngClass]="'actionWidth'">
                                    <button *ngIf="isDialog" mat-icon-button matTooltip="Visualizza"
                                        (click)="openData(row)" type="button" matDialogClose>
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button *ngIf="!isDialog" mat-icon-button matTooltip="Visualizza"
                                        (click)="openData(row)" type="button">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumnsLogs"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsLogs;">
                            </mat-row>
                        </mat-table>

                        <div *ngIf="logs == null || logs.length == 0" class="no-records">
                            Nessun log presente
                        </div>
                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
                    </div>
                </div>


                <mat-dialog-actions *ngIf="isDialog" class="posizioneBottoniModale">
                    <button class="btn btn-outline-primary marginBottoni" matDialogClose>CHIUDI</button>
                </mat-dialog-actions>

            </div>
        </div>
    </div>
</div>
