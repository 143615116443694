import { Company } from './company';
export class User {
  id: number;
  email: string;
  password: string;
  name: string;
  surname: string;
  phone: string;
  role: UserRole;
  fk_company?: Company;


  constructor() { }
}

export enum UserRole {
  Admin = 1,
  Tecnico = 2,
  Operatore = 3,
  Cliente = 4
}
