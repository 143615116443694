import { ItemState, ItemPriorityFilter } from './Item';
import { Highway } from './highway';
import { Company } from './company';
import { ItemLocation } from './itemLocation';
import { Exit } from './Exit';
import { Roadway } from './roadway';
import { ItemType } from './itemType';

export class SummaryFilter {
    IsValidated: boolean;
    Stato: ItemState;
    Cliente: Company;
    Strada: Highway;
    Ubicazione: ItemLocation;
    Uscita: Exit;
    Posizione: string;
    Carreggiata: Roadway;
    Tipologia: ItemType;
    Pmv: boolean;
    Indicazione: string;
    Priorita: ItemPriorityFilter;
    IsCompleted: boolean;
    isDeleted: boolean;

}