import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home'
  },
  {
    name: 'Anagrafiche',
    url: '/anagrafiche',
    icon: 'icon-docs',
    children: [
      {
        name: 'Clienti',
        url: '/company',
        icon: 'icon-people'
      },
      {
        name: 'Item',
        url: '/add-item',
        icon: 'icon-layers'
      }

    ]
  },
  {
    name: 'Attività',
    url: '/att',
    icon: 'icon-graph',
    children: [
      {
        name: 'Indagini',
        url: '/list-indagine',
        icon: 'icon-briefcase'
      },
      {
        name: 'Manutenzioni',
        url: '/404',
        icon: 'icon-wrench'
      }

    ]
  },
  {
    name: 'Imp. Sistema',
    url: '/sistema',
    icon: 'icon-settings',
    children: [
      {
        name: 'Gruppi e utenti',
        url: '/404',
        icon: 'icon-user-following'
      },
      {
        name: 'Gestione Template',
        url: '/404',
        icon: 'icon-doc'
      },
      {
        name: 'Gestione Stampe',
        url: '/404',
        icon: 'icon-printer'
      },
      {
        name: 'Gestione Import',
        url: '/',
        icon: 'icon-cloud-upload',
        class: 'import'
      },
    ]
  }
];
