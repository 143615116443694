import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Company } from '../../models/company';
import { CompanyService } from '../../services/company.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { DialogService } from '../../services/dialog.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  displayedColumns = ['Codice', 'Ragione Sociale', 'Azioni']

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("dialogAddCompany", { static: false }) dialogAddCompany: TemplateRef<any>;
  @ViewChild("dialogUpdateCompany", { static: false }) dialogUpdateCompany: TemplateRef<any>;

  dataSource: MatTableDataSource<Company>;

  companyRegisterForm: FormGroup;
  companyUpdateForm: FormGroup;

  company: Company = new Company();

  allCompany: Array<Company> = new Array<Company>();
  dialogRef: any;


  constructor(
    private companyService: CompanyService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.reloadCompany();
    this.createForm();

  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Rimuovo lo spazio bianco
    filterValue = filterValue.toLowerCase(); // Ricerca di default con caratteri minuscoli
    this.dataSource.filter = filterValue;
  }

  reloadCompany() {
    this.companyService.getAllCompany().then((resCompany) => {
      this.allCompany = resCompany;
      this.dataSource = new MatTableDataSource(resCompany);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
      .catch((err) => {
      })
  }

  createForm() {

    if (this.company == null)
      this.company = new Company();

    this.companyRegisterForm = this.formBuilder.group({
      code: [this.company.code, Validators.required],
      businessName: [this.company.businessName, Validators.required],
    })
  }


  addCompany() {

    this.companyService.saveCompany(this.company)
      .then((res) => {
        Swal.fire({
          title: 'Cliente inserito correttamente',
          icon: 'success',
        })
        this.reloadCompany();
        this.dialog.closeAll();
      })
      .catch((err) => {
        Swal.fire({
          title: err,
          icon: 'error'
        })
      });

  }

  deleteCompany(idCompany: number) {
    DialogService.Confirm("Sei sicuro di voler eliminare il cliente selezionato?", () => {
      this.companyService.deleteCompany(idCompany).then(() => {
        this.reloadCompany();
      });
    })
  }

  updateCompany(company: Company) {
    DialogService.Confirm("Sei sicuro di voler aggiornare il cliente selezionato?", () => {
      this.companyService.updateCompany(company).then(() => {
        DialogService.Success("Cliente aggiornato!");
        this.dialog.closeAll();
        this.reloadCompany();
      })
    })
  }

  editCompany(i) {
    var cloneCompany = JSON.parse(JSON.stringify(this.allCompany[i]));

    this.openUpdateDialog(cloneCompany);
  }

  openDialog(): void {
    this.dialog.open(this.dialogAddCompany, {
      width: '550px',
      autoFocus: false
    });
  }

  openUpdateDialog(company: Company): void {
    this.dialog.open(this.dialogUpdateCompany, {
      data: company,
      width: '550px',
      autoFocus: false,
    });
  }

}

