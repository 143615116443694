import { User } from './../models/user.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ItemType } from '../models/itemType';

@Injectable({
  providedIn: 'root'
})
export class ItemTypeService {

  constructor(private http: HttpClient) { }

  getAllItemType(): Promise<Array<ItemType>> {
    return this.http.get<Array<ItemType>>(environment.apiFullUrl + '/ItemType/GetAllItemType').toPromise();
  }

  getUserCompany(idCompany): Promise<Array<User>> {
    let url = environment.apiFullUrl + '/ItemType/GetUserCompany' + idCompany;
    return this.http.get<Array<User>>(url).toPromise()
  }
}
