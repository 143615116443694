import { Company } from './../models/company';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    constructor(private http: HttpClient) { }

    getAllCompany(): Promise<Array<Company>> {
        return this.http.get<Array<Company>>(environment.apiFullUrl + '/company/GetAllCompany').toPromise()
    }

    deleteCompany(id): Promise<any> {
        return this.http.delete<any>(environment.apiFullUrl + '/company/DeleteCompany/' + id).toPromise()
    }

    // getCompany(id): Promise<Company> {
    //     let url = environment.apiFullUrl + '/user/GetCompany/' + id;
    //     return this.http.get<Company>(url).toPromise()
    // }

    updateCompany(company: Company): Promise<any> {
        return this.http.post<any>(environment.apiFullUrl + '/company/UpdateCompany', company).toPromise()
    }

    saveCompany(company: Company): Promise<Company> {
        return this.http.post<Company>(environment.apiFullUrl + '/company/SaveCompany', company).toPromise()
    }


}