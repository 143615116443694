import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    constructor(private http: HttpClient) { }

    generateDocument(fk_template: number, fk_entity: number, fk_company: number, additionalWherePart = null): Promise<any> {
        return this.http.post<any>(environment.apiFullUrl + '/Document/GenerateDocument/' + fk_template + '/' + fk_entity + '/' + fk_company, additionalWherePart).toPromise()
    }


}