<div class="brand-card">
  <div class="card-header">Clienti</div>
  <div class="brand-card-body">
    <div class="container-fluid">

      <form>
        <div class="mat-table__wrapper">
          <div class="example-container mat-elevation-z8 mat-row">

            <!--TABELLA CLIENTI-->
            <mat-table [dataSource]="dataSource" matSort>

              <!--CODICE-->
              <ng-container matColumnDef="Codice">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Codice
                </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.code}} </mat-cell>
              </ng-container>

              <!--RAGIONE SOCIALE-->
              <ng-container matColumnDef="Ragione Sociale">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Ragione Sociale
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.businessName}}
                </mat-cell>
              </ng-container>

              <!--AZIONI-->
              <ng-container matColumnDef="Azioni">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                </mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                  <!--MODIFICA-->
                  <mat-icon matTooltip="MODIFICA" style="color:#2f353a; font-size: 19px;" class="pointer" (click)="editCompany(i)">edit</mat-icon>
                  <!--ELIMINA-->
                  <mat-icon matTooltip="ELIMINA" style="color:red; font-size: 19px;" class="pointer" (click)="deleteCompany(row.id)">delete</mat-icon>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;">
              </mat-row>
            </mat-table>

            <!--PAGINAZIONE-->
            <mat-paginator class="paginatorDashboard" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>
        </div>
      </form>

      <!--AZIONI-->
      <div class="row mt-4">
        <div class="col-sm-12 text-right">

          <!--INDIETRO-->
          <button matTooltip="INDIETRO" class="btn btn-primary btn-orange mr-1" type="button" [routerLink]="['/dashboard']" routerLinkActive="router-link-active">
            <i class="icon-arrow-left"></i>
          </button>

          <!--AGGIUNGI-->
          <button matTooltip="AGGIUNGI CLIENTE" (click)="openDialog()"
                  class="btn btn-primary btn-orange" type="button">
            <i class="icon-user-follow"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--MODALE AGGIUNGI CLIENTE-->
<ng-template #dialogAddCompany>
  <div style="border: 1px solid #c8ced3;" class="card-header">Aggiungi cliente</div>
  <div class="brand-card-body">
    <form [formGroup]="companyRegisterForm">
      <div class="row">

        <!--CODICE-->
        <div class="col-sm-12">
          <mat-form-field class="full-width" style="width:92%">
            <mat-label>Codice</mat-label>
            <input matInput formControlName="code" [(ngModel)]="company.code">
          </mat-form-field>
        </div>

        <!--RAGIONE SOCIALE-->
        <div class="col-sm-12">
          <mat-form-field class="full-width" style="width:92%">
            <mat-label>Ragione Sociale</mat-label>
            <input matInput formControlName="businessName" [(ngModel)]="company.businessName">
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12 text-right">

      <!--INDIETRO-->
      <button matTooltip="INDIETRO" class="btn btn-primary btn-orange mr-1" matDialogClose>
        <i class="icon-arrow-left"></i>
      </button>

      <!--AGGIUNGI CLIENTE-->
      <button matTooltip="AGGIUNGI" class="btn btn-primary btn-orange" [disabled]="companyRegisterForm.invalid" (click)="addCompany()">
        <i class="icon-user-follow"></i>
      </button>
    </div>
  </div>
</ng-template>

<!--MODALE MODIFICA CLIENTE-->
<ng-template #dialogUpdateCompany let-data>
  <div style="border: 1px solid #c8ced3;" class="card-header">Modifica cliente</div>
  <div class="brand-card-body">
    <form [formGroup]="companyRegisterForm">

      <!--CODICE-->
      <div class="col-sm-12">
        <mat-form-field class="full-width" style="width:92%">
          <mat-label>Codice</mat-label>
          <input matInput formControlName="code" [(ngModel)]="data.code">
        </mat-form-field>
      </div>

      <!--RAGIONE SOCIALE-->
      <div class="col-sm-12">
        <mat-form-field class="full-width" style="width:92%">
          <mat-label>Ragione Sociale</mat-label>
          <input matInput formControlName="businessName" [(ngModel)]="data.businessName">
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="row mt-3">
    <div class="col-sm-12 text-right">

      <!--INDIETRO-->
      <button matTooltip="INDIETRO" class="btn btn-primary btn-orange mr-1" matDialogClose>
        <i class="icon-arrow-left"></i>
      </button>

      <!--AGGIUNGI CLIENTE-->
      <button matTooltip="SALVA MODIFICA" class="btn btn-primary btn-orange" [disabled]="companyRegisterForm.invalid" (click)="updateCompany(data)">
        <i class="icon-pencil"></i>
      </button>
    </div>
  </div>

</ng-template>
