import { Item } from './../models/Item';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiasFile } from '../models/siasFile';
import { FilterDashboard } from '../models/dashboard-filter';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(private http: HttpClient) { }

  saveItem(item: Item): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + '/item/InsertItem', item).toPromise()
  }

  updateItem(item: Item): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + '/item/UpdateItem', item).toPromise()
  }

  deleteItem(id: number, deleted: boolean): Promise<any> {
    let url = environment.apiFullUrl + '/item/DeleteItem/' + id + '/' + deleted;
    return this.http.get<any>(url).toPromise()
  }

  getItem(id): Promise<Item> {
    let url = environment.apiFullUrl + '/item/GetItem/' + id;
    return this.http.get<Item>(url).toPromise()
  }

  getAllItem(filter?: FilterDashboard): Promise<Array<Item>> {
    let currentFilter = JSON.parse(JSON.stringify(filter));
    if (currentFilter.Stato == -1)
      currentFilter.Stato = null;

    if (currentFilter.Priorita == -1)
      currentFilter.Priorita = null;

    if (currentFilter.Pmv == -1)
      currentFilter.Pmv = null;

    if (currentFilter.IsCompleted == -1)
      currentFilter.IsCompleted = null;

    if (currentFilter.isDeleted == -1)
      currentFilter.isDeleted = null;

    return this.http.post<Array<Item>>(environment.apiFullUrl + '/item/GetAllItem', currentFilter).toPromise();
  }

}
