import { User, UserRole } from './../models/user.model';
import { environment } from './../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public currentUser: string;

    public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.tokenAvailable());

    get isLoggedIn() {
        return this.loggedIn.asObservable();
    }


    constructor(private http: HttpClient, private router: Router) {
        this.currentUser = localStorage.getItem('currentUserSias');
    }

    login(email: string, password: string): Promise<any> {
        let user: User = new User();
        user.email = email;
        user.password = password;
        return this.http.post<any>(environment.apiUrl + '/api/auth/login', user).toPromise();
    }

    logout() {
        // Al logout rimuovo l'utente dal local storage
        this.loggedIn.next(false);
        localStorage.removeItem('currentUserSias');
        this.router.navigate(['/login']);
    }

    register(email: string, name: string, surname: string, phone: string, role: UserRole): Promise<any> {
        let user: User = new User();
        user.email = email;
        user.name = name;
        user.surname = surname;
        user.phone = phone;
        user.role = role;
        return this.http.post<any>(environment.apiUrl + '/api/auth/register', user).toPromise();
    }

    getCurrentUser(): User {
        //Richiamo direttamente lo user
        return helper.decodeToken(localStorage.getItem('currentUserSias'));
    }

    getCurrentToken() {
        //Richiamo direttamente lo user
        return localStorage.getItem('currentUserSias');
    }

    tokenAvailable(): boolean {
        return helper.decodeToken(localStorage.getItem('currentUserSias'));
    }

}
