<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <div class="clearfix">
          <h1 style="font-size: 3em;">404</h1>
          <h2>Ops! La pagina non può essere trovata.</h2>
          <p>Ti sei perso? Ti indichiamo la strada!</p>
          <button [routerLink]="['/dashboard']" class="btn btn-primary btn-orange mb-5">Torna alla Dashboard</button>
          <img style="width:100%" src="../../../assets/img/404page.png" />
        </div>
        
      </div>
      
    </div>
  </div>
</div>
