import { AuthGuard } from './helpers/auth.guard';
import { WizardIndagineComponent } from './components/indagine/wizard-indagine/wizard-indagine.component';
import { IndagineComponent } from './components/indagine/indagine.component';
import { AddItemComponent } from './components/item/add-item/add-item.component';
import { ItemComponent } from './components/item/item.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { CompanyComponent } from './components/company/company.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ListIndagineComponent } from './components/indagine/list-indagine/list-indagine.component';

export const routes: Routes = [

  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
        data: {
          title: 'Profile'
        },
      },
      {
        path: 'item',
        component: ItemComponent,
        data: {
          title: 'Item'
        }
      },
      {
        path: 'item/:id',
        component: ItemComponent,
        data: {
          title: 'Dettaglio Item'
        }
      },
      {
        path: 'item/:id/indagine',
        component: IndagineComponent,
        data: {
          title: 'Indagine'
        }
      },
      {
        path: 'add-item',
        component: AddItemComponent,
        data: {
          title: 'Aggiungi Item'
        }
      },
      {
        path: 'add-item/:id',
        component: AddItemComponent,
        data: {
          title: 'Modifica Item'
        }
      },
      {
        path: 'add-item/:id/:fk_dataLogVersion',
        component: AddItemComponent,
        data: {
          title: 'Restore Item'
        }
      },
      {
        path: 'indagine',
        component: IndagineComponent,
        data: {
          title: 'Indagine'
        }
      },
      {
        path: 'item/:id/wizard/:id',
        component: WizardIndagineComponent,
        data: {
          title: 'Compila indagine'
        }
      },
      {
        path: 'company',
        component: CompanyComponent,
        data: {
          title: 'Cliente'
        }
      },
      {
        path: 'list-indagine',
        component: ListIndagineComponent,
        data: {
          title: 'Indagini'
        }
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Dashboard'
        }
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
