import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DataLogVersion } from '../models/dataLogVersion';

@Injectable({
  providedIn: 'root'
})
export class DataLogVersionService {

  constructor(private http: HttpClient) { }

  getAllDataLogVersions(id: number, className: string): Promise<Array<DataLogVersion>> {
    return this.http.get<Array<DataLogVersion>>(environment.apiFullUrl + '/DataLogVersion/getAllDataLogVersions/' + id + "/" + className).toPromise()
  }

  getDataLogVersion(id: number): Promise<DataLogVersion> {
    return this.http.get<DataLogVersion>(environment.apiFullUrl + '/DataLogVersion/getDataLogVersion/' + id).toPromise()
  }
}
