import { AuthService } from './../../services/auth.service';
import { Component, ViewChild, TemplateRef, OnInit, AfterViewInit, ElementRef, ChangeDetectorRef, Output } from '@angular/core';
import { navItems } from '../../_nav';
import { CsvService } from '../../services/csv.service';
import { DialogService } from '../../services/dialog.service';
import { KmzService } from '../../services/kmz.service';
import { EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../models/user.model';
import { PreviousRouteService } from '../../helpers/previousRoute.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements AfterViewInit {
  public sidebarMinimized = false;
  public navItems = navItems;

  modelName: string = "Item";

  currentUser: User;

  public previousRoute;
  public currentRoute;

  // @Output("importCsvCompleted") importCsvCompleted: EventEmitter<any> = new EventEmitter<any>();
  // @Output("importKmzCompleted") importKmzCompleted: EventEmitter<any> = new EventEmitter<any>();


  @ViewChild("dialogImport", { static: false }) dialogImport: TemplateRef<any>;

  constructor(
    private authenticationService: AuthService, private elementRef: ElementRef, private dialog: MatDialog, private previousRouteService: PreviousRouteService) {

    this.getCurrentUser();
    this.previousRoute = this.previousRouteService.getPreviousUrl();
    this.currentRoute = this.previousRouteService.getCurrentUrl();
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.querySelector('.import'))
      this.elementRef.nativeElement.querySelector('.import').addEventListener('click', this.onClick.bind(this));
  }

  onClick(event) {
    this.openImportDialog();
    // console.log(event);
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.authenticationService.logout();
  }

  openImportDialog(): void {
    this.dialog.open(this.dialogImport, {
      width: '550px',
      autoFocus: false,
    });
  }

  refreshPage() {
    window.location.reload();
  }

  getCurrentUser() {
    this.currentUser = this.authenticationService.getCurrentUser();
  }



}
