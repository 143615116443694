<div class="brand-card" *ngIf="currentInvestigationWizard">
  <div class="card-header">
    {{currentInvestigationWizard.investigation.fk_investigationType.name}} -
    {{currentInvestigationWizard.sections[selectedTab].section != null ? currentInvestigationWizard.sections[selectedTab].section.page?.name : 'Dati Generali'}}
  </div>
  <div style="min-height: calc(100vh - 47vh);" class="brand-card-body">


    <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedTab" class="larghezzaWizard mat-tab-fill-height">

      <!-- TAB dinamici costruiti sulla base delle section-->
      <mat-tab [label]="wizardSection.section ? wizardSection.section.name : 'Dati generali'"
        *ngFor="let wizardSection of currentInvestigationWizard.sections; let i = index" [disabled]="selectedTab != i">

        <!-- Array delle domande con creazione dei corrispondenti campi di input -->
        <div *ngFor="let question of wizardSection.questions; let q = index">
          <div class="container-fluid mt-2">

            <!-- QuestionType = 1: FILE (lo visualizza esternamente al mat-form-field altrimenti va in errore) -->
            <div class="d-flex row" *ngIf="question.questionType == 1">

              <!-- Se il file è già presente allora non mostra l'uploader ma solo il nome del file con possibilità di cancellazione -->

              <!--SCEGLI FILE-->
              <input #upload #file_{{question.idQuestion}} style="display:none;" class="ml-3 inputFile"
                *ngIf="question.fk_answerFile == undefined || question.fk_answerFile == null"
                id="file_{{question.idQuestion}}" name="file_{{question.idQuestion}}" type="file"
                accept="image/*;capture=camera" (change)="onFileChange($event, question)" />

              <button (click)="getId(question)" class=" label" id="file_{{question.idQuestion}}" mat-raised-button
                color="accent">
                <mat-icon>cloud_upload</mat-icon>
              </button>


              <!--NOME FILE-->
              <input class="ml-3 file-name" *ngIf="question.fk_answerFile" type="text" readonly
                [(ngModel)]="question.fk_answerFile.name">

              <!-- PREVIEW -->
              <button data-container="body" placement="right" (click)="getFilePreview(question.fk_answerFile.data)"
                [ngbPopover]="popContent" class="btn btn-primary delete-file ml-3" type="button"
                *ngIf="question.fk_answerFile"><i class="fa fa-search"></i></button>

              <ng-template #popContent>
                <img style="max-width: 100%; max-height: 100%;" [src]="previewImage">
              </ng-template>

              <!--ELIMINA-->
              <button class="btn btn-danger delete-file ml-3" type="button" *ngIf="question.fk_answerFile"
                (click)="deleteFile(question)"><i class="fa fa-trash"></i></button>
            </div>

            <!-- MAT-FORM-FIELD: usato per formattare bene i controlli ad eccezione del file uploader -->
            <mat-form-field style="padding: 10px;" class="d-flex align-items-start marginBottom"
              *ngIf="question.questionType != 1 && question.questionType != 5">
              <mat-label>{{question.question}}</mat-label>

              <!-- QuestionType = 2: TESTO -->
              <input type="text" matInput [(ngModel)]="question.answerVarchar" name="text{{question.idQuestion}}"
                *ngIf="question.questionType == 2">

              <!-- QuestionType = 3: BOOLEANO -->
              <!-- QuestionType = 4: NUMERO -->

            </mat-form-field>

            <!-- QuestionType = 5: SCELTA CON RADIO BUTTON ( ho dovuto mettere anche questo fuori dal form-field perchè non supportato) -->
            <div class="marginBottom" style="padding-top: 2%;" *ngIf="question.questionType == 5">
              <h3 style="text-align: left;">{{question.question}}</h3>
              <div *ngFor="let choice of question.multipleChoiceAnswerLst; let choiceIndex = index"
                style="float: left;">
                <!-- <input type="radio" class="example-radio-button" id="radio{{question.idQuestion}}_{{choiceIndex}}"
                  name="radio{{question.idQuestion}}_{{choiceIndex}}" [value]="choice"
                  (change)="question.fk_answerMultipleChoiseDetail = choice"
                  [checked]="question.fk_answerMultipleChoiseDetail != null && question.fk_answerMultipleChoiseDetail.id == choice.id"> -->
                <mat-radio-button [class.colorWhite]="choice.value === 'N.P.'"
                  [class.colorGreen]="choice.value === '0.0'" [class.colorYellow]="choice.value === '0.2'"
                  [class.colorRedLight]="choice.value === '0.5'" [class.colorRed]="choice.value === '1'"
                  id="radio{{question.idQuestion}}_{{choiceIndex}}" name="radio{{question.idQuestion}}_{{choiceIndex}}"
                  [value]="choice" (change)="question.fk_answerMultipleChoiseDetail = choice"
                  [checked]="question.fk_answerMultipleChoiseDetail != null && question.fk_answerMultipleChoiseDetail.id == choice.id">
                </mat-radio-button>
                <!-- <label for="radio{{question.idQuestion}}_{{choiceIndex}}">{{choice.value}}</label> -->
              </div>
            </div>
          </div>
        </div>

        <div style="width: 100%;" class="row" *ngIf="selectedTab == 0">
          <div class="col-md-12">
            <mat-checkbox [(ngModel)]="currentInvestigationWizard.investigation.notDetectable">Non rilevabile
            </mat-checkbox>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div class="row">
      <div class="col-md-12 prevNextButtons">
        <button matTooltip="Indietro" class="btn btn-primary btn-orange" (click)="previous()" *ngIf="selectedTab != 0">
          <i class="fa fa-arrow-left"></i>
        </button>
        <button matTooltip="Esci" class="btn btn-primary btn-orange" (click)="exit()" *ngIf="selectedTab == 0">
          <i class="fa fa-close"></i>
        </button>
        <button matTooltip="Avanti" class="btn btn-primary btn-orange" (click)="next()"
          *ngIf="!currentInvestigationWizard.investigation.notDetectable && selectedTab != currentInvestigationWizard.sections.length - 1">
          <i class="fa fa-arrow-right"></i>
        </button>
        <button class="btn btn-primary btn-orange" (click)="save()"
          *ngIf="currentInvestigationWizard.investigation.notDetectable || selectedTab == currentInvestigationWizard.sections.length - 1 ">
          <i class="fa fa-save"></i>
        </button>
        <button matTooltip="Forza il completamente dell'indagine" class="btn btn-primary btn-orange"
          (click)="forceSave()" *ngIf="selectedTab != 0 ">
          <i class="fa fa-save"></i>
        </button>
      </div>
    </div>

  </div>
</div>

<!--dialog Modifica immagine-->
<ng-template #dialogCrop>
  <div class="card">
    <div class="card-header"> MODIFICA IMMAGINE</div>
    <div class="card-body" id="crop">

      <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [autoCrop]="false"
        [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="aspectRatioVariable"
        [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
        [transform]="transform" [alignImage]="'left'" format="png" (imageCropped)="imageCropped($event)">
      </image-cropper>

      <div class="row mt-3  justify-content-center">

        <!-- <div class="col-md-auto edit-img p-1">
          <button (click)="rotateLeft()" matTooltip="Ruota a sinistra" class="btn btn-primary btn-dark">
            <i class="fa fa-rotate-left"></i>
          </button>
        </div>

        <div class="col-md-auto edit-img p-1">
          <button (click)="rotateRight()" matTooltip="Ruota a destra" class="btn btn-primary btn-dark">
            <i class="fa fa-rotate-right"></i>
          </button>
        </div> -->

        <div class="col-md-auto edit-img p-1">
          <button (click)="aspectRadioHorizontal()" matTooltip="Ritaglia orizzontalmente"
            class="btn btn-primary btn-dark">
            <i class="fa fa-arrows-h"></i>
          </button>
        </div>

        <div class="col-md-auto edit-img p-1">
          <button style="padding-right: 17px; padding-left: 17px;" (click)="aspectRadioVertical()"
            matTooltip="Ritaglia verticalmente" class="btn btn-primary btn-dark">
            <i class="fa fa-arrows-v"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>
    <!--CONFERMA-->
    <button mat-dialog-close (click)="cropImage()" matTooltip="CONFERMA"
      class="btn btn-primary btn-orange float-right mr-2">
      <i class="fa fa-check"></i>
    </button>

    <!--CHIUDI-->
    <button (click)="clearImageEvent()" mat-dialog-close matTooltip="CHIUDI"
      class="btn btn-primary btn-orange mr-1 float-right">
      <i class="fa fa-window-close"></i>
    </button>
  </div>
</ng-template>