import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ItemLocation } from '../models/itemLocation';

@Injectable({
    providedIn: 'root'
  })
  export class ItemLocationService {
  
    constructor(private http: HttpClient) { }
    
    getAllItemLocation(): Promise<Array<ItemLocation>>{
        return this.http.get<Array<ItemLocation>>(environment.apiFullUrl + '/ItemLocation/getAllItemLocation').toPromise()
    }


}

