import { SiasFile } from './../../models/siasFile';
import { SiasFileService } from './../../services/siasFile.service';
import { DialogService } from './../../services/dialog.service';
import { ItemService } from './../../services/item.service';
import { MatTableDataSource } from '@angular/material/table';
import { Item } from './../../models/Item';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, ViewChild, OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataLogVersion } from '../../models/dataLogVersion';
import { Router } from '@angular/router';
import { CsvService } from '../../services/csv.service';
import { SpinnerService } from '../../services/spinner.service';
import { FilterDashboard } from '../../models/dashboard-filter';
import { CompanyService } from '../../services/company.service';
import { HighwayService } from '../../services/highway.service';
import { ItemLocationService } from '../../services/itemLocation.service';
import { ItemTypeService } from '../../services/itemType.service';
import { RoadwayService } from '../../services/roadway.service';
import { Company } from '../../models/company';
import { Highway } from '../../models/highway';
import { ItemLocation } from '../../models/itemLocation';
import { Exit } from '../../models/Exit';
import { ItemType } from '../../models/itemType';
import { Roadway } from '../../models/roadway';
import { DomSanitizer } from '@angular/platform-browser';
import { AgmMap } from '@agm/core';

@Component({
  templateUrl: 'dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']

})
export class DashboardComponent implements OnInit {

  public isCollapsed = false;

  // DisplayedColumns = Colonne visualizzate nell'html
  displayedColumns = ['Stato', 'firstCompilationDate', 'cod', 'fk_company', 'fk_highway', 'fk_itemLocation', 'Posizione', 'fk_roadway', 'fk_itemType', 'preparation', 'indication', 'note', 'global_degradation', 'intervention_priority', 'State', 'Actions'];

  displayedColumnsAttachments = ['Data', 'Nome File', 'Actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<Item>;
  dataSourceTables: MatTableDataSource<Item>;
  dataSourceAttachments: MatTableDataSource<SiasFile>;
  attachments: Array<SiasFile> = new Array<SiasFile>();
  @ViewChild("dialogAttachments", { static: false }) dialogAttachments: TemplateRef<any>;

  @ViewChild("dialogFilter", { static: false }) dialogFilter: TemplateRef<any>;

  IsFirstCall: boolean;
  isFilterApplied: boolean;

  selectedRowIndex: number = -1;
  pageAlreadyLoaded: boolean = false;

  items: Array<Item>;

  item: Item;

  currentItemSelected: Item = new Item();

  attachmentType: number = 1;

  noData: boolean = false;

  noDataDashboard: boolean = false;

  allFilesUploaded: Array<Blob> = new Array<Blob>();

  customFileProperties: any[] = [];

  isMapVisible: boolean = true;

  isTableVisible: boolean = true;

  previous;

  previewItemImage;

  noMatchText: string = "Nessun risultato trovato";

  // Variabili per la gestione dei report
  totalItems: number;
  notCompletedItems: number;
  interventionPriority3: number;
  interventionPriority2: number;
  interventionPriority1: number;
  investigationDone: number;
  investigationNotDone: number;


  // Centro iniziale della mappa
  lat: number;
  lng: number;
  zoom: number = 9;
  icon;
  @ViewChild('AgmMap') agmMap: AgmMap;
  currentBound;
  isFilterMapApplied: boolean;

  basePath: string = "../../assets/";

  //Sezione datalogs item
  @ViewChild("dialogLogs", { static: false }) dialogLogs: TemplateRef<any>;
  modalLogsReady = false;

  filter: FilterDashboard = new FilterDashboard();
  allCompany: Array<Company> = new Array<Company>();
  allHighway: Array<Highway> = new Array<Highway>();
  allItemLocation: Array<ItemLocation> = new Array<ItemLocation>();
  allHighwayExit: Array<Exit> = new Array<Exit>();
  allItemType: Array<ItemType> = new Array<ItemType>();
  allRoadway: Array<Roadway> = new Array<Roadway>();
  allPosition: Array<string> = new Array<string>();
  allIndication: Array<string> = new Array<string>();

  itemPriority: any[] = [
    { Key: -1, Value: "Tutti" },
    { Key: 1, Value: "Intervento tempestivo", Avatar: "../../../assets/img/red_circle.png" },
    { Key: 2, Value: "Intervento urgente", Avatar: "../../../assets/img/yellow_circle.png" },
    { Key: 3, Value: "Intervento ordinario", Avatar: "../../../assets/img/green_circle.png" }
  ];

  itemState: any[] = [
    { Key: -1, Value: "Tutti" },
    { Key: 2, Value: "Fatta", Avatar: "../../../assets/img/blu_circle.png" },
    { Key: 1, Value: "Non fatta", Avatar: "../../../assets/img/orange_circle.png" },
  ];

  itemPmv: any[] = [
    { Key: -1, Value: "Tutti" },
    { Key: true, Value: "Presente", Avatar: "../../../assets/img/check.png" },
    { Key: false, Value: "Non presente" },
  ];

  itemCompleted: any[] = [
    { Key: -1, Value: "Tutti" },
    { Key: true, Value: "Censito" },
    { Key: false, Value: "Non censito" },
  ];

  itemDeleted: any[] = [
    { Key: -1, Value: "Tutti" },
    { Key: true, Value: "Eliminato" },
    { Key: false, Value: "Non eliminato" },
  ];

  constructor(private itemService: ItemService, public dialog: MatDialog, private siasFileService: SiasFileService, private router: Router,
    private cd: ChangeDetectorRef, private csvService: CsvService, private spinnerService: SpinnerService,
    private companyService: CompanyService, private highwayService: HighwayService, private itemLocationService: ItemLocationService, private itemTypeService: ItemTypeService, private roadwayService: RoadwayService, private sanitizer: DomSanitizer
  ) {

    this.filter = new FilterDashboard();
    this.filter.Carreggiata = null;
    this.filter.Cliente = null;
    this.filter.IsCompleted = null;
    this.filter.Indicazione = null;
    this.filter.Pmv = null;
    this.filter.Posizione = null;
    this.filter.Priorita = null;
    this.filter.Stato = null;
    this.filter.Strada = null;
    this.filter.Tipologia = null;
    this.filter.Ubicazione = null;
    this.filter.Uscita = null;
    this.filter.isDeleted = null;
  }


  ngOnInit() {
    this.IsFirstCall = true;
    this.isFilterApplied = false;
    this.reloadItems();
  }

  resetFilter() {
    localStorage.removeItem('filterDashboard');
    this.filter = new FilterDashboard();
    this.isFilterApplied = false;
    this.reloadItems();
  }

  resetFilterMap() {
    localStorage.removeItem('filterMap');
    this.dataSourceTables.data = this.dataSource.data;
    this.reportFilterToApply();
    this.isFilterMapApplied = false;
  }

  resetAllFilter() {
    localStorage.removeItem('filterDashboard');
    localStorage.removeItem('filterMap');
    this.filter = new FilterDashboard();
    this.isFilterApplied = false;
    this.isFilterMapApplied = false;
    this.reportFilterToApply();
    this.reloadItems();
  }


  // Al click della riga viene evidenziata o meno la riga stessa
  highlight(row) {
    if (this.selectedRowIndex == row.id) {
      this.selectedRowIndex = null;
    } else {
      this.selectedRowIndex = row.id;
    }
  }

  tableOnly() {
    this.isMapVisible = !this.isMapVisible;
  }

  mapOnly() {
    this.isTableVisible = !this.isTableVisible;
  }

  goToFillItem(itemId) {
    this.router.navigate[('/add-item/' + itemId)];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Rimuovo lo spazio bianco
    filterValue = filterValue.toLowerCase(); // Ricerca di default con caratteri minuscoli
    this.dataSourceTables.filter = filterValue;
    this.reportFilterToApply();
  }

  reloadItems(isFilterApplied?) {

    if (isFilterApplied == true) {
      localStorage.setItem('filterDashboard', JSON.stringify(this.filter))
      localStorage.removeItem('filterMap');
      this.isFilterMapApplied = false;
    } else if (localStorage.getItem('filterDashboard')) {
      this.filter = JSON.parse(localStorage.getItem('filterDashboard'));
      this.isFilterApplied = true;
    }

    this.itemService.getAllItem(this.filter).then((resItem) => {

      if (isFilterApplied)
        this.isFilterApplied = true;

      this.items = resItem;



      if (this.IsFirstCall) {
        // Setto come centro della mappa il primo elemento dell'array
        this.lat = this.items[0].lat;
        this.lng = this.items[0].lng;
        this.dataSource = new MatTableDataSource(resItem);
        this.createFilter()
        this.IsFirstCall = false;
      }

      if (this.items.length == 0) {
        this.noDataDashboard = true;
      } else {
        this.noDataDashboard = false;
      }

      this.dataSourceTables = new MatTableDataSource(this.items);
      this.dataSourceTables.paginator = this.paginator;
      this.dataSourceTables.sort = this.sort;
      this.dataSourceTables.filterPredicate = function (data, filter: string): boolean {
        return data.cod != null && data.cod.toLowerCase().includes(filter)
          || data.fk_company != null && data.fk_company.businessName.toLowerCase().includes(filter)
          || data.fk_highway != null && data.fk_highway.name.toLowerCase().includes(filter)
          || data.locationExit != null && data.locationExit.toLowerCase().includes(filter)
          || data.fk_exit != null && data.fk_exit.name.toLowerCase().includes(filter)
          || data.fk_itemType != null && data.fk_itemType.name.toLowerCase().includes(filter)
          || data.indication != null && data.indication.toLowerCase().includes(filter)
          || data.fk_itemLocation != null && data.fk_itemLocation.name.toLowerCase().includes(filter)
      };

      if (localStorage.getItem('filterMap')) {
        this.isFilterMapApplied = true;
        this.dataSourceTables.data = JSON.parse(localStorage.getItem('filterMap'));
      }

      this.reportFilterToApply();

      this.spinnerService.show();
      // Se la mappa è stata già precedetemente caricata e viene richiamato questo metodo, nascondo lo spinner
      if (this.pageAlreadyLoaded)
        this.spinnerService.hide()

    })
      .catch((err) => {
        DialogService.Error("Non è stato possibile recuperare gli item")
      })
  }


  createFilter() {
    this.dataSource.data.forEach(item => {
      if (item.fk_company != null && this.allCompany.find(c => c.id == item.fk_company.id) == null)
        this.allCompany.push(item.fk_company);

      if (item.fk_highway != null && this.allHighway.find(c => c.id == item.fk_highway.id) == null)
        this.allHighway.push(item.fk_highway);

      if (item.fk_exit != null && this.allHighwayExit.find(c => c.id == item.fk_exit.id) == null)
        this.allHighwayExit.push(item.fk_exit);

      if (item.fk_itemLocation != null && this.allItemLocation.find(c => c.id == item.fk_itemLocation.id) == null)
        this.allItemLocation.push(item.fk_itemLocation);

      if (item.fk_itemType != null && this.allItemType.find(c => c.id == item.fk_itemType.id) == null)
        this.allItemType.push(item.fk_itemType);

      if (item.fk_roadway != null && this.allRoadway.find(c => c.id == item.fk_roadway.id) == null)
        this.allRoadway.push(item.fk_roadway);

      if (item.locationExit != null && !(this.allPosition.find(c => c.indexOf(item.locationExit) > -1))) {
        this.allPosition.push(item.locationExit);
      }

      if (item.indication != null && !(this.allPosition.find(c => c.indexOf(item.indication) > -1))) {
        this.allIndication.push(item.indication);
      }
    });
  }

  // Inizio la Gestione dell'allegato all'interno della dashboard
  getItemAttachments(fk_item: number, isModal: boolean = true) {
    this.siasFileService.getAllSiasFileByItem(fk_item, this.attachmentType).then((resAllAttachments) => {
      this.attachments = resAllAttachments;
      if (isModal) {
        this.openModalAttachments(this.attachments);
      } else {
        this.dataSourceAttachments = new MatTableDataSource(resAllAttachments);
        // this.dataSourceAttachments.paginator = this.paginator;
        // this.dataSourceAttachments.sort = this.sort;
      }
    }).catch((error) => {
      DialogService.Error("Non è stato possibile recuperare gli allegati per questo item")
    })
  }

  callCsvService(files: FileList, modelName?: string) {
    let fileToUpload = files.item(0);
    this.csvService.checkImportCsv(fileToUpload, modelName);
  }

  getItemPreview(id: number) {
    this.previewItemImage = null
    this.spinnerService.hide();
    this.siasFileService.getAllSiasFileByItem(id, 3).then((res) => {
      this.spinnerService.hide();
      if (res.length == 0) {
        this.previewItemImage = 'assets/img/img.png';
      } else {
        this.previewItemImage = res;
        this.getFilePreview(this.previewItemImage[0].data);
      }
    })
    this.spinnerService.hide();
  }

  getFilePreview(base64) {
    this.previewItemImage = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64, ${base64}`);
  }

  openModalAttachments(attachments: Array<SiasFile>) {

    // Se l'array che mi arriva è vuoto noData diventa true così da mostrare nella tabella la voce che non sono presenti record
    if (attachments.length == 0)
      this.noData = true;

    this.dataSourceAttachments = new MatTableDataSource(attachments);
    // this.dataSourceAttachments.paginator = this.paginator;
    // this.dataSourceAttachments.sort = this.sort;

    let dialogRefAttachment = this.dialog.open(this.dialogAttachments, {
      width: '550px',
      autoFocus: false
    });

    dialogRefAttachment.afterClosed().subscribe(result => {
      this.noData = false;
    });
  }

  deleteAttachment(idFile: number) {
    DialogService.Confirm("Sei sicuro di voler eliminare l'allegato selezionato?", () => {
      this.siasFileService.deleteSiasFile(idFile).then(() => {
        this.getItemAttachments(this.currentItemSelected.id, false)
      });
    })
  }



  // Fine Gestione allegato all'interno della dashboard

  // Inizio Gestione Mappa
  clickedMarker(label: string, index: number, infowindow) {
    // console.log(`clicked the marker: ${label || index}`)
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  mapLoad($event) {
    this.pageAlreadyLoaded = true;
    this.spinnerService.hide();
  }

  // mapClicked($event: MouseEvent) {
  //   this.markers.push({
  //     lat: $event.coords.lat,
  //     lng: $event.coords.lng,
  //     draggable: true
  //   });
  // }

  // markerDragEnd(m: marker, $event: MouseEvent) {
  //   console.log('dragEnd', m, $event);
  // }


  // Fine gestione mappa



  //#region "Datalogs Item"

  restoreItem(log: DataLogVersion) {
    this.router.navigate(['add-item', log.fk_entity, log.id])
  }

  getLogsItem(item: Item) {
    this.item = item;
    this.modalLogsReady = true;
    this.openModalLogs();
  }

  openModalLogs() {

    this.dialog.open(this.dialogLogs, {
      width: '550px',
      autoFocus: false
    });

  }

  //#endregion "Datalogs Item"

  detailItem(idItem: number) {
    this.router.navigate(["item", idItem])
  }

  deleteItem(idItem: number, deleted: boolean) {
    DialogService.Confirm("Sei sicuro di voler eliminare l'item selezionato?", () => {
      this.itemService.deleteItem(idItem, deleted).then(() => {
        this.reloadItems();
      });
    })
  }

  selectedRow(idItem: number) {

    this.itemService.getItem(idItem).then((res) => {
      this.currentItemSelected = res;
    }).catch((err) => {
      DialogService.Error("Errore: " + err + "contattare l'amministratore del sistema")
    })

  }


  //Caricamento di allegati massivo
  onFileChange(event, type, labelName, itemId) {

    if (event.target.files && event.target.files.length) {


      for (var i = 0; i < event.target.files.length; i++) {
        var reader = new FileReader();
        reader.onload = function (readerEvent) {
        }
        reader.readAsDataURL(event.target.files[i]);

        var selectedFile = event.target.files[i];

        let fileProperties: any = { fileName: selectedFile.name, fileType: type, labelName: labelName }

        this.allFilesUploaded.push(selectedFile);

        this.customFileProperties.push(fileProperties);

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();

      }

      this.siasFileService.saveSiasFile(this.allFilesUploaded, this.customFileProperties, itemId).then(() => {
        this.getItemAttachments(this.currentItemSelected.id, false)
      }).catch((err) => {
        DialogService.Error("Non è stato possibile recuperare gli allegati");
      })

    }
  }


  downloadAttachment(id: number) {
    this.siasFileService.downloadSiasFile(id);
  }

  openFilterDialog() {
    this.dialog.open(this.dialogFilter, {
      width: '550px',
      autoFocus: false
    })
  }


  // Come da richiesta, filtro la mappa e la tabella a seconda del trascinamento e dello zoom che viene fatto alla mappa, così da fungere come ulteriore filtro
  checkMarkersInBounds(bounds) {
    this.currentBound = bounds;
  }

  filterMarkersInBounds() {

    this.dataSource.filteredData = [];

    for (let item of this.dataSource.data) {

      let itemPosition = { lat: item.lat, lng: item.lng };

      if (this.currentBound.contains(itemPosition)) {

        item.lat = itemPosition.lat;
        item.lng = itemPosition.lng;

        this.dataSource.filteredData.push(item);
        this.dataSourceTables.data = this.dataSource.filteredData;
        this.cd.detectChanges();

      }
    }

    this.reportFilterToApply();
    this.resetFilter();
    this.isFilterApplied = false;
    this.isFilterMapApplied = true;
    localStorage.setItem('filterMap', JSON.stringify(this.dataSourceTables.data))
    localStorage.removeItem('filterDashboard');

  }

  //Aggiorna i report presenti all'interno del componente con i filtri attuali
  reportFilterToApply() {
    this.totalItems = this.dataSourceTables.filteredData.length;
    this.notCompletedItems = this.dataSourceTables.filteredData.filter(x => x.cod == null).length;
    this.interventionPriority3 = this.dataSourceTables.filteredData.filter(x => x.intervention_priority == 3).length;
    this.interventionPriority2 = this.dataSourceTables.filteredData.filter(x => x.intervention_priority == 2).length;
    this.interventionPriority1 = this.dataSourceTables.filteredData.filter(x => x.intervention_priority == 1).length;
    this.investigationDone = this.dataSourceTables.filteredData.filter(x => x.State == 2).length;
    this.investigationNotDone = this.dataSourceTables.filteredData.filter(x => x.State == 1).length;
  }

}

