<!--GESTIONE IMPORT-->
<div class="card-header">Importazione dati</div>

<div class="row mt-3">
  <!--IMPORT CSV-->
  <div class="col-md-6">
    <label>Importa da CSV</label><br />
    <button *ngIf="showCsvButton == true" (click)="csv.click()" class="icon_custom" for="file-import-csv"
            matTooltip="Importa da CSV" mat-raised-button color="accent">
      <mat-icon>list</mat-icon>
      <span>CSV</span>
      <input #csv (change)="onFileCsvChange($event)" id="file-import-csv" type="file" />&nbsp;
    </button>
  </div>

  <!--IMPORT KMZ-->
  <div class="col-md-6">
    <label>Importa da KMZ</label><br />
    <button *ngIf="showKmzButton == true" (click)="kmz.click()" class="icon_custom" for="file-import-kmz"
            matTooltip="Importa da KMZ" mat-raised-button color="accent">
      <mat-icon>room</mat-icon>
      <span>KMZ</span>
      <input #kmz (change)="onFileKmzChange($event)" id="file-import-kmz" type="file" />&nbsp;
    </button>
  </div>
</div>

<!--CHIUDI MODALE-->
<mat-dialog-actions class="float-right">
  <button class="btn btn-primary btn-orange mt-4" matDialogClose mat-raised-button style="min-width: 40px !important; padding:0px;">
    <mat-icon>close</mat-icon>
  </button>
</mat-dialog-actions>


<!-- Con le icone -->
<!-- <label *ngIf="showCsvButton == true" class="icon_custom" for="file-import-csv" matTooltip="Importa da CSV">
    Importa CSV<i class="material-icons icon_custom">list</i>
</label>
<input *ngIf="showCsvButton == true" (change)="onFileCsvChange($event)" id="file-import-csv" type="file" />&nbsp; -->
<!--
<label *ngIf="showKmzButton == true" class="icon_custom" for="file-import-kmz" matTooltip="Importa da KMZ">
    <i class="material-icons icon_custom">room</i>
</label>
<input *ngIf="showKmzButton == true" (change)="onFileKmzChange($event)" id="file-import-kmz" type="file" />&nbsp; -->
