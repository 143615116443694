<!-- PROFILO UTENTE E AMMINISTRAZIONE -->
<div class="brand-card">
  <div class="card-header">Impostazioni</div>
  <div class="brand-card-body">
    <mat-tab-group class="larghezzaUserProfile">
      <!--TAB - PROFILO UTENTE-->
      <mat-tab label="Profilo Utente">

        <div class="brand-card-body">
          <div class="container-fluid">
            <form [formGroup]="userProfileForm">
              <div class="row">

                <!--NOME-->
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field class="full-width">
                    <mat-label>Nome</mat-label>
                    <input type="text" matInput autocomplete='user.name' formControlName="name" [readonly]="true">
                  </mat-form-field>
                </div>

                <!--COGNOME-->
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field class="full-width">
                    <mat-label>Cognome</mat-label>
                    <input type="text" matInput autocomplete='user.surname' formControlName="surname" [readonly]="true">
                  </mat-form-field>
                </div>

                <!--EMAIL-->
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field class="full-width">
                    <mat-label>E-mail</mat-label>
                    <input type="text" matInput autocomplete='user.email' formControlName="email" [readonly]="true">
                  </mat-form-field>
                </div>

                <!--TELEFONO-->
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field class="full-width">
                    <mat-label>Telefono</mat-label>
                    <input type="number" matInput autocomplete='user.phone' formControlName="phone" [readonly]="true">
                  </mat-form-field>
                </div>

                <!--RUOLO-->
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field class="full-width">
                    <mat-select placeholder="Ruolo">
                      <mat-option [value]="role.value" *ngFor="let role of roles">
                        {{role.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!--AZIENDA-->
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field class="full-width">
                    <mat-label>Azienda</mat-label>
                    <input type="text" matInput autocomplete='user.fk_company' formControlName="fk_company" [readonly]="true">
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 text-right">

                  <!--INDIETRO-->
                  <button matTooltip="INDIETRO" class="btn btn-primary btn-orange" [routerLink]="['/dashboard']" routerLinkActive="router-link-active">
                    <i class="icon-arrow-left"></i>
                  </button>

                  <!--MODIFICA CLIENTE-->
                  <button matTooltip="MODIFICA" class="btn btn-primary btn-orange" (click)="modificaUserProfile()">
                    <i class="icon-pencil"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </mat-tab>

      <!--TAB - AMMINISTRAZIONE-->
      <mat-tab label="Amministrazione">
        <mat-accordion>

          <!--CLIENTI-->
          <div class="container-fluid">
            <mat-expansion-panel class="mt-3">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Clienti
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <!--NUOVO CLIENTE-->
                <div class="col-md-6 mt-3">
                  <button class="btn btn-primary btn-orange" style="width:100%">
                    <i class="cui-user"></i> NUOVO CLIENTE
                  </button>
                </div>
                <!--GESTISCI CLIENTI-->
                <div class="col-md-6 mt-3">
                  <button class="btn btn-primary btn-orange" style="width:100%">
                    <i class="cui-pencil"></i> GESTISCI CLIENTI
                  </button>
                </div>
              </div>
            </mat-expansion-panel>
          </div>

          <!--REGISTRA CLIENTE-->
          <div class="container-fluid">
            <mat-expansion-panel class="mt-3 mb-5">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Registra Cliente
                </mat-panel-title>
              </mat-expansion-panel-header>

              <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                <div class="row">

                  <!--NOME-->
                  <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <mat-form-field class="full-width">
                      <mat-label>Nome</mat-label>
                      <input type="text" matInput formControlName="name">
                    </mat-form-field>
                  </div>

                  <!--COGNOME-->
                  <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <mat-form-field class="full-width">
                      <mat-label>Cognome</mat-label>
                      <input type="text" matInput formControlName="surname">
                    </mat-form-field>
                  </div>

                  <!--EMAIL-->
                  <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <mat-form-field class="full-width">
                      <mat-label>E-mail</mat-label>
                      <input type="text" matInput formControlName="email">
                    </mat-form-field>
                  </div>

                  <!--TELEFONO-->
                  <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <mat-form-field class="full-width">
                      <mat-label>Telefono</mat-label>
                      <input type="number" matInput formControlName="phone">
                    </mat-form-field>
                  </div>

                  <!--RUOLO-->
                  <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <mat-form-field class="full-width">
                      <mat-select placeholder="Ruolo" formControlName="role">
                        <mat-option [value]="role.value" *ngFor="let role of roles">
                          {{role.viewValue}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <!--AZIENDA-->
                  <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <mat-form-field class="full-width">
                      <mat-label>Azienda</mat-label>
                      <input type="text" matInput>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-12 text-right">

                    <!--RESET-->
                    <button matTooltip="RESET" (click)="resetForm()" type="button" class="btn btn-primary btn-orange">
                      <i class="icon-refresh"></i>
                    </button>

                    <!--REGISTRA-->
                    <button matTooltip="REGISTRA" type="submit" class="btn btn-primary btn-orange">
                      <i class="icon-user-follow"></i>
                    </button>
                  </div>
                </div>
              </form>
            </mat-expansion-panel>
          </div>
        </mat-accordion>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<!--MODALE MODIFICA CLIENTE-->
<ng-template #dialogModificaUserPRofile let-data>
  <div class="card-header">Modifica Cliente</div>
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="userEditProfileForm">

        <div class="container-fluid">
          <!--NOME-->
          <mat-form-field class="full-width">
            <mat-label>Nome</mat-label>
            <input type="text" matInput [(ngModel)]="data.name" formControlName="name">
          </mat-form-field>

          <!--COGNOME-->
          <mat-form-field class="full-width">
            <mat-label>Cognome</mat-label>
            <input type="text" matInput [(ngModel)]="data.surname" formControlName="surname">
          </mat-form-field>

          <!--EMAIL-->
          <mat-form-field class="full-width">
            <mat-label>E-mail</mat-label>
            <input type="text" matInput [(ngModel)]="data.email" formControlName="email" [readonly]="true">
          </mat-form-field>

          <!--TELEFONO-->
          <mat-form-field class="full-width">
            <mat-label>Telefono</mat-label>
            <input type="number" matInput [(ngModel)]="data.phone" formControlName="phone">
          </mat-form-field>

          <!--RUOLO-->
          <mat-form-field class="full-width">
            <mat-select placeholder="Ruolo">
              <mat-option [value]="role.value" *ngFor="let role of roles">
                {{role.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!--AZIENDA-->
          <mat-form-field class="full-width">
            <mat-label>Azienda</mat-label>
            <input type="text" matInput [(ngModel)]="data.fk_company" formControlName="fk_company">
          </mat-form-field>
        </div>

        <mat-dialog-actions class="float-right">

          <!--CHIUDI-->
          <button matTooltip="INDIETRO" class="btn btn-primary btn-orange" matDialogClose mat-raised-button style="min-width: 40px !important; padding:0px;">
            <mat-icon>close</mat-icon>
          </button>

          <!--SALVA-->
          <button matTooltip="SALVA" class="btn btn-primary btn-orange" [disabled]="userProfileForm.invalid"
                  (click)="updateUser(data)" mat-raised-button style="min-width: 40px !important; padding:0px;">
            <mat-icon>check</mat-icon>
          </button>
        </mat-dialog-actions>
      </form>
    </div>
  </div>
</ng-template>
