import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemService } from '../../services/item.service';
import { Item } from '../../models/Item';
import { DataLogVersion } from '../../models/dataLogVersion';
import { SiasFile } from '../../models/siasFile';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SiasFileService } from '../../services/siasFile.service';
import { DialogService } from '../../services/dialog.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {


  // Sezione riguardante gli allegati
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumnsAttachments = ['Data', 'Nome File', 'Actions'];
  dataSourceAttachments: MatTableDataSource<SiasFile>;

  displayedColumnsPlate = ['Data', 'Nome File', 'Actions'];
  dataSourcePlates: MatTableDataSource<SiasFile>;

  item: Item = new Item;

  itemImage: SafeResourceUrl;

  addItemForm: FormGroup;

  zoom: number;

  logs: Array<DataLogVersion>;

  attachmentType: number = 1;
  plateType: number = 2;
  imageItemType: number = 3;

  noAttachments: boolean = false;
  noPlates: boolean = false;

  isDataAvailable: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private itemService: ItemService, private siasFileService: SiasFileService, private sanitizer: DomSanitizer, private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id) {

      // Solo dopo aver recuperato TUTTI i dati dell'ITEM procedo con il recuperarmi (qualora ci siano) gli allegati e le targhe
      this.itemService.getItem(this.activatedRoute.snapshot.params.id).then((res) => {
        this.item = res;
        this.filePreview();

        // Recupero gli allegati se presenti
        this.siasFileService.getAllSiasFileByItem(this.item.id, this.attachmentType, false).then((resAllAttachments) => {
          if (resAllAttachments.length > 0) {
            this.dataSourceAttachments = new MatTableDataSource(resAllAttachments);
            this.dataSourceAttachments.paginator = this.paginator;
            this.dataSourceAttachments.sort = this.sort;
          } else {
            this.noAttachments = true;
          }
        }).catch(() => {
          DialogService.Error("Non è stato possibile recuperare gli allegati per questo item")
        })

        // Recupero le targhe se presenti
        this.siasFileService.getAllSiasFileByItem(this.item.id, this.plateType, false).then((resAllPlates) => {
          if (resAllPlates.length > 0) {
            this.dataSourcePlates = new MatTableDataSource(resAllPlates);
            this.dataSourcePlates.paginator = this.paginator;
            this.dataSourcePlates.sort = this.sort;
          } else {
            this.noPlates = true;
          }
        }).catch(() => {
          DialogService.Error("Non è stato possibile recuperare le targhe per questo item")
        })

        this.isDataAvailable = true;
      })

      this.zoom = 15;

    }
  }

  restoreItem(log: DataLogVersion) {
    this.router.navigate(['add-item', log.fk_entity, log.id])
  }

  downloadAttachment(id: number) {
    this.siasFileService.downloadSiasFile(id);
  }

  goToEdit(id: number) {
    this.router.navigate(["../../add-item/", id], { relativeTo: this.activatedRoute });
  }

  goBack() {
    this.router.navigate(['/dashboard']);
  }

  filePreview() {
    //Recupero l'immagine dell'item passando come parametro il tipo 3 (immagine identificativa item)
    this.siasFileService.getAllSiasFileByItem(this.item.id, this.imageItemType, true).then((resItemImage) => {
      if (resItemImage.length > 0) {
        this.siasFileService.previewSiasFile(resItemImage[0].id).then((res) => {
          this.itemImage = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64, ${res}`);
        })
      }
    }).catch(() => {
      DialogService.Error("Non è stato possibile recuperare l'immagine identificativa dell'item")
    })
  }

  goToMaintenance(id) {
    window.open("http://sias.eqproject.it/#/home/" + id, "_blank");
  }

}
