<!--FILTRI-->
<div class="row">
  <!--PULSANTI-->
  <div class="col-md-6 col-sm-12 mb-3">

    <!--FILTRO-->
    <button matTooltip="CERCA" class="btn btn-primary btn-orange mr-1" (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseSearch" [ngClass]="{'filtroSi': isCollapsed}">
      <i class="icon-magnifier"></i>
    </button>

    <!--FILTRO AVANZATO-->
    <button matTooltip="FILTRO AVANZATO" class="btn btn-primary btn-orange mr-1" (click)="openFilterDialog()"
      [ngClass]="{'filtroSi': isFilterApplied}">
      <i class="icon-magnifier-add"></i>
    </button>
  </div>

  <!--CERCA-->
  <div class="col-md-6 col-sm-12 mb-3" [ngbCollapse]="!isCollapsed">
    <div id="collapseSearch" class="filtroCerca">
      <mat-form-field floatLabel="never">
        <mat-label>Cerca</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)">
      </mat-form-field>
    </div>
  </div>
</div>

<!--TABELLA RIEPILOGO INDAGINE-->
<div class="card">
  <div class="card-header">
    Riepilogo Indagine
  </div>
  <div class="card-body">
    <!--TABELLA-->
    <div class="mat-table__wrapper">
      <div class="mat-elevation-z8">
        <mat-table [dataSource]="datasourceSummary" matSort>

          <!-- FDL PIASTRA -->
          <ng-container matColumnDef="PiastraFDL">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="bordoGrigioChiaro">
              Piastra FDL
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="bordoGrigioChiaro">
              {{row?.local_degradation_piastra}}
            </mat-cell>
          </ng-container>

          <!-- FDL RITTO -->
          <ng-container matColumnDef="RittoFDL">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="bordoGrigioChiaro">
              Ritto FDL
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="bordoGrigioChiaro">
              {{row?.local_degradation_ritto}}
            </mat-cell>
          </ng-container>

          <!-- FDL TRAVERSO -->
          <ng-container matColumnDef="TraversoFDL">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="bordoGrigioChiaro">
              Traverso FDL
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="bordoGrigioChiaro">
              {{row?.local_degradation_traverso}}
            </mat-cell>
          </ng-container>

          <!-- FDL PASTORALE -->
          <ng-container matColumnDef="PastoraleFDL">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="bordoGrigioChiaro">
              Pastorale FDL
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="bordoGrigioChiaro">
              {{row?.local_degradation_pastorale}}
            </mat-cell>
          </ng-container>

          <!-- PRIORITA' -->
          <ng-container matColumnDef="Priorita">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Priorità
            </mat-header-cell>
            <mat-cell class="centerColumn" *matCellDef="let row">
              <i *ngIf="row.intervention_priority != 0" style="font-size: 10pt;" class="fa fa-circle"
                [ngStyle]="{'color': row.intervention_priority == 1 ? 'red' : row.intervention_priority == 2 ? 'yellow' : 'green'}"></i>
            </mat-cell>
          </ng-container>

          <!-- FDG -->
          <ng-container matColumnDef="FDG">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="bordoGrigioChiaro">
              FDG
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="bordoGrigioChiaro">
              {{row?.global_degradation}}
            </mat-cell>
          </ng-container>

          <!-- ITEM -->
          <ng-container matColumnDef="CodItem">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="bordoGrigioChiaro">
              Item
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="bordoGrigioChiaro"> {{row?.item.cod}} </mat-cell>
          </ng-container>

          <!-- AZIONI -->
          <ng-container matColumnDef="Actions">
            <mat-header-cell style="flex-direction:unset!important" *matHeaderCellDef mat-sort-header
              class="bordoGrigioChiaro allineamentoDestra">
              Azioni
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" class="bordoGrigioChiaro allineamentoDestra">
              <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="['../item/' + row.item.id + '/indagine']">
                  Dettaglio
                  Indagine
                </button>
                <!-- Popup con dettaglio item -->
                <button mat-menu-item [routerLink]="">Dettaglio Item</button>
              </mat-menu>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsSummary; sticky:true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsSummary;"
            [ngClass]="{'highlight': selectedRowIndex == row.item.id}" (click)="highlight(row)">
          </mat-row>
        </mat-table>
      </div>
    </div>

    <!--FOOTER TABELLA-->
    <div class="row mt-3 mb-3">
      <!--TOTALE ITEM - NON CENSITI-->
      <div class="col-sm-6 col-xs-12">
        <p class="item-data">
          <span>Totale Indagini: </span><span style="margin-right:10px;"
            class="item-number">{{totalInvestigations}}</span>
          <span>Non validate: </span><span class="item-number">{{notCompletedItems}}</span>
          <i class="fa fa-exclamation-triangle" style="color: red;font-size: 10pt;"></i>
        </p>
      </div>
      <!--PAGINAZIONE-->
      <div class="col-sm-6 col-xs-12">
        <mat-paginator id="oneOf" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>

    <hr />

    <!--LEGENDA-->
    <div class="row">
      <div class="col-sm-12 text-left">
        <div class='my-legend'>
          <div class='legend-title'>PRIORITA'</div>
          <div class='legend-scale'>
            <ul class='legend-labels'>
              <li><span style='background:red;'></span>Intervento tempestivo: {{interventionPriority1}}</li>
              <li><span style='background:yellow;'></span>Intervento urgente: {{interventionPriority2}}</li>
              <li><span style='background:green'></span>Intervento ordinario: {{interventionPriority3}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--MODALE FILTRO AVANZATO-->
<ng-template #dialogFilter>
  <div class="card">
    <div class="card-header">Filtro Avanzato</div>
    <div class="card-body">

      <div class="row">

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [searchable]="false" [items]="validateStatus" bindLabel="Value" bindValue="Key"
            placeholder="Validazione" clearAllText="Rimuovi" [(ngModel)]="filter.IsValidated">
          </ng-select>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allCompany" placeholder="Cliente" bindLabel="businessName" [(ngModel)]="filter.Cliente">
          </ng-select>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allHighway" placeholder="Strada" bindLabel="name" [(ngModel)]="filter.Strada">
          </ng-select>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allItemLocation" placeholder="Ubicazione" bindLabel="name"
            [(ngModel)]="filter.Ubicazione">
          </ng-select>
        </div>

        <!-- COMP ESTERNA -->
        <div *ngIf="filter.Ubicazione?.id == 2" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allHighwayExit" placeholder="Uscita" bindLabel="name" [(ngModel)]="filter.Uscita">
          </ng-select>
        </div>

        <div *ngIf="filter.Ubicazione?.id == 1" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allRoadway" placeholder="Carreggiata" bindLabel="name" [(ngModel)]="filter.Carreggiata">
          </ng-select>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allItemType" placeholder="Tipologia" bindLabel="name" [(ngModel)]="filter.Tipologia">
          </ng-select>
        </div>

        <div *ngIf="filter.Ubicazione?.id == 1" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allPosition" placeholder="Posizione" bindLabel="name" [(ngModel)]="filter.Posizione">
          </ng-select>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allIndication" placeholder="Indicazione" bindLabel="name"
            [(ngModel)]="filter.Indicazione">
          </ng-select>
        </div>

        <div class=" col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [searchable]="false" [items]="itemPriority" bindLabel="Value" bindValue="Key"
            placeholder="Priorità" clearAllText="Rimuovi" [(ngModel)]="filter.Priorita">
            <ng-template ng-label-tmp let-item="item">
              <img height="15" width="15" [src]="item.Avatar" />
              {{item.Value}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <img height="15" width="15" [src]="item.Avatar" />
              {{item.Value}}
            </ng-template>
          </ng-select>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [searchable]="false" [items]="itemPmv" bindLabel="Value" bindValue="Key" placeholder="Pmv"
            clearAllText="Rimuovi" [(ngModel)]="filter.Pmv">
            <ng-template ng-label-tmp let-item="item">
              <img height="10" width="20" [src]="item.Avatar" />
              {{item.Value}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <img height="10" width="20" [src]="item.Avatar" />
              {{item.Value}}
            </ng-template>
          </ng-select>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [searchable]="false" [items]="itemCompleted" bindLabel="Value" bindValue="Key" placeholder="Stato"
            clearAllText="Rimuovi" [(ngModel)]="filter.IsCompleted">
          </ng-select>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [searchable]="false" [items]="itemDeleted" bindLabel="Value" bindValue="Key"
            placeholder="Eliminato" clearAllText="Rimuovi" [(ngModel)]="filter.isDeleted">
          </ng-select>
        </div>
      </div>

      <!--PULSANTI-->
      <mat-dialog-actions>
        <!--APPLICA-->
        <button mat-dialog-close (click)="reloadAllSummary(true)" matTooltip="CERCA"
          class="btn btn-primary btn-orange float-right">
          <i class="icon-magnifier"></i>
        </button>

        <!--RESET-->
        <button mat-dialog-close (click)="resetFilter()" matTooltip="RESET"
          class="btn btn-primary btn-orange mr-1 float-right">
          <i class="icon-refresh"></i>
        </button>
      </mat-dialog-actions>
    </div>
  </div>

</ng-template>