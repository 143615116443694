import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { InvestigationService } from '../../../services/investigation.service';
import { Router } from '@angular/router';
import { SummaryInvestigation } from '../../../models/summaryInvestigation.model';
import { SummaryFilter } from '../../../models/summary-filter';
import { Company } from '../../../models/company';
import { Highway } from '../../../models/highway';
import { ItemLocation } from '../../../models/itemLocation';
import { ItemType } from '../../../models/itemType';
import { Exit } from '../../../models/Exit';
import { Roadway } from '../../../models/roadway';
import { Item } from '../../../models/Item';

@Component({
  selector: 'app-list-indagine',
  templateUrl: './list-indagine.component.html',
  styleUrls: ['./list-indagine.component.scss']
})
export class ListIndagineComponent implements OnInit {

  displayedColumnsSummary = ['CodItem', 'PiastraFDL', 'RittoFDL', 'TraversoFDL', 'PastoraleFDL', 'Priorita', 'FDG', 'Actions'];

  datasourceSummary: MatTableDataSource<SummaryInvestigation>;
  item: Array<Item>;

  public isCollapsed = false;

  isFilterApplied: boolean;

  IsFirstCall: boolean = true;


  // Variabili per la gestione dei report
  totalInvestigations: number;
  notCompletedItems: number;
  interventionPriority3: number;
  interventionPriority2: number;
  interventionPriority1: number;


  @ViewChild("dialogFilter", { static: false }) dialogFilter: TemplateRef<any>;
  filter: SummaryFilter = new SummaryFilter();
  allCompany: Array<Company> = new Array<Company>();
  allHighway: Array<Highway> = new Array<Highway>();
  allItemLocation: Array<ItemLocation> = new Array<ItemLocation>();
  allHighwayExit: Array<Exit> = new Array<Exit>();
  allItemType: Array<ItemType> = new Array<ItemType>();
  allRoadway: Array<Roadway> = new Array<Roadway>();
  allPosition: Array<string> = new Array<string>();
  allIndication: Array<string> = new Array<string>();

  itemPriority: any[] = [
    { Key: -1, Value: "Tutti" },
    { Key: 1, Value: "Intervento tempestivo", Avatar: "../../../assets/img/red_circle.png" },
    { Key: 2, Value: "Intervento urgente", Avatar: "../../../assets/img/yellow_circle.png" },
    { Key: 3, Value: "Intervento ordinario", Avatar: "../../../assets/img/green_circle.png" }
  ];

  itemPmv: any[] = [
    { Key: -1, Value: "Tutti" },
    { Key: true, Value: "Presente", Avatar: "../../../assets/img/check.png" },
    { Key: false, Value: "Non presente" },
  ];

  itemCompleted: any[] = [
    { Key: -1, Value: "Tutti" },
    { Key: true, Value: "Censito" },
    { Key: false, Value: "Non censito" },
  ];

  validateStatus: any[] = [
    { Key: -1, Value: "Tutti" },
    { Key: true, Value: "Validato" },
    { Key: false, Value: "Non validato" }
  ];

  itemDeleted: any[] = [
    { Key: -1, Value: "Tutti" },
    { Key: true, Value: "Eliminato" },
    { Key: false, Value: "Non eliminato" },
  ];

  selectedRowIndex: number = -1;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private investigationService: InvestigationService,
    private router: Router
  ) {
    this.filter = new SummaryFilter();
    this.filter.IsValidated = null;
    this.filter.Carreggiata = null;
    this.filter.Cliente = null;
    this.filter.IsCompleted = null;
    this.filter.Indicazione = null;
    this.filter.Pmv = null;
    this.filter.Posizione = null;
    this.filter.Priorita = -1;
    this.filter.Stato = -1;
    this.filter.Strada = null;
    this.filter.Tipologia = null;
    this.filter.Ubicazione = null;
    this.filter.Uscita = null;
    this.filter.isDeleted = null;
  }

  ngOnInit(): void {
    this.filter = new SummaryFilter();
    this.item = new Array<Item>();
    this.filter.IsValidated = null;
    this.isFilterApplied = false;

    this.reloadAllSummary();
  }


  resetFilter() {
    localStorage.removeItem('filterIndagine');
    this.filter = new SummaryFilter();
    this.isFilterApplied = false;
    this.reloadAllSummary();
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Rimuovo lo spazio bianco
    filterValue = filterValue.toLowerCase(); // Ricerca di default con caratteri minuscoli
    this.datasourceSummary.filter = filterValue;
  }

  reloadAllSummary(isFilterApplied?) {

    if (isFilterApplied == true)
      localStorage.setItem('filterIndagine', JSON.stringify(this.filter))
    else if (localStorage.getItem('filterIndagine')) {
      this.filter = JSON.parse(localStorage.getItem('filterIndagine'));
      this.isFilterApplied = true;
    }

    this.investigationService.getSummaryInvestigation(null, this.filter).then((resAllSummary) => {

      if (isFilterApplied)
        this.isFilterApplied = true;

      this.totalInvestigations = resAllSummary.length;
      this.notCompletedItems = resAllSummary.filter(x => x.isValid == false).length;
      this.interventionPriority3 = resAllSummary.filter(x => x.intervention_priority == 3).length;
      this.interventionPriority2 = resAllSummary.filter(x => x.intervention_priority == 2).length;
      this.interventionPriority1 = resAllSummary.filter(x => x.intervention_priority == 1).length;

      this.datasourceSummary = new MatTableDataSource(resAllSummary);
      this.datasourceSummary.paginator = this.paginator;
      this.datasourceSummary.sort = this.sort;

      if (this.IsFirstCall) {
        this.createFilter();
        this.IsFirstCall = false;
      }

    })
  }

  // Al click della riga viene evidenziata o meno la riga stessa
  highlight(row) {
    if (this.selectedRowIndex == row.item.id) {
      this.selectedRowIndex = null;
    } else {
      this.selectedRowIndex = row.item.id;
    }
  }

  openFilterDialog() {
    this.dialog.open(this.dialogFilter, {
      width: '550px',
      autoFocus: false
    })
  }


  createFilter() {
    this.datasourceSummary.data.forEach(summary => {
      if (summary.item["fk_company"] != null && this.allCompany.find(c => c.id == summary.item["fk_company"].id) == null) {
        this.allCompany.push(summary.item["fk_company"]);
      }

      if (summary.item["fk_highway"] != null && this.allHighway.find(c => c.id == summary.item["fk_highway"].id) == null)
        this.allHighway.push(summary.item["fk_highway"]);

      if (summary.item["fk_exit"] != null && this.allHighwayExit.find(c => c.id == summary.item["fk_exit"].id) == null)
        this.allHighwayExit.push(summary["itemfk_exit"]);

      if (summary.item["fk_itemLocation"] != null && this.allItemLocation.find(c => c.id == summary.item["fk_itemLocation"].id) == null)
        this.allItemLocation.push(summary.item["fk_itemLocation"]);

      if (summary.item["fk_itemType"] != null && this.allItemType.find(c => c.id == summary.item["fk_itemType"].id) == null)
        this.allItemType.push(summary.item["fk_itemType"]);

      if (summary.item["fk_roadway"] != null && this.allRoadway.find(c => c.id == summary.item["fk_roadway"].id) == null)
        this.allRoadway.push(summary.item["fk_roadway"]);

      if (summary.item["locationExit"] != null && !(this.allPosition.find(c => c.indexOf(summary.item["locationExit"]) > -1))) {
        this.allPosition.push(summary.item["locationExit"]);
      }

      if (summary.item["indication"] != null && !(this.allPosition.find(c => c.indexOf(summary.item["indication"]) > -1))) {
        this.allIndication.push(summary.item["indication"]);
      }
    });




  }
}
