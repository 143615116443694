<div *ngIf="isDataAvailable" class="brand-card">
  <div class="card-header"> DETTAGLIO ITEM {{item.cod}}</div>
  <div class="brand-card-body">
    <mat-tab-group>
      <!--DETTAGLIO-->
      <mat-tab label="DETTAGLIO">
        <div class="brand-card-body">
          <div class="container-fluid">
            <form>
              <div class="row">

                <!--COL DESTRA-->
                <div class="col-md-6 col-sm-12">
                  <agm-map [zoom]='zoom' [latitude]="item.lat" [longitude]="item.lng" [fullscreenControl]='true'
                    [mapTypeControl]='true'>
                    <agm-marker [latitude]="item.lat" [longitude]="item.lng" [markerDraggable]="false">
                    </agm-marker>
                  </agm-map>
                  <!--IMMAGINE-->
                  <!-- <div class="box">
                  <img *ngIf="itemImage" [src]="itemImage" alt="Immagine Item">
                </div> -->
                </div>


                <!--COL SINISTRA-->
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="row">

                    <!--CODICE-->
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                      <mat-form-field class="full-width">
                        <mat-label>Codice</mat-label>
                        <input matInput [(ngModel)]="item.cod" name="cod" id="cod" type="text" placeholder="Codice"
                          disabled>
                      </mat-form-field>
                    </div>

                    <!--CLIENTE-->
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                      <mat-form-field class="full-width">
                        <mat-label>Cliente</mat-label>
                        <input *ngIf="item.fk_company" [(ngModel)]="item.fk_company.businessName" name="fk_company"
                          id="fk_company" matInput type="text" placeholder="Cliente" disabled>
                      </mat-form-field>
                    </div>

                    <!--STRADA-->
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                      <mat-form-field class="full-width">
                        <mat-label>Strada</mat-label>
                        <input [(ngModel)]="item.fk_highway.name" name="fk_highway" id="fk_highway" matInput type="text"
                          placeholder="Strada" disabled>
                      </mat-form-field>
                    </div>

                    <!--UBICAZIONE-->
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                      <mat-form-field class="full-width">
                        <mat-label>Ubicazione</mat-label>
                        <input [(ngModel)]="item.fk_itemLocation.name" name="fk_itemLocation" id="fk_itemLocation"
                          matInput type="text" placeholder="Ubicazione" disabled>
                      </mat-form-field>
                    </div>

                    <!--POSIZIONE-->
                    <div *ngIf="item.locationExit" class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                      <mat-form-field class="full-width">
                        <mat-label>Posizione</mat-label>
                        <input type="text" matInput [(ngModel)]="item.locationExit" name="locationExit"
                          id="locationExit" disabled>
                      </mat-form-field>
                    </div>

                    <!--USCITA-->
                    <div *ngIf="item.fk_exit" class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                      <mat-form-field class="full-width">
                        <mat-label>Uscita</mat-label>
                        <input [(ngModel)]="item.fk_exit.name" name="fk_exit" id="fk_exit" matInput type="text"
                          placeholder="Uscita" disabled>
                      </mat-form-field>
                    </div>

                    <!--CARREGGIATA-->
                    <div *ngIf="item.fk_roadway" class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                      <mat-form-field class="full-width">
                        <mat-label>Carreggiata</mat-label>
                        <input type="text" matInput [(ngModel)]="item.fk_roadway.name" name="roadway" id="roadway"
                          placeholder="Carreggiata" disabled>
                      </mat-form-field>
                    </div>

                    <!--TIPOLOGIA-->
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                      <mat-form-field class="full-width">
                        <mat-label>Tipologia</mat-label>
                        <input [(ngModel)]="item.fk_itemType.name" name="fk_itemType" id="fk_itemType" matInput
                          type="text" placeholder="Tipologia" disabled>
                      </mat-form-field>
                    </div>

                    <!--INDICAZIONE-->
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                      <mat-form-field class="full-width">
                        <mat-label>Indicazione</mat-label>
                        <input type="text" matInput [(ngModel)]="item.indication" name="indication" id="indication"
                          disabled>
                      </mat-form-field>
                    </div>


                    <!--NOTE-->
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                      <mat-form-field class="full-width">
                        <mat-label>Note</mat-label>
                        <input type="text" matInput [(ngModel)]="item.note" name="note" id="note" disabled>
                      </mat-form-field>
                    </div>

                    <!--PMV-->
                    <div class="col-md-12 col-sm-12 mt-4">
                      <mat-checkbox [(ngModel)]="item.preparation" name="preparation" id="preparation" disabled>
                        PMV
                      </mat-checkbox>
                    </div>

                    <div class="col-md-12 col-sm-12">
                      <div class="box">
                        <img [src]="itemImage ? itemImage : 'assets/img/img.png'" alt="Immagine Item">
                      </div>
                    </div>

                  </div>
                </div>


              </div>

              <!--PULSANTI-->
              <div class="row">
                <div class="col-md-6 col-sm-12 text-center">

                  <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-12 column-margin">

                      <button matTooltip="Indietro" (click)="goBack()" class="btn btn-primary btn-orange mr-1"
                        type="button">
                        <i class="cui-chevron-left"></i>
                      </button>

                      <button matTooltip="Modifica" (click)="goToEdit(item.id)" class="btn btn-primary btn-orange mr-1"
                        type="button">
                        <i class="cui-note"></i>
                      </button>

                    </div>

                    <div class="col-lg-7 col-md-7 col-sm-12">
                      <button (click)="goToMaintenance(item.id)" matTooltip="Manutenzione"
                        class="btn btn-primary btn-orange mr-1" type="button">
                        <i class="cui-wrench"></i>
                      </button>

                      <button matTooltip="Indagine" [routerLink]="['indagine']" class="btn btn-primary btn-orange mr-1"
                        type="button">
                        <i class="cui-layers"></i>
                      </button>

                      <button matTooltip="Attività" class="btn btn-primary btn-orange" type="button">
                        <i class="cui-pie-chart"></i>
                      </button>
                    </div>
                  </div>


                </div>
              </div>
            </form>
          </div>
        </div>
      </mat-tab>

      <!--ALLEGATI-->
      <mat-tab label="ALLEGATI">
        <div class="brand-card-body">
          <div class="container-fluid">
            <div class="card">

              <!--TITOLO-->
              <div class="card-header">
                Allegati
              </div>
              <div class="card-body">
                <div class="mat-table__wrapper">
                  <div class="example-container mat-row">

                    <!--TABELLA-->
                    <mat-table [dataSource]="dataSourceAttachments" matSort>
                      <!--DATA-->
                      <ng-container matColumnDef="Data">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                          Data
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.creationData}} </mat-cell>
                      </ng-container>
                      <!--NOME-->
                      <ng-container matColumnDef="Nome File">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                          Nome
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                          {{row.name}}
                        </mat-cell>
                      </ng-container>
                      <!--AZIONI-->
                      <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'actionWidth'">
                          Azioni
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row; let i = index" [ngClass]="'actionWidth'">
                          <button mat-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <button (click)="downloadAttachment(row.id)" mat-menu-item>Scarica</button>
                            <button mat-menu-item>Elimina</button>
                          </mat-menu>
                        </mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="displayedColumnsAttachments"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumnsAttachments;">
                      </mat-row>
                    </mat-table>
                    <div *ngIf="noAttachments" class="no-records">
                      Nessun allegato presente
                    </div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <!--TARGHE-->
      <mat-tab label="TARGHE">
        <div class="brand-card-body">
          <div class="container-fluid">
            <div class="card">
              <!--TITOLO-->
              <div class="card-header">
                Targhe
              </div>
              <div class="card-body">
                <div class="mat-table__wrapper">
                  <div class="example-container mat-row">

                    <!--TABELLA-->
                    <mat-table [dataSource]="dataSourcePlates" matSort>
                      <!--DATA-->
                      <ng-container matColumnDef="Data">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                          Data
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.creationData}} </mat-cell>
                      </ng-container>
                      <!--NOME-->
                      <ng-container matColumnDef="Nome File">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                          Nome
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                          {{row.name}}
                        </mat-cell>
                      </ng-container>
                      <!--AZIONI-->
                      <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'actionWidth'">
                          Azioni
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row; let i = index" [ngClass]="'actionWidth'">
                          <button mat-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <button (click)="downloadAttachment(row.id)" mat-menu-item>Scarica</button>
                            <button mat-menu-item>Elimina</button>
                          </mat-menu>
                        </mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="displayedColumnsAttachments"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumnsAttachments;">
                      </mat-row>
                    </mat-table>
                    <div *ngIf="noPlates" class="no-records">
                      Nessuna targa presente
                    </div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <!--STORICO-->
      <mat-tab label="STORICO">
        <div class="brand-card-body">
          <div class="container-fluid">
            <app-data-log-version [table]="'Item'" [label]="'Items'" [idDataLog]="item.id"
              (onCallback)="restoreItem($event)" [isDialog]="false">
            </app-data-log-version>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>