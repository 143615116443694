import { InvestigationRow } from './investigationRow';
import { InvestigationType } from './investigationType';

export class Investigation {
    public id: number;
    public fk_item: number;
    public name: string;
    public fk_investigationType: InvestigationType;
    public investigationTypeName: string;
    public lstInvestigationRow: Array<InvestigationRow>
    public statusInvestigation: InvestigationStatusEnum;
    public progressInvestigation: number;
    public notDetectable: boolean;
    public points_weight: number;
    public max_points_weight: number;
    public local_degradation: number;
    public isValidated: boolean;
}

export enum InvestigationStatusEnum {
    DaFare = 1,
    InCorso = 2,
    Completato = 3
}