import { Company } from './company';
import { User } from './user.model';
import { ItemLocation } from './itemLocation';
import { Highway } from './highway';
import { Exit } from './exit';
import { ItemType } from './itemType';
import { Roadway } from './roadway';
import { SiasFile } from './siasFile';

export class Item {
  public id: number;
  public cod: string;
  public fk_user: User;
  public fk_company: Company
  public fk_itemLocation: ItemLocation;
  public fk_highway: Highway;
  public fk_exit: Exit;
  public locationExit: string;
  public fk_roadway: Roadway;
  public fk_itemType: ItemType;
  public preparation: boolean;
  public indication: string;
  public State: ItemState;
  public fk_dataLogVersion: number;
  public lat: number;
  public lng: number;
  public intervention_priority: number;
  public global_degradation: number;
  public icon;
  public deleted: boolean;
  public note: string;
  public files: Array<SiasFile>;
  public firstCompilationDate: Date;
}

export enum ItemState {
  Default = 1,
  Validato = 2,
}

export enum ItemPriorityFilter {
  Alta = 1,
  Media = 2,
  Bassa = 3
}
