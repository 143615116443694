<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form validate-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="divImgSias" data-tilt>
          <img style="width: 75%; height: 100%;" src="assets/img/brand/platone.png" alt="IMG">
        </div>

        <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
          <input class="input100" type="text" name="email" placeholder="Email" formControlName="email">
          <span class="focus-input100"></span>
          <span class="symbol-input100">
            <i class="fa fa-envelope" aria-hidden="true"></i>
          </span>
        </div>

        <div class="wrap-input100 validate-input" data-validate="Password is required">
          <input class="input100" type="password" name="pass" placeholder="Password" formControlName="password">
          <span class="focus-input100"></span>
          <span class="symbol-input100">
            <i class="fa fa-lock" aria-hidden="true"></i>
          </span>
        </div>

        <div class="container-login100-form-btn">
          <button class="login100-form-btn">
            Login
          </button>
        </div>

        <div class="divImgSias">
          <img class="imgSias" src="assets/img/brand/LogoSiasNero.svg" alt="IMG">
        </div>
      </form>
    </div>
  </div>
</div>