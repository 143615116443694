<!--FILTRI-->
<div class="row">
  <!--PULSANTI-->
  <div class="col-md-6 col-sm-12 mb-3">
    <!--FILTRO-->
    <button matTooltip="CERCA" class="btn btn-primary btn-orange mr-1" (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseSearch" [ngClass]="{'filtroSi': isCollapsed}">
      <i class="icon-magnifier"></i>
    </button>

    <!--FILTRO AVANZATO-->
    <button matTooltip="FILTRO AVANZATO" class="btn btn-primary btn-orange mr-1" (click)="openFilterDialog()"
      [ngClass]="{'filtroAvanzatoSi': isFilterApplied}">
      <i class="icon-magnifier-add"></i>
    </button>

    <!--RESET FILTRO-->
    <button *ngIf="isFilterApplied || isFilterMapApplied" matTooltip="RESET FILTRO"
      class="btn btn-primary btn-orange mr-1" (click)="resetAllFilter()"
      [ngClass]="{'filtroAvanzatoSi': isFilterApplied || isFilterMapApplied}">
      <i class="fa fa-ban"></i>
    </button>

    <!--SOLO TABELLA-->
    <button matTooltip="TABELLA" class="btn btn-primary btn-orange mr-1" (click)="tableOnly()"
      [disabled]="!isTableVisible" [ngClass]="{'filtroSi': isTableVisible}">
      <i class="icon-list"></i>
    </button>

    <!--SOLO MAPPA-->
    <button matTooltip="MAPPA" class="btn btn-primary btn-orange mr-1" (click)="mapOnly()" [disabled]="!isMapVisible"
      [ngClass]="{'filtroSi': isMapVisible}">
      <i class="icon-map"></i>
    </button>
  </div>

  <!--CERCA-->
  <div class="col-md-6 col-sm-12 mb-3" [ngbCollapse]="!isCollapsed">
    <div id="collapseSearch" class="filtroCerca">
      <mat-form-field floatLabel="never">
        <mat-label>Cerca</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)">
      </mat-form-field>
    </div>
  </div>
</div>

<div class="animated fadeIn">
  <div class="row" id="dashboard">
    <!-- MAPPA -->
    <div [hidden]="!isMapVisible || !dataSourceTables"
      [ngClass]="{'col-md-6': isTableVisible, 'col-md-12': !isTableVisible}">
      <div class="card">

        <div class="card-header">
          Mappa
        </div>

        <div class="card-body" [ngStyle]="{'height': !isTableVisible ? '86vh' : '79vh' }">

          <agm-map id [fitBounds]="true" #AgmMap *ngIf="items && items.length > 0" [latitude]="lat" [longitude]="lng"
            [zoom]="zoom" (mapReady)="mapLoad($event)" [disableDefaultUI]="false" [zoomControl]="true"
            [fullscreenControl]='true' [mapTypeControl]='true' (boundsChange)="checkMarkersInBounds($event)">
            <!-- (idle)="checkMarkersInBounds($event)" -->
            <!-- (mapClick)="mapClicked($event)" -->
            <agm-marker-cluster [maxZoom]='12' [minimumClusterSize]='5'
              imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
              <agm-marker [agmFitBounds]="true" *ngFor="let m of dataSourceTables.filteredData; let i = index"
                (markerClick)="clickedMarker(null, i, infowindow)" [latitude]="m.lat" [longitude]="m.lng"
                [iconUrl]="basePath + m.icon">

                <agm-info-window #infowindow>
                  <h5 style="margin: 0px;" class="text-uppercase">
                    <b>Dettaglio item</b>
                  </h5>
                  <hr style="margin-top: 10px; margin-bottom: 10px;" />
                  <ng-container *ngIf="m?.cod">
                    <b>Codice: </b> {{m?.cod}} <br>
                    <b>Tipologia: </b> {{m?.fk_itemType?.name}} <br>
                    <b>Strada: </b> {{m?.fk_highway?.name}} <br>
                    <b>Ubicazione: </b> {{m?.fk_itemLocation?.name}} <br>
                    <b>Uscita/Posizione: </b>
                    {{m?.fk_exit ? m.fk_exit.name : (m?.locationExit ? m?.locationExit : '')}} <br>
                    <b>Indicazione: </b> {{m?.indication}} <br>
                    <hr style="margin-top: 10px; margin-bottom: 10px;" />
                  </ng-container>
                  <p>
                    <b>Dettaglio </b>
                    <a *ngIf="(m.cod == null)" [routerLink]="'/add-item/' + m.id">
                      <i style="cursor:pointer;" class="fa fa-edit"></i>
                    </a>
                    <a *ngIf="!(m.cod == null)" [routerLink]="'/item/' + m.id">
                      <i style="cursor:pointer; color:blue" class="fa fa-edit"></i>
                    </a>
                    <a style="float:right; margin-left:15px" class="pointer" placement="bottom"
                      [ngbPopover]="popContent" container="body" (click)="getItemPreview(m?.id)">
                      <i style="color:blue" class="fa fa-search"></i> <b>Foto</b>
                    </a>
                  </p>
                </agm-info-window>

              </agm-marker>
            </agm-marker-cluster>
            <!-- <agm-circle [latitude]="lat + 0.3" [longitude]="lng" [radius]="5000" [fillColor]="'red'"
              [circleDraggable]="true" [editable]="true">
            </agm-circle> -->

          </agm-map>

          <div matTooltip="Filtra gli elementi nella mappa" (click)="filterMarkersInBounds()" dir="ltr" title="Filtra"
            controlwidth="40" controlheight="40" class="filterMap">
            <i [ngStyle]="{'color': isFilterMapApplied == true ? 'blue' : '#666666'}" style=" font-size: 2em;
                      line-height: 1.10em;
                      vertical-align: -15%; padding: 3px;" class="fa fa-filter"></i>
          </div>

          <!-- <div matTooltip="Rimuovi filtro" (click)="resetFilterMap()" dir="ltr"
            title="Rimuovi filtro" controlwidth="18" controlheight="18" class="resetMap">
            <i style=" font-size: 2em;
                        line-height: 1.10em;
                        vertical-align: -15%; color: #666666; padding: 3px;" class="fa fa-ban"></i>
          </div> -->

          <ng-template #popContent>
            <img style="max-width: 100%; max-height: 100%;" [src]="previewItemImage">
          </ng-template>

        </div>
      </div>
    </div>

    <!-- ITEMS TABLE -->
    <div [hidden]="!isTableVisible || !dataSourceTables"
      [ngClass]="{'col-md-6': isMapVisible, 'col-md-12': !isMapVisible}">

      <div class="card">
        <div class="card-header">
          Items
          <!-- <app-import style="float: right;" modelName="Item" (importKmzCompleted)="reloadItems()"
          (importCsvCompleted)="reloadItems()"></app-import> -->
        </div>
        <div class="card-body">

          <div class="mat-table__wrapper">
            <div class="example-container mat-elevation-z8">

              <mat-table matTableExporter [dataSource]="dataSourceTables" #exporter="matTableExporter" matSort>

                <!-- Stato Column -->
                <ng-container matColumnDef="Stato">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Stato
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <i *ngIf="row.cod == null || row.fk_highway.id == null || row.fk_itemLocation.id == null || row.fk_itemType == null || row.preparation == null || row.indication == null"
                      matTooltip="Proprietà mancante" class="fa fa-exclamation-triangle"
                      style="color: red;font-size: 16pt;"></i>
                  </mat-cell>
                </ng-container>

                <!-- Data Column -->
                <ng-container matColumnDef="firstCompilationDate">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Data Creazione
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.firstCompilationDate | date:'dd-MM-yyyy' }}
                  </mat-cell>
                </ng-container>

                <!-- Codice Column -->
                <ng-container matColumnDef="cod">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Codice
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.cod}} </mat-cell>
                </ng-container>

                <!-- Cliente Column -->
                <ng-container matColumnDef="fk_company">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Cliente
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row?.fk_company?.businessName}} </mat-cell>
                </ng-container>

                <!-- Strada Column -->
                <ng-container matColumnDef="fk_highway">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Strada
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.fk_highway?.name}} </mat-cell>
                </ng-container>

                <!-- Ubicazione Column -->
                <ng-container matColumnDef="fk_itemLocation">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Ubicazione
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.fk_itemLocation?.name}} </mat-cell>
                </ng-container>

                <!-- Posizione Column -->
                <ng-container matColumnDef="Posizione">
                  <mat-header-cell *matHeaderCellDef>
                    Uscita / Posizione
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.fk_exit?.name ? row.fk_exit?.name : row.locationExit}}
                  </mat-cell>
                </ng-container>

                <!-- Carreggiata Column -->
                <ng-container matColumnDef="fk_roadway">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Carreggiata
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.fk_roadway?.name}}
                  </mat-cell>
                </ng-container>

                <!-- Tipologia Column -->
                <ng-container matColumnDef="fk_itemType">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Tipologia
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.fk_itemType?.name}} </mat-cell>
                </ng-container>

                <!-- Allestimento Column -->
                <ng-container matColumnDef="preparation">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    PMV
                  </mat-header-cell>
                  <mat-cell class="centerColumn" *matCellDef="let row">
                    <i [ngClass]="{'fa fa-check icons font-sm d-block':row.preparation == true, '':row.preparation == false}"
                      [ngStyle]="{'color': row.preparation == true ? 'green' : ''}"></i>
                  </mat-cell>
                </ng-container>


                <!-- Indicazione Column -->
                <ng-container matColumnDef="indication">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Indicazione
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row?.indication}} </mat-cell>
                </ng-container>

                <!-- Note Column -->
                <ng-container matColumnDef="note">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Note
                  </mat-header-cell>
                  <mat-cell class="centerColumn" *matCellDef="let row">
                    <i (click)="tooltip.toggle()" #tooltip="matTooltip"
                      matTooltip="{{row?.note ? row?.note : 'Nessuna nota'}}" matTooltipPosition="right"
                      style="color:blue" class="fa fa-search"></i>
                  </mat-cell>
                </ng-container>

                <!-- FDG Column -->
                <ng-container matColumnDef="global_degradation">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    FDG
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row?.global_degradation}} </mat-cell>
                </ng-container>

                <!-- Priorità Column -->
                <ng-container matColumnDef="intervention_priority">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Priorità
                  </mat-header-cell>
                  <mat-cell class="centerColumn" *matCellDef="let row">
                    <i *ngIf="row.intervention_priority != 0" style="font-size: 14pt;" class="fa fa-circle"
                      [ngStyle]="{'color': row.intervention_priority == 1 ? 'red' : row.intervention_priority == 2 ? 'yellow' : 'green'}"></i>
                  </mat-cell>
                </ng-container>

                <!-- Indagine Column -->
                <ng-container matColumnDef="State">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Indagine
                  </mat-header-cell>
                  <mat-cell class="centerColumn" *matCellDef="let row">
                    <i style="font-size: 14pt;" class="fa fa-circle"
                      [ngStyle]="{'color': row.State == 1 ? '#ff8c00' : '#1a00ff'}"></i>
                  </mat-cell>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="Actions">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Azioni
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row; let i = index">
                    <button mat-button [matMenuTriggerFor]="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button
                        [disabled]="row.cod == null || row.fk_highway.id == null || row.fk_itemLocation.id == null || row.fk_itemType == null || row.preparation == null || row.indication == null"
                        (click)="detailItem(row.id)" mat-menu-item>
                        Dettaglio
                      </button>
                      <button mat-menu-item [routerLink]="['../add-item', row.id]">Modifica</button>
                      <button *ngIf="!row.deleted" (click)="deleteItem(row.id, true)" mat-menu-item>Elimina</button>
                      <button *ngIf="row.deleted" (click)="deleteItem(row.id, false)" mat-menu-item>Ripristina</button>
                      <button (click)="getItemAttachments(row.id); selectedRow(row.id)" mat-menu-item>Allegati</button>
                      <button (click)="getLogsItem(row)" mat-menu-item>Visualizza storico</button>
                    </mat-menu>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"
                  [ngClass]="{'highlight': selectedRowIndex == row.id}" (click)="highlight(row)">
                </mat-row>
              </mat-table>
              <div *ngIf="noDataDashboard" class="no-records">
                Nessun elemento presente
              </div>
            </div>
          </div>

          <div style="margin-top: 2%;" class="row">

            <div class="col-sm-6 col-xs-12">
              <p class="item-data">
                <span>Totale Items: </span><span style="margin-right:10px;" class="item-number">{{totalItems}}</span>
                <span>Non censiti: </span><span class="item-number">{{notCompletedItems}}</span>
                <i class="fa fa-exclamation-triangle" style="color: red;font-size: 10pt;"></i>
              </p>
            </div>

            <div class="col-sm-6 col-xs-12">
              <mat-paginator id="oneOf" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
            </div>
          </div>

          <hr />

          <!-- LEGENDA -->
          <div class="row">
            <div class="col-md-4 col-sm-12 text-left">
              <div class='my-legend'>
                <div class='legend-title'>PRIORITA'</div>
                <div class='legend-scale'>
                  <ul class='legend-labels'>
                    <li><span style='background:red;'></span>Intervento tempestivo: {{interventionPriority1}}</li>
                    <li><span style='background:yellow;'></span>Intervento urgente: {{interventionPriority2}}</li>
                    <li><span style='background:green'></span>Intervento ordinario: {{interventionPriority3}}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 text-left">
              <div class='my-legend'>
                <div class='legend-title'>INDAGINE</div>
                <div class='legend-scale'>
                  <ul class='legend-labels'>
                    <li><span style='background:#ff8c00;'></span>Indagine non fatta: {{investigationNotDone}}</li>
                    <li><span style='background:#1a00ff;'></span>Indagine fatta: {{investigationDone}}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class='my-legend download-section'>
                <div class='legend-title'>SCARICA CSV</div>
                <i matTooltip="SCARICA CSV" mat-raised-button class="fa fa-file-excel-o download-csv"
                  (click)="exporter.exportTable('csv')"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modale Allegato -->
<ng-template #dialogAttachments>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          Allegati {{currentItemSelected.cod}}
        </div>
        <div class="card-body">

          <div class="example-header">
            <mat-form-field>
              <!-- <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtro"> -->
            </mat-form-field>
          </div>

          <div class="mat-table__wrapper">
            <div class="example-container mat-elevation-z8 mat-row">

              <table mat-table [dataSource]="dataSourceAttachments" matSort>

                <ng-container matColumnDef="Data">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Data
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.creationDate | date: 'dd/MM/yyyy'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Nome File">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Nome
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.name}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Azioni
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row; let i = index">
                    <button mat-button [matMenuTriggerFor]="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button (click)="downloadAttachment(row.id)" mat-menu-item>Scarica</button>
                      <button (click)="deleteAttachment(row.id)" mat-menu-item>Elimina</button>
                    </mat-menu>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsAttachments"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsAttachments;">
                </mat-row>
              </table>

              <div *ngIf="noData" class="no-records">
                Nessun allegato presente
              </div>
              <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator> -->
            </div>
          </div>


          <mat-dialog-actions class="posizioneBottoniModale">
            <button (click)="fileInput.click()" class="btn btn-outline-primary marginBottoni">AGGIUNGI</button>
            <input name="item_image" multiple #fileInput type="file"
              (change)="onFileChange($event, 1,'Allegato', currentItemSelected.id)" style="display:none;" />
            <button class="btn btn-outline-primary marginBottoni" matDialogClose>CHIUDI</button>
          </mat-dialog-actions>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<!-- Modale Versioning -->
<ng-template #dialogLogs>
  <app-data-log-version *ngIf="item" [label]="'Items'" [table]="'Item'" [idDataLog]="item.id"
    (onCallback)="restoreItem($event)" [isDialog]="true">
  </app-data-log-version>
</ng-template>

<!-- Modale Filtro avanzato -->
<ng-template #dialogFilter>
  <div class="card">
    <div class="card-header">Filtro Avanzato</div>
    <div class="card-body">

      <div class="row">
        <!--CLIENTE-->
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allCompany" placeholder="Cliente" bindLabel="businessName" [(ngModel)]="filter.Cliente">
          </ng-select>
        </div>

        <!--STRADA-->
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allHighway" placeholder="Strada" bindLabel="name" [(ngModel)]="filter.Strada">
          </ng-select>
        </div>

        <!--UBICAZIONE-->
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allItemLocation" placeholder="Ubicazione" bindLabel="name"
            [(ngModel)]="filter.Ubicazione">
          </ng-select>
        </div>

        <!--USCITA - COMP. ES.-->
        <div *ngIf="filter.Ubicazione?.id == 2" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allHighwayExit" placeholder="Uscita" bindLabel="name" [(ngModel)]="filter.Uscita">
          </ng-select>
        </div>

        <!--CARREGGIATA - COMP. ES.-->
        <div *ngIf="filter.Ubicazione?.id == 1" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allRoadway" placeholder="Carreggiata" bindLabel="name" [(ngModel)]="filter.Carreggiata">
          </ng-select>
        </div>

        <!--TIPOLOGIA - COMP. ES.-->
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allItemType" placeholder="Tipologia" bindLabel="name" [(ngModel)]="filter.Tipologia">
          </ng-select>
        </div>

        <!--POSIZIONE - COMP. ES.-->
        <div *ngIf="filter.Ubicazione?.id == 1" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allPosition" placeholder="Posizione" bindLabel="name" [(ngModel)]="filter.Posizione">
          </ng-select>
        </div>

        <!--INDICAZIONE - COMP. ES.-->
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [items]="allIndication" placeholder="Indicazione" bindLabel="name"
            [(ngModel)]="filter.Indicazione">
          </ng-select>
        </div>

        <!--INDAGINE-->
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [searchable]="false" [items]="itemState" bindLabel="Value" bindValue="Key" placeholder="Indagine"
            clearAllText="Rimuovi" [(ngModel)]="filter.Stato">
            <ng-template ng-label-tmp let-item="item">
              <img height="15" width="15" [src]="item.Avatar" />
              {{item.Value}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <img height="15" width="15" [src]="item.Avatar" />
              {{item.Value}}
            </ng-template>
          </ng-select>
        </div>

        <!--FATTORI PRIORITA'-->
        <div class=" col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [searchable]="false" [items]="itemPriority" bindLabel="Value" bindValue="Key"
            placeholder="Priorità" clearAllText="Rimuovi" [(ngModel)]="filter.Priorita">
            <ng-template ng-label-tmp let-item="item">
              <img height="15" width="15" [src]="item.Avatar" />
              {{item.Value}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <img height="15" width="15" [src]="item.Avatar" />
              {{item.Value}}
            </ng-template>
          </ng-select>
        </div>

        <!--PMV-->
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [searchable]="false" [items]="itemPmv" bindLabel="Value" bindValue="Key" placeholder="Pmv"
            clearAllText="Rimuovi" [(ngModel)]="filter.Pmv">
            <ng-template ng-label-tmp let-item="item">
              <img height="10" width="20" [src]="item.Avatar" />
              {{item.Value}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <img height="10" width="20" [src]="item.Avatar" />
              {{item.Value}}
            </ng-template>
          </ng-select>
        </div>

        <!--STATO-->
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [searchable]="false" [items]="itemCompleted" bindLabel="Value" bindValue="Key" placeholder="Stato"
            clearAllText="Rimuovi" [(ngModel)]="filter.IsCompleted">
          </ng-select>
        </div>

        <!--ELIMINATO-->
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [searchable]="false" [items]="itemDeleted" bindLabel="Value" bindValue="Key"
            placeholder="Eliminato" clearAllText="Rimuovi" [(ngModel)]="filter.isDeleted">
          </ng-select>
        </div>
      </div>

      <!--PULSANTI-->
      <mat-dialog-actions>

        <!--APPLICA-->
        <button mat-dialog-close (click)="reloadItems(true)" matTooltip="CERCA"
          class="btn btn-primary btn-orange float-right">
          <i class="icon-magnifier"></i>
        </button>

        <!--RESET-->
        <button mat-dialog-close (click)="resetAllFilter()" matTooltip="RESET"
          class="btn btn-primary btn-orange mr-1 float-right">
          <i class="icon-refresh"></i>
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</ng-template>