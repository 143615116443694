import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import Swal from 'sweetalert2';
import { User, UserRole } from '../../models/user.model';
import { DialogService } from '../../services/dialog.service';
import { UserService } from '../../services/user.service';
import { MatDialog } from '@angular/material/dialog';


interface keyValueRole {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @ViewChild("dialogModificaUserPRofile", { static: false }) dialogModificaUserPRofile: TemplateRef<any>;

  user: User = new User();

  allUser: Array<User> = new Array<User>();

  userLogged: User;

  userProfileForm: FormGroup;
  userEditProfileForm: FormGroup;
  registerForm: FormGroup;

  loading = false;
  submitted = false;
  error = '';

  roles: keyValueRole[] = [
    { value: '1', viewValue: 'Admin' },
    { value: '2', viewValue: 'Tecnico' },
    { value: '3', viewValue: 'Operatore' },
    { value: '4', viewValue: 'Cliente' }
  ];


  constructor(
    private UserService: UserService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthService,
    private dialog: MatDialog
  ) {

    this.userLogged = this.authenticationService.getCurrentUser();

  }

  ngOnInit() {


    // si deve aprire già popolato
    this.userProfileForm = this.formBuilder.group({
      email: [this.userLogged.email],
      name: [this.userLogged.name],
      surname: [this.userLogged.surname],
      phone: [this.userLogged.phone],
      role: [this.userLogged.role],
      fk_company: [this.userLogged.fk_company]
    })

    this.userEditProfileForm = this.formBuilder.group({
      email: [''],
      name: [''],
      surname: [''],
      phone: [''],
      role: [''],
      fk_company: ['']
    })


    this.registerForm = this.formBuilder.group({
      email: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      phone: ['', Validators.required],
      role: [''],
      fk_company: ['']
    })


  }

  // reloadUserLogged() {
  //   this.UserService.getUser(this.userLogged).then((resUser) => {
  //     this.userLogged = resUser;
  //   })
  //     .catch((err) => {
  //     })
  // }


  // Per accedere ai campi del form facilmente
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;


    // Se il form non è valido mi fermo
    if (this.registerForm.invalid) {
      return;
    }

    this.authenticationService.register(this.f.email.value, this.f.name.value, this.f.surname.value, this.f.phone.value, this.f.role.value)
      .then((res) => {
        Swal.fire({
          title: 'Registrazione completata con successo',
          text: 'È stata mandata una mail con i dati di accesso all indirizzo inserito',
          icon: 'success',
        })
        this.resetForm();

      })
      .catch((err) => {
        Swal.fire({
          title: err,
          icon: 'error'
        })
      });

  }

  resetForm() {
    this.registerForm.reset();
  }

  updateUser(id: User) {
    DialogService.Confirm("Sei sicuro di voler modificare il profilo?", () => {
      this.UserService.updateUser(id).then(() => {
        DialogService.Success("Per vedere le modifiche è necessario rieffettuare l'accesso", "Profilo aggiornato!");
        this.dialog.closeAll();
        // this.reloadUserLogged();
      })
    })
  }

  modificaUserProfile() {
    var cloneUser = JSON.parse(JSON.stringify(this.userLogged));

    this.openModificaUserPRofileDialog(this.userLogged);
  }



  openModificaUserPRofileDialog(user: User): void {
    this.dialog.open(this.dialogModificaUserPRofile, {
      data: user,
      width: '550px',
      autoFocus: false,
    });
  }

}
