

export class SiasFile {
    public id: number;
    public name: string;
    public extension: string;
    public data: any;
    public content_type: string;
    public labelName: string;
    public creationDate: Date;
    public type: TypeFile;
    public base64: string;
}

export enum TypeFile {
    Allegato = 1,
    Targa = 2,
    ItemImg = 3
}