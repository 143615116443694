import { Component, OnInit, ViewChild, TemplateRef, ViewChildren, QueryList } from '@angular/core';
import { Investigation, InvestigationStatusEnum } from '../../models/investigation';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { InvestigationService } from '../../services/investigation.service';
import { DialogService } from '../../services/dialog.service';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { InvestigationType } from '../../models/investigationType';
import { DocumentService } from '../../services/document.service';
import { environment } from '../../../environments/environment';
import { ItemService } from '../../services/item.service';
import { Item, ItemState } from '../../models/Item';
import { SummaryInvestigation } from '../../models/summaryInvestigation.model';
import { InvestigationWizard, InvestigationWizardSections } from './wizard-indagine/wizard-indagine.component';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { LocationStrategy } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { PreviousRouteService } from '../../helpers/previousRoute.service';

@Component({
  selector: 'app-indagine',
  templateUrl: './indagine.component.html',
  styleUrls: ['./indagine.component.scss']
})
export class IndagineComponent implements OnInit {

  displayedColumnsPiastra = ['Degrado', 'Avanzamento', 'Stato', 'Actions'];
  displayedColumnsRitto = ['Degrado', 'Avanzamento', 'Stato', 'Actions'];
  displayedColumnsTraverso = ['Degrado', 'Avanzamento', 'Stato', 'Actions'];
  displayedColumnsPastorali = ['Degrado', 'Avanzamento', 'Stato', 'Actions'];

  dataSourcePiastra: MatTableDataSource<Investigation>;
  dataSourceRitto: MatTableDataSource<Investigation>;
  dataSourceTraverso: MatTableDataSource<Investigation>;
  dataSourcePastorali: MatTableDataSource<Investigation>;

  piastraRegisterForm: FormGroup;
  rittoRegisterForm: FormGroup;
  traversoRegisterForm: FormGroup;
  pastoraliRegisterForm: FormGroup;

  allInvestigationPiastra: Array<Investigation>;
  allInvestigationRitto: Array<Investigation>;
  allInvestigationTraverso: Array<Investigation>;
  allInvestigationPastorali: Array<Investigation>;
  allInvestigations: Array<Investigation>;

  summaryInvestigationWizardPiastra: InvestigationWizard = new InvestigationWizard();
  summaryInvestigationWizardRitto: InvestigationWizard = new InvestigationWizard();
  summaryInvestigationWizardTraverso: InvestigationWizard = new InvestigationWizard();
  summaryInvestigationWizardPastorale: InvestigationWizard = new InvestigationWizard();

  forcedStatus: number = 3;
  forcedProgressInvestigation: number = 100;

  investigation: Investigation = new Investigation();
  investigationSummary: Array<SummaryInvestigation> = new Array<SummaryInvestigation>()
  currentItem: Item = new Item();

  isDataAvailable: boolean = false;

  previewImage: SafeResourceUrl;

  public previousRoute;
  public currentRoute;

  isInvestigationValidated: boolean;

  @ViewChild("dialogAddPiastra", { static: false }) dialogAddPiastra: TemplateRef<any>;
  @ViewChild("dialogAddRitto", { static: false }) dialogAddRitto: TemplateRef<any>;
  @ViewChild("dialogAddTraverso", { static: false }) dialogAddTraverso: TemplateRef<any>;
  @ViewChild("dialogAddPastorali", { static: false }) dialogAddPastorali: TemplateRef<any>;
  @ViewChild("dialogInvestigationWizardSummary", { static: false }) dialogInvestigationWizardSummary: TemplateRef<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private investigationService: InvestigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private documentService: DocumentService,
    private itemService: ItemService,
    private sanitizer: DomSanitizer,
    private previousRouteService: PreviousRouteService
  ) {

    this.investigation.fk_investigationType = new InvestigationType()

  }

  ngOnInit(): void {


    if (this.activatedRoute.snapshot.params.id) {
      // Mi creo una variabile di tipo number in quanto l'activated route mi restituisce una stringa
      let idItem: number = parseInt(this.activatedRoute.snapshot.params.id);
      this.itemService.getItem(idItem).then((res) => {
        this.currentItem = res;
        this.reloadAllInvestigation();
      }).catch((err) => {
        DialogService.Error("Non è stato possibile recuperare l'item: " + err)
      })
    }

    this.previousRoute = this.previousRouteService.getPreviousUrl();
    this.currentRoute = this.previousRouteService.getCurrentUrl();

  }


  reloadAllInvestigation() {
    this.investigationService.getListInvestigation(this.currentItem.id).then((resAllInvestigations) => {

      // Recupero tutte le piastre dell'item in cui mi trovo
      this.allInvestigationPiastra = resAllInvestigations.filter(x => x.fk_investigationType.id == 1);
      this.dataSourcePiastra = new MatTableDataSource(this.allInvestigationPiastra);
      this.dataSourcePiastra.paginator = this.paginator;
      this.dataSourcePiastra.sort = this.sort;

      // Recupero tutti i ritti dell'item in cui mi trovo
      this.allInvestigationRitto = resAllInvestigations.filter(x => x.fk_investigationType.id == 2);
      this.dataSourceRitto = new MatTableDataSource(this.allInvestigationRitto);
      this.dataSourceRitto.paginator = this.paginator;
      this.dataSourceRitto.sort = this.sort;

      // Recupero tutti i traversi dell'item in cui mi trovo
      this.allInvestigationTraverso = resAllInvestigations.filter(x => x.fk_investigationType.id == 3);
      this.dataSourceTraverso = new MatTableDataSource(this.allInvestigationTraverso);
      this.dataSourceTraverso.paginator = this.paginator;
      this.dataSourceTraverso.sort = this.sort;

      // Recupero tutti i pastorali dell'item in cui mi trovo
      this.allInvestigationPastorali = resAllInvestigations.filter(x => x.fk_investigationType.id == 4);
      this.dataSourcePastorali = new MatTableDataSource(this.allInvestigationPastorali);
      this.dataSourcePastorali.paginator = this.paginator;
      this.dataSourcePastorali.sort = this.sort;
    }).then(() => {
      this.reloadCurrentSummary();
      this.reloadSummaryInvestigationWizard();
    })
  }

  reloadSummaryInvestigationWizard() {

    // Mi recupero il wizard di ogni singola indagine
    this.investigationService.getInvestigation(this.allInvestigationPiastra[0].id).then((res) => {
      this.summaryInvestigationWizardPiastra.investigation = res;
      this.summaryInvestigationWizardPiastra.sections = new Array<InvestigationWizardSections>();

      //Creazione pagina DATI GENERALI
      let noSectionQuestions = this.summaryInvestigationWizardPiastra.investigation.lstInvestigationRow.filter(q => q.section == null);
      noSectionQuestions.sort((a, b) => { return a.orderNumber <= b.orderNumber ? -1 : 1 });
      let noSectionName: InvestigationWizardSections = new InvestigationWizardSections();
      noSectionName.questions = noSectionQuestions;
      noSectionName.section = null;
      this.summaryInvestigationWizardPiastra.sections.push(noSectionName);

      //Recupera tutte le sezioni distinte, associate alle question, che andranno a comporre le pagine dell'indagine e le ordina in base al campo sectionOrder
      let allDistinctSections = this.summaryInvestigationWizardPiastra.investigation.lstInvestigationRow.filter(q => q.section != null).filter((value, index, array) => array.map(a => a.section.id).indexOf(value.section.id) === index).map(q => q.section);
      allDistinctSections.sort((a, b) => {
        return a.sectionOrder <= b.sectionOrder ? -1 : 1;
      });

      //Popola le pagine del wizard (cioè le sezioni) inserendo in ognuna di esse le question in base alla configurazione che arriva dal DB
      allDistinctSections.forEach(section => {
        let wizardSection: InvestigationWizardSections = new InvestigationWizardSections();
        wizardSection.section = section;
        wizardSection.questions = this.summaryInvestigationWizardPiastra.investigation.lstInvestigationRow.filter(q => q.section != null && q.section.id == section.id).sort((a, b) => {
          return a.orderNumber <= b.orderNumber ? -1 : 1;
        });
        this.summaryInvestigationWizardPiastra.sections.push(wizardSection);
      });

    });

    // RITTO
    this.investigationService.getInvestigation(this.allInvestigationRitto[0].id).then((res) => {
      this.summaryInvestigationWizardRitto.investigation = res;
      this.summaryInvestigationWizardRitto.sections = new Array<InvestigationWizardSections>();

      //Creazione pagina DATI GENERALI
      let noSectionQuestions = this.summaryInvestigationWizardRitto.investigation.lstInvestigationRow.filter(q => q.section == null);
      noSectionQuestions.sort((a, b) => { return a.orderNumber <= b.orderNumber ? -1 : 1 });
      let noSectionName: InvestigationWizardSections = new InvestigationWizardSections();
      noSectionName.questions = noSectionQuestions;
      noSectionName.section = null;
      this.summaryInvestigationWizardRitto.sections.push(noSectionName);

      //Recupera tutte le sezioni distinte, associate alle question, che andranno a comporre le pagine dell'indagine e le ordina in base al campo sectionOrder
      let allDistinctSections = this.summaryInvestigationWizardRitto.investigation.lstInvestigationRow.filter(q => q.section != null).filter((value, index, array) => array.map(a => a.section.id).indexOf(value.section.id) === index).map(q => q.section);
      allDistinctSections.sort((a, b) => {
        return a.sectionOrder <= b.sectionOrder ? -1 : 1;
      });

      //Popola le pagine del wizard (cioè le sezioni) inserendo in ognuna di esse le question in base alla configurazione che arriva dal DB
      allDistinctSections.forEach(section => {
        let wizardSection: InvestigationWizardSections = new InvestigationWizardSections();
        wizardSection.section = section;
        wizardSection.questions = this.summaryInvestigationWizardRitto.investigation.lstInvestigationRow.filter(q => q.section != null && q.section.id == section.id).sort((a, b) => {
          return a.orderNumber <= b.orderNumber ? -1 : 1;
        });
        this.summaryInvestigationWizardRitto.sections.push(wizardSection);
      });
    });

    // TRAVERSO
    this.investigationService.getInvestigation(this.allInvestigationTraverso[0].id).then((res) => {
      this.summaryInvestigationWizardTraverso.investigation = res;
      this.summaryInvestigationWizardTraverso.sections = new Array<InvestigationWizardSections>();

      //Creazione pagina DATI GENERALI
      let noSectionQuestions = this.summaryInvestigationWizardTraverso.investigation.lstInvestigationRow.filter(q => q.section == null);
      noSectionQuestions.sort((a, b) => { return a.orderNumber <= b.orderNumber ? -1 : 1 });
      let noSectionName: InvestigationWizardSections = new InvestigationWizardSections();
      noSectionName.questions = noSectionQuestions;
      noSectionName.section = null;
      this.summaryInvestigationWizardTraverso.sections.push(noSectionName);

      //Recupera tutte le sezioni distinte, associate alle question, che andranno a comporre le pagine dell'indagine e le ordina in base al campo sectionOrder
      let allDistinctSections = this.summaryInvestigationWizardTraverso.investigation.lstInvestigationRow.filter(q => q.section != null).filter((value, index, array) => array.map(a => a.section.id).indexOf(value.section.id) === index).map(q => q.section);
      allDistinctSections.sort((a, b) => {
        return a.sectionOrder <= b.sectionOrder ? -1 : 1;
      });

      //Popola le pagine del wizard (cioè le sezioni) inserendo in ognuna di esse le question in base alla configurazione che arriva dal DB
      allDistinctSections.forEach(section => {
        let wizardSection: InvestigationWizardSections = new InvestigationWizardSections();
        wizardSection.section = section;
        wizardSection.questions = this.summaryInvestigationWizardTraverso.investigation.lstInvestigationRow.filter(q => q.section != null && q.section.id == section.id).sort((a, b) => {
          return a.orderNumber <= b.orderNumber ? -1 : 1;
        });
        this.summaryInvestigationWizardTraverso.sections.push(wizardSection);
      });
    });

    // PASTORALE
    this.investigationService.getInvestigation(this.allInvestigationPastorali[0].id).then((res) => {
      this.summaryInvestigationWizardPastorale.investigation = res;
      this.summaryInvestigationWizardPastorale.sections = new Array<InvestigationWizardSections>();

      //Creazione pagina DATI GENERALI
      let noSectionQuestions = this.summaryInvestigationWizardPastorale.investigation.lstInvestigationRow.filter(q => q.section == null);
      noSectionQuestions.sort((a, b) => { return a.orderNumber <= b.orderNumber ? -1 : 1 });
      let noSectionName: InvestigationWizardSections = new InvestigationWizardSections();
      noSectionName.questions = noSectionQuestions;
      noSectionName.section = null;
      this.summaryInvestigationWizardPastorale.sections.push(noSectionName);

      //Recupera tutte le sezioni distinte, associate alle question, che andranno a comporre le pagine dell'indagine e le ordina in base al campo sectionOrder
      let allDistinctSections = this.summaryInvestigationWizardPastorale.investigation.lstInvestigationRow.filter(q => q.section != null).filter((value, index, array) => array.map(a => a.section.id).indexOf(value.section.id) === index).map(q => q.section);
      allDistinctSections.sort((a, b) => {
        return a.sectionOrder <= b.sectionOrder ? -1 : 1;
      });

      //Popola le pagine del wizard (cioè le sezioni) inserendo in ognuna di esse le question in base alla configurazione che arriva dal DB
      allDistinctSections.forEach(section => {
        let wizardSection: InvestigationWizardSections = new InvestigationWizardSections();
        wizardSection.section = section;
        wizardSection.questions = this.summaryInvestigationWizardPastorale.investigation.lstInvestigationRow.filter(q => q.section != null && q.section.id == section.id).sort((a, b) => {
          return a.orderNumber <= b.orderNumber ? -1 : 1;
        });
        this.summaryInvestigationWizardPastorale.sections.push(wizardSection);
      });
    });

  }

  openDialogPiastra(): void {
    this.dialog.open(this.dialogAddPiastra, {
      width: '550px',
      autoFocus: false
    })
  }


  openDialogRitto(): void {
    this.dialog.open(this.dialogAddRitto, {
      width: '550px',
      autoFocus: false
    })
  }


  openDialogTraverso(): void {
    this.dialog.open(this.dialogAddTraverso, {
      width: '550px',
      autoFocus: false
    })
  }


  openDialogPastorali(): void {
    this.dialog.open(this.dialogAddPastorali, {
      width: '550px',
      autoFocus: false
    })
  }

  // Rimuovo la sidebar laterale perchè il tema muove tutto verso l'alto
  openInvestigationWizardSummary(): void {
    if (document.getElementsByClassName("sidebar-lg-show"))
      document.body.className = document.body.className.replace("sidebar-lg-show", "")

    setTimeout(() => {
      this.dialog.open(this.dialogInvestigationWizardSummary, {
        width: '1200px',
        height: '80vh',
        autoFocus: false
      })
    }, 200);
  }

  addInvestigation(type: number) {

    this.investigation.fk_item = this.currentItem.id;
    this.investigation.fk_investigationType.id = type;

    this.investigationService.saveInvestigation(this.investigation).then(() => {
      DialogService.Success("Salvataggio effettuato con successo")
    }).catch((err) => {
      DialogService.Error("Errore: " + err + " , contattare gli amministratori di sistema")
    })

  }

  deleteInvestigation(id: number) {
    DialogService.Confirm("Sei sicuro di voler eliminare il cliente selezionato?", () => {
      this.investigationService.deleteInvestigation(id).then(() => {
        this.reloadAllInvestigation();
      }).catch((err) => {
        DialogService.Error("Non è stato possibile eliminare l'indagine: " + err);
      })
    });
  }

  openInvestigationWizard(id: number) {
    this.router.navigate(["../wizard/", id], { relativeTo: this.activatedRoute });

  }

  generateDocument() {
    this.documentService.generateDocument(2, this.currentItem.id, this.currentItem.fk_company.id).then((res) => {
      window.open(environment.apiUrl + '/' + res.Path);
    }).catch((err) => {
      DialogService.Error("Errore nella generazione del documento")
    })
  }


  // Ho dovuto fare la forzatura con il relativeTo e tornare indietro con il path perchè con il router.navigate ad un path assoluto il sistema ignorava il comando e/o lo riscriveva
  goBack() {
    if (this.previousRoute == this.currentRoute) {
      this.router.navigate(["../../../dashboard"], { relativeTo: this.activatedRoute });
    } else if (this.previousRoute.includes('wizard')) {
      this.router.navigate(["../../../item/" + this.currentItem.id], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(["../../../" + this.previousRoute], { relativeTo: this.activatedRoute });
    }
  }

  reloadCurrentSummary() {
    this.investigationService.getSummaryInvestigation(this.currentItem.id, null).then((resSummary) => {
      this.investigationSummary = resSummary;
      this.isDataAvailable = true;
    })
  }

  forceValidate(fk_item: number, isValidated: boolean) {
    this.investigationService.setInvestigationValidate(fk_item, isValidated).then(() => {
      DialogService.Success("Operazione effettuata con successo");
      window.location.reload();
    }).catch((err) => {
      DialogService.Error("Non è stato possibile eseguire l'operazione corrente: " + err)
    })
  }

  getFilePreview(base64) {
    this.previewImage = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64, ${base64}`);
  }

}
